import React, { useContext, useEffect, useRef, useState } from "react";
import "./upperbar.css";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../context/auth-context";
import CloseIcon from "../../images/CloseIcon.svg";
import logo from "../../images/logo.png";
import { FaRegBell } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { Fetchdata } from "../hooks/getData";
import placeholder from "../../images/placeholder.png";
import {
  AiOutlineMenu,
  AiOutlinePoweroff,
  AiFillMessage,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { IoIosRefresh } from "react-icons/io";
import { MdNotificationsNone } from "react-icons/md";
import { Popover } from "@material-ui/core";
import UpperbarContext from "../context/upperbar-context";
import { AiOutlineClose } from "react-icons/ai";
import { ShowImgPreview } from "../hooks/imagePreview";
import $ from "jquery";
import nepal from "../../images/np.png";
import english from "../../images/en.png";

export default function Upperbar({
  sidebarController,
  setSidebarController,
  windowWidth,
  userDetails,
}) {
  const {
    logoutDropdown,
    setLogoutDropdown,
    refreshLayout,
    setRefreshLayout,
    logoutAlert,
    setLogoutAlert,
    sidebarLogout,
    setSidebarLogout,
    darkText,
    darkBg,
    darkBg2,
    mode,
    // langText,
    appURL,
    toggleLang,
    toggleDate,
    sideMenu,
    setSideMenu,
    logoutHandler,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [notificationList, setNotificationList] = useState([]);

  const [mobNav, setMobNav] = useState(true);

  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");

  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();

  const showLogout = (e) => {
    setLogoutDropdown(e.currentTarget);
  };

  const showAlert = (e) => {
    setLogoutDropdown(null);
    setLogoutAlert(true);
  };

  const handleMenuChange = () => {
    setSideMenu(!sideMenu);
  };

  // const notification = sessionStorage.getItem("NotificationList");
  // const notificationList = JSON.parse(notification);

  useEffect(() => {
    const params = {
      FetchURL: `${appURL}api/notification-list?ComID=${User.CompanyId}&UserID=-1`,
      Type: "GET",
    };

    Fetchdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.NotificationList
            ? result.NotificationList
            : "";

          setNotificationList(postResult);
          // setOriginalList(postResult);
          // setLoading(false);
        } else {
          setNotificationList([]);
          // setLoading(false);
        }
      })
      .catch((err) => {
        setNotificationList([]);
        // setLoading(false);
      });
  }, []);


  useEffect(() => {
    if (logoutAlert) {
      $(".addLogoutPopBg").fadeIn(500);
      $(".addLogoutPop").slideDown(500);
    }
  }, [logoutAlert]);

  const closePop = () => {
    setLogoutAlert(false);
    $(".addLogoutPopBg").fadeOut(500);
    $(".addLogoutPop").slideUp(500);
  };

  // const showLogoutPopup = () => {
  //   return (
  //     <>
  //       <div className="container-fluid p-0 logoutPopup-wrapper addLogoutPopBg">
  //         <div className="logoutpopup-inner addLogoutPop">
  //           <div className="row">
  //             <div className="col-md-12 col-lg-12 col-sm-12">
  //               <div className="popUpHeader ">
  //                 <div className="popUpTitle">Elite Office</div>
  //                 <div className="popUpClose">
  //                   <img
  //                     className="popUpCloseIcon"
  //                     src={CloseIcon}
  //                     alt="CloseIcon"
  //                     onClick={closePop}
  //                   />
  //                 </div>
  //               </div>

  //               <div className="logoutpopup-body text-start ps-4">
  //                 Do you want to Logout ?
  //               </div>
  //               <div className="logoutpopup-footer mt-4">
  //                 <button
  //                   type="button"
  //                   class="btn btn-sm ps-4 pe-4 me-2 ms-3"
  //                   style={{
  //                     backgroundColor: "var(--button-color)",
  //                     color: "white",
  //                   }}
  //                   onClick={logoutHandler}
  //                 >
  //                   Logout
  //                 </button>
  //                 <button
  //                   type="button"
  //                   class="btn btn-light btn-sm ps-4 pe-4 me-3 "
  //                   onClick={closePop}
  //                 >
  //                   Cancel
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      <nav className={!logoutAlert ? "nav" : "nav pushMainNav"}>
        <div className="uk-grid uk-flex-middle uk-flex-between uk-width-1">
          <div className="logo">
            <Link to="/dashboard">
              <img
                src={logo}
                alt="logo"
                style={{ height: "50px" }}
                className="uk-margin-auto uk-display-block"
              />
            </Link>
            <div className="ham-menu">
              <span>
                <i>
                  <GiHamburgerMenu
                    className="ham-icon"
                    color="#777"
                    size="1.5rem"
                    onClick={handleMenuChange}
                  />
                </i>
              </span>
            </div>
          </div>

          <div className="uk-width-expand ps-3 ">
            <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap resp">
              <div
                className="ps-2 uk-flex uk-flex-middle large-nav"
                style={{ borderLeft: "1px solid #d9d9d9" }}
              >
                <div>
                  <img
                    src={userDetails.ComLogo}
                    alt=""
                    style={{ height: "65px" }}
                  />
                </div>
                <div className="ms-2">
                  <h3 className="text-start text-dark">
                    {/* <span
                      className="uk-margin-remove"
                      style={{ color: mode === "light" ? "#004aad" : "#fff" }}
                    >
                      Elite
                    </span>{" "} */}
                    {userDetails.ComName}
                  </h3>
                  <p className="text-start">{userDetails.ComBranch}</p>
                  <p className="text-start address">
                    {userDetails.ComAddress}, {userDetails.ComTel}
                  </p>
                </div>
              </div>

              <div className="uk-flex uk-flex-middle">
                <span className="uk-flex uk-flex-middle">
                  <span
                  className="uk-text-bold"
                    style={{
                      marginRight: "2.7rem",
                      display: "inline-block",
                    }}
                  >
                    Nep  Date
                  </span>
                  <input
                    className="form-check-input chgLang"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault1"
                    onClick={toggleDate}
                  />
                  <span className="uk-text-bold">
                  Eng Date
                  </span>
                </span>

                <span>
                  <span
                    style={{
                      marginRight: "2.7rem",
                      display: "inline-block",
                      marginTop: "0.19rem",
                    }}
                  >
                    <img
                      src={english}
                      style={{ width: "25px", marginTop: "-0.3rem" }}
                      alt="flag"
                    />
                  </span>
                  <input
                    className="form-check-input chgLang"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    onClick={toggleLang}
                  />
                  <span className="uk-margin-small-left">
                    <img
                      src={nepal}
                      style={{
                        width: "25px",
                        marginTop: "-0.5rem",
                        marginLeft: "-0.5rem",
                      }}
                      alt="flag"
                    />
                  </span>
                </span>

                {/* <span>
                  <select onChange={(e) => setSelect(e.target.value)}>
                    {options.map((opt) => (
                      <option
                        onClick={translate1}
                        key={opt.code}
                        value={opt.code}
                      >
                        {opt.name}
                      </option>
                    ))}
                  </select>
                </span> */}

                {userDetails.IsManager === 0 && (
                  <span className="notify me-5 large-nav" uk-margin>
                    <button type="button" className="btn p-0">
                      <FaRegBell size="1.3rem" color="#000" />
                      <span className="uk-badge">
                        {notificationList.length}
                      </span>
                    </button>
                    <div
                      className="drop-box"
                      uk-dropdown=" animation: reveal-top; animate-out: true; duration: 700"
                    >
                      <h5>Notification</h5>

                      {notificationList.length > 0 && (
                        <div>
                          <div className="notify__wrapper">
                            {notificationList.map((item) => {
                              const { Image, Title, Description } = item;
                              return (
                                <>
                                  <Link to="user-notification">
                                    <div className="uk-flex uk-flex-middle notify-wrap">
                                      <div className="notify-img">
                                        <img src={Image} alt="dp" />
                                      </div>

                                      <div className="ms-3">
                                        <div className="notify-title">
                                          {Title}
                                        </div>
                                        <div className="notify-desc">
                                          {Description}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </>
                              );
                            })}
                          </div>

                          <div className="notify__footer">
                            <Link to="user-notification">
                              <p>Show all notifications</p>
                            </Link>
                          </div>
                        </div>
                      )}

                      {notificationList.length <= 0 && (
                        <div className="p-2 no-notification">
                          No notifications.
                        </div>
                      )}
                    </div>
                  </span>
                )}

                {/* <span>
                  <img
                    src={
                      userDetails.UserImage === null
                        ? placeholder
                        : userDetails.UserImage
                    }
                    alt="image"
                    style={{
                      cursor: "pointer",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      setImgPreview(
                        userDetails.UserImage === null
                          ? placeholder
                          : userDetails.UserImage
                      );
                      setImgPrv(true);
                    }}
                  />
                </span> */}

                <span className="large-nav">
                  <div className="user-info text-start  uk-margin-remove uk-padding-remove">
                    {userDetails.MiddleName
                      ? userDetails.FirstName +
                        " " +
                        userDetails.MiddleName +
                        " " +
                        userDetails.LastName
                      : userDetails.FirstName + " " + userDetails.LastName}
                  </div>
                  <div className="info-designation text-start">
                    {userDetails.DesignationName}
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="uk-flex uk-flex-wrap ps-2 uk-flex-middle">
            {/* <span>
              <i
                className="fas fa-redo ms-3 pointer"
                onClick={handleRefresh}
              
                title="Refresh"
              ></i>
            </span> */}
            <span className=" logoff">
              {/* <AiOutlinePoweroff
                size={18}
                className="nav-power-icons"
                onClick={showLogout}
              
              /> */}
              <span>
                <img
                  src={
                    userDetails.UserImage === null
                      ? placeholder
                      : userDetails.UserImage
                  }
                  alt="image"
                  style={{
                    cursor: "pointer",
                    height: "35px",
                    borderRadius: "50%",
                  }}
                  onClick={showLogout}
                />
              </span>
              <Popover
                open={Boolean(logoutDropdown)}
                anchorEl={logoutDropdown}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 62, left: 4500 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={() => setLogoutDropdown(null)}
              >
                <div className="sec-logout" onClick={showAlert}>
                  <button type="button" class="btn-logout">
                    <AiOutlinePoweroff size={18} className="me-2" />
                    {mode === "en" ? "Logout" : "लगआउट"}
                  </button>
                </div>
              </Popover>

              {/* {logoutAlert && showLogoutPopup()} */}
            </span>
            <div
              className="dots-icon"
              // onClick={() => {
              //   setMobNav(!mobNav);
              // }}
              style={{ height: "max-content" }}
            >
              <BsThreeDotsVertical
                className="dots"
                color="#000"
                size="1.2rem"
              />
              <div
                id="mob-nav"
                // className={`${mobNav ? "mob-nav" : ""}`}
                className="mob-nav"
                uk-dropdown="mode: click; animation: reveal-top; animate-out: true; duration: 700"
              >
                <div className="uk-margin-bottom">
                  <h3 className="text-start text-dark">
                    {/* <span
                className="uk-margin-remove"
                style={{ color: mode === "light" ? "#004aad" : "#fff" }}
              >
                Elite
              </span>{" "}
              Office */}
                    {userDetails.ComName}
                  </h3>
                  <p className="text-start">{userDetails.ComBranch}</p>
                  <p className="text-start">
                    {userDetails.ComAddress}, {userDetails.ComTel}
                  </p>
                </div>

                {/* <div className="uk-margin-bottom">
            <span style={{ paddingLeft: "30px" }}>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                defaultChecked
                onClick={toggleLang}
              />
              <span className="uk-margin-small-left">
              
              </span>
            </span>
          </div> */}

                <div className="uk-margin-bottom">
                  <span className="user-info text-start uk-margin-remove  uk-padding-remove">
                    {userDetails.MiddleName
                      ? userDetails.FirstName +
                        " " +
                        userDetails.MiddleName +
                        " " +
                        userDetails.LastName
                      : userDetails.FirstName + " " + userDetails.LastName}
                  </span>
                  <span className="info-designation text-start d-block">
                    {userDetails.DesignationName}
                  </span>
                </div>

                <div className="uk-margin-bottom text-start">
                  {userDetails.IsManager === 0 && (
                    <span className="notify me-5" uk-margin>
                      <button type="button" className="btn p-0">
                        <FaRegBell size="1.3rem" color="#000" />
                        <span className="uk-badge">
                          {notificationList.length}
                        </span>
                      </button>
                      <div className="drop-box">
                        <h5>Notification</h5>

                        {notificationList.length > 0 && (
                          <div>
                            <div className="notify__wrapper">
                              {notificationList.map((item) => {
                                const { Image, Title, Description } = item;
                                return (
                                  <>
                                    <Link to="user-notification">
                                      <div className="uk-flex uk-flex-middle notify-wrap">
                                        <div className="notify-img">
                                          <img src={Image} alt="dp" />
                                        </div>

                                        <div className="ms-3">
                                          <div className="notify-title">
                                            {Title}
                                          </div>
                                          <div className="notify-desc">
                                            {Description}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </>
                                );
                              })}
                            </div>

                            <div className="notify__footer">
                              <Link to="user-notification">
                                <p>Show all notifications</p>
                              </Link>
                            </div>
                          </div>
                        )}

                        {notificationList.length === 0 && (
                          <div className="p-2 no-notification">
                            No notifications.
                          </div>
                        )}
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* <div
        className={
          !logoutAlert && sidebarController ? "mainNav" : "mainNav pushMainNav"
        }
      >
        <div class="topnav">
          <div className="nav-left">
            {sidebarController ? (
              windowWidth > 1100 ? (
                <AiOutlineMenu
                  size={18}
                  className="nav-menu-icons"
                  onClick={() => {
                    setSidebarController(!sidebarController);
                  }}
                />
              ) : (
                <></>
              )
            ) : (
              <AiOutlineMenu
                size={18}
                className="nav-menu-icons"
                onClick={() => {
                  setSidebarController(!sidebarController);
                }}
              />
            )}
            <div className="nav-user-details">
              <img
                src={userDetails.Image}
                alt="ppic"
                className="nav-user-profile"
              />
              <div className="nav-user-info">
                <span className="info-name text-start">
                  {userDetails.MiddleName
                    ? userDetails.FirstName +
                      " " +
                      userDetails.MiddleName +
                      " " +
                      userDetails.LastName
                    : userDetails.FirstName + " " + userDetails.LastName}
                </span>
                <span className="info-designation text-start">
                  {userDetails.DesignationName}
                </span>
              </div>
            </div>
          </div>

          <div className="nav-right">
            <BsSearch size={18} className="nav-search-icons" />
            <IoIosRefresh
              size={18}
              className="nav-icons"
              onClick={reloadLayout}
            />
            <AiFillMessage size={18} className="nav-icons" />
            <MdNotificationsNone size={18} className="nav-icons" />
            <AiOutlinePoweroff
              size={18}
              className="nav-power-icons"
              onClick={showLogout}
            />
            <Popover
              open={Boolean(logoutDropdown)}
              anchorEl={logoutDropdown}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 62, left: 4500 }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => setLogoutDropdown(null)}
            >
              <div className="sec-logout" onClick={showAlert}>
                <button type="button" class="btn-logout">
                  <AiOutlinePoweroff size={18} className="me-2" />
                  Logout
                </button>
              </div>
            </Popover>

            {logoutAlert && showLogoutPopup()}
          </div>
        </div>
      </div> */}

      {/* -----Log out PopUP */}
      <div className="container-fluid p-0 logoutPopup-wrapper addLogoutPopBg">
        <div className="logoutpopup-inner addLogoutPop">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
              <div className="popUpHeader ">
                <div className="popUpTitle">Elite Office</div>
                <div className="popUpClose">
                  <img
                    className="popUpCloseIcon"
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={closePop}
                  />
                </div>
              </div>

              <div className="logoutpopup-body text-start ps-4">
                {mode === "en"
                  ? "Do you want to Logout ?"
                  : "के तपाई लगआउट गर्न चाहनुहुन्छ?"}
              </div>
              <div className="logoutpopup-footer mt-4">
                <button
                  type="button"
                  class="btn btn-sm ps-4 pe-4 me-2 ms-3"
                  style={{
                    backgroundColor: "var(--button-color)",
                    color: "white",
                  }}
                  onClick={logoutHandler}
                >
                  {mode === "en" ? "Logout" : "लगआउट"}
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-sm ps-4 pe-4 me-3 "
                  onClick={closePop}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----Log out PopUP */}

      {imgPrv &&
        ShowImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}
    </>
  );
}
