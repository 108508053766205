import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";

export default function ResignationPopup({
  setPopup,
  reload,
  setReload,
  formValue,
  setFormValue,
  formError,
  setFormError,
  rgsnList,
  popup,
  DFlag,
  isApproved,
  setIsApproved,
}) {
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setPopup(false);
    setFormError({});
    $(".addResignationPopBg").fadeOut(500);
    $(".addResignationPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (popup) {
      $(".addResignationPopBg").fadeIn(500);
      $(".addResignationPop").slideDown(500);
    }
  }, [popup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    if (name === "department") {
      formValue.staff = "";
    }
    setFormValue({ ...formValue, [name]: value });
  };

  const handleDate = ({ adDate }) => {
    // setFromDate(adDate);
    let name = "date";
    setFormValue({ ...formValue, [name]: adDate });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    // if (!values.subdepartment) {
    //   errors.subdepartment = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (!values.department) {
      errors.department = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.staff) {
      errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.date) {
      errors.date = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.reason) {
      errors.reason = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setFormError(validate(formValue));
    setIsSubmit(true);
  };

  const addData = async (dataToSend) => {
    const response = await fetch(dataToSend.FetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Signature: "p0m76",
      },
      body: JSON.stringify(dataToSend),
    });
    const cooptive = await response.json();
    return cooptive;
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID.toString(),
        Flag: "i",
        StaffID: formValue.staff,
        Type: formValue.type === "R" ? "R" : "T",
        TermDate: formValue.date,
        Reason: formValue.reason,
        IsApproved: isApproved ? "Y" : "N",
        FetchURL: `${appURL}api/admin/reg-term`,
      };

      addData(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          rgsnList();
          setPopup(false);
          $(".addResignationPopBg").fadeOut(500);
          $(".addResignationPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formError]);

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";

        setDepartmentList(postResult);
      } else {
      }
    });
  };

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, [formValue.department]);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const departmentId = formValue.department;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      Designation: departmentId,
      BranchID: BranchID,
      WorkingStatus: "-1",
      IsActive: "-1",
      FiscalID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [formValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: formValue.department,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addResignationPopBg">
        <div className="newpopup addResignationPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Resignation" : "राजीनामा"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label
                          htmlFor="department"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="department"
                          value={formValue.department}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>

                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                        {formError.department && (
                          <p className="errormsg">{formError.department}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label htmlFor="staff" style={{ fontSize: "12px" }}>
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="staff"
                          value={formValue.staff}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Staff"
                              : "कर्मचारी चयन गर्नुहोस्"}
                          </option>
                          {staffList.map((item) => (
                            <option key={item.UserID} value={item.UID}>
                              {item.FirstName + " " + item.LastName}
                            </option>
                          ))}
                        </select>
                        {formError.staff && (
                          <p className="errormsg">{formError.staff}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <div style={{ textAlign: "left" }}>
                          <label className="form-label" htmlFor="purpose">
                            {mode === "en" ? "Type" : "टाइप"}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                        </div>

                        <div
                          className="d-flex uk-flex-wrap"
                          style={{ rowGap: "10px" }}
                        >
                          <div>
                            <input
                              type="radio"
                              className="radio--button"
                              id="R"
                              value="R"
                              name="type"
                              checked={formValue.type === "R"}
                              onChange={handleChange}
                            />
                            <label
                              className="label-radio-routine px-3"
                              htmlFor="R"
                              style={{ marginRight: "10px" }}
                            >
                              {mode === "en" ? "Resignation" : "राजीनामा"}
                            </label>
                          </div>

                          <div>
                            <input
                              type="radio"
                              className="radio--button"
                              id="T"
                              value="T"
                              name="type"
                              checked={formValue.type === "T"}
                              onChange={handleChange}
                            />
                            <label
                              className="label-radio-routine px-3"
                              htmlFor="T"
                              style={{ marginRight: "10px" }}
                            >
                              {mode === "en" ? "Termination" : "समाप्ति"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          className="text-start mb-1"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en" ? "Termination Date" : "समाप्ति मिति"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        {DFlag === "N" ? (
                          <Calendar
                            className="form-control form-control-sm pt-0 pb-0 "
                            dateFormat="YYYY/MM/DD"
                            // defaultDate="2010/01/01"
                            theme="default"
                            language="en"
                            // minDate={getNepaliDate()}
                            // maxDate={getMaxNepaliDate()}
                            value={formValue.date}
                            onChange={handleDate}
                            name="date"
                            hideDefaultValue={true}
                            placeholder={
                              mode === "en"
                                ? "Termination Date"
                                : "समाप्ति मिति"
                            }
                            key={reload}
                          />
                        ) : (
                          <input
                            type="date"
                            value={formValue.date}
                            name="date"
                            placeholder="Select a Date"
                            className="form-control form-control-sm "
                            onChange={handleChange}
                          />
                        )}
                        {formError.date && (
                          <p className="errormsg">{formError.date}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="desc" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Reason" : "कारण"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <textarea
                        id="desc"
                        value={formValue.reason}
                        onChange={handleChange}
                        style={{ fontSize: "13px" }}
                        class="form-control ps-2"
                        name="reason"
                        rows="3"
                        cols="12"
                        placeholder={mode === "en" ? "Reason" : "कारण"}
                      ></textarea>
                      {formError.reason && (
                        <p className="errormsg">{formError.reason}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start">
                    <div className="form-group">
                      <div class="form-check" style={{ fontSize: "12px" }}>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="isApproved"
                          name="isApproved"
                          checked={isApproved}
                          onChange={() => setIsApproved(!isApproved)}
                        />
                        <label
                          style={{ fontSize: "12px", cursor: "pointer" }}
                          class="form-check-label"
                          htmlFor="isApproved"
                        >
                          {mode === "en" ? "Is Approved" : "स्वीकृत छ"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
