import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";

import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HolidayPopup from "./HolidayPopup";
import HolidayPopupEdit from "./HolidayPopupEdit";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import ConfirmPopup from "../../../hooks/confirmPopup";
import { GetNepaliDate } from "../../../hooks/dateConvertor";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function AdminHoliday() {
  const {
    fiscalYear,
    todayDate,
    expires,
    appURL,
    customStyles,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const [holidayPopup, setHolidayPopup] = useState(false);
  const [holidayEditPopup, setHolidayEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [originalUserList, setOriginalUserList] = useState(null);
  const searchInput = useRef("");

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");
  const [DFlag, setDflag] = useState("N");

  var d = new Date();
  const holidayValue = {
    event: "",
    eventDate: GetNepaliDate(d),
    option: "",
  };
  const [holidayFormValue, setHolidayFormValue] = useState(holidayValue);
  const [holidayFormError, setHolidayFormError] = useState({});

  const { User } = useContext(AuthContext);
  const [re, setRe] = useState();

  const addLeaveNote = (e) => {
    setHolidayPopup(true);

    setHolidayFormValue(holidayValue);
    setRe(Math.random());
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setHolidayEditPopup(true);
    setTitleID(datas.EventID);
    setHolidayFormValue({
      event: datas.Event,
      // eventDate: datas.EnglishDate,
      option: datas.EveType,
      eventDate:
        DFlag === "N" ? GetNepaliDate(datas.EnglishDate) : datas.EnglishDate,
    });
  };

  //API to hit Holiday status

  // const stateInitial = [];
  // const [newStat, setNewStat] = useState(stateInitial);

  // const deactivateDepart = (ID, IsActive) => {
  //   const dataForm = {
  //     ComID: User.CompanyId,
  //     StaffID: User.UID,
  //     HolidayID: ID,
  //     Flag: "US",
  //     Status: IsActive,
  //     BranchID: User.BranchId,
  //     FiscalID: User.FiscalID,
  //     FetchURL: `${appURL}api/admin/holiday`,
  //     Type: "POST",
  //   };
  //   if (IsActive === 1) {
  //     dataForm.Status = 0;
  //   } else {
  //     dataForm.Status = 1;
  //   }
  //   Fetchdata(dataForm).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       holiList();
  //       let statsN = JSON.parse(JSON.stringify(newStat));
  //       let pitchStatus;

  //       if (dataForm.Status === 1) {
  //         pitchStatus = "Activated";
  //       } else if (dataForm.Status === 0) {
  //         pitchStatus = "Deactivated";
  //       }

  //       setNewStat(statsN);
  //       toast.success(result.Message, {
  //         style: {
  //           color: "green",
  //           fontSize: "13px",
  //         },
  //         theme: "light",
  //       });
  //     } else {
  //       toast.error("Error: " + result.Message, {
  //         style: {
  //           color: "red",
  //           fontSize: "13px",
  //         },
  //         theme: "light",
  //       });
  //     }
  //   });
  // };

  // const changeStatus = (ID, IsActive) => {
  //   deactivateDepart(ID, IsActive);
  // };

  // const checkStatus = (IsActive) => {
  //   if (IsActive === 1) {
  //     return mode === "en" ? "Deactivate" : "निष्क्रिय";
  //   } else if (IsActive === 0) {
  //     return mode === "en" ? "Activate" : "सक्रिय";
  //   }
  // };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Event" : "घटना",
      // center: true,
      // width: "200px",
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Event,
    },
    {
      name: mode === "en" ? "Nepali Date" : "नेपाली मिति",
      grow: 1,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.NepaliDate : englishToNepaliNumber(row.NepaliDate),
    },
    {
      name: mode === "en" ? "English Date" : "अंग्रेजी मिति",
      grow: 1,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en"
          ? row.EnglishDate
          : englishToNepaliNumber(row.EnglishDate),
    },
    {
      name: mode === "en" ? "Day" : "दिन",
      grow: 1,
      // center: true,
      width: "120px",
      selector: (row) => {
        var days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        var npdays = [
          "आइतबार",
          "सोमबार",
          "मंगलबार",
          "बुधबार",
          "बिहिबार",
          "शुक्रबार",
          "शनिबार",
        ];
        var d = new Date(row.EnglishDate);
        if (mode === "en") {
          var dayName = days[d.getDay()];
        } else {
          var dayName = npdays[d.getDay()];
        }
        return dayName;
      },
    },

    {
      name: mode === "en" ? "Days Remaning" : "दिन बाँकी",
      // grow: 0,
      // center: true,
      width: "270px",
      selector: (row) => (
        <>
          {row.DaysRemaining < 0 && row.DaysRemaining !== -1 && (
            <span>
              {mode === "en"
                ? Math.abs(row.DaysRemaining)
                : englishToNepaliNumber(Math.abs(row.DaysRemaining))}{" "}
              {mode === "en" ? "days ago" : "दिन पहिले"}
            </span>
          )}
          {row.DaysRemaining === -1 && (
            <span>{mode === "en" ? "Yesterday" : "हिजो"}</span>
          )}
          {row.DaysRemaining === 0 && (
            <span>{mode === "en" ? "Today" : "आज"}</span>
          )}
          {row.DaysRemaining === 1 && (
            <span>{mode === "en" ? "Tommorow" : "भोलि"}</span>
          )}
          {row.DaysRemaining > 0 && row.DaysRemaining !== 1 && (
            <span>
              {mode === "en"
                ? row.DaysRemaining
                : englishToNepaliNumber(row.DaysRemaining)}{" "}
              {mode === "en" ? "days remaining" : "दिन बाँकी"}
            </span>
          )}
        </>
      ),
    },

    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => updateRequest(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const usercolumns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Event" : "घटना",
      // center: true,
      // width: "200px",
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Event,
    },
    {
      name: mode === "en" ? "Nepali Date" : "नेपाली मिति",
      grow: 1,
      // center: true,
      width: "150px",
      selector: (row) => row.NepaliDate,
    },
    {
      name: mode === "en" ? "English Date" : "अंग्रेजी मिति",
      grow: 1,
      // center: true,
      width: "150px",
      selector: (row) => row.EnglishDate,
    },
    {
      name: mode === "en" ? "Day" : "दिन",
      grow: 1,
      // center: true,
      width: "100px",
      selector: (row) => {
        var days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        var d = new Date(row.EnglishDate);
        var dayName = days[d.getDay()];
        return dayName;
      },
    },

    {
      name: mode === "en" ? "Days Remaning" : "दिन बाँकी",
      // grow: 0,
      // center: true,
      width: "270px",
      selector: (row) => (
        <>
          {row.DaysRemaining < 0 && row.DaysRemaining !== -1 && (
            <span>
              {Math.abs(row.DaysRemaining)}{" "}
              {mode === "en" ? "days ago" : "दिन पहिले"}
            </span>
          )}
          {row.DaysRemaining === -1 && (
            <span>{mode === "en" ? "Yesterday" : "हिजो"}</span>
          )}
          {row.DaysRemaining === 0 && (
            <span>{mode === "en" ? "Today" : "आज"}</span>
          )}
          {row.DaysRemaining === 1 && (
            <span>{mode === "en" ? "Tommorow" : "भोलि"}</span>
          )}
          {row.DaysRemaining > 0 && row.DaysRemaining !== 1 && (
            <span>
              {row.DaysRemaining}{" "}
              {mode === "en" ? "days remaining" : "दिन बाँकी"}
            </span>
          )}
        </>
      ),
    },
  ];

  const updateRequest = (row) => {
    setSelectedNote(row);
    setConfirmPopup(true);
  };

  //API to hit holiday list
  const [holidayList, setHolidayList] = useState([]);

  useEffect(() => {
    holiList();
  }, [reload]);

  const holiList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/event`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setHolidayList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(true);
        setHolidayList([]);
        setOriginalList([]);
      }
    });
  };

  // const [holidayUserList, setHolidayUserList] = useState([]);

  // useEffect(() => {
  //   const params = {
  //     FetchURL: `${appURL}api/holiday?ComID=${User.CompanyId}&BranchID=${User.BranchId}`,
  //     Type: "GET",
  //   };

  //   Fetchdata(params)
  //     .then(function (result) {
  //       if (result.StatusCode === 200) {
  //         const postResult = result.Holidays ? result.Holidays : "";
  //         setHolidayUserList(postResult);
  //         setOriginalUserList(postResult);
  //         setLoading(false);
  //       } else {
  //         setHolidayUserList([]);
  //         setOriginalList([]);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setHolidayUserList([]);
  //       setOriginalList([]);
  //       setLoading(false);
  //     });
  // }, []);

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Event"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setHolidayList(srchResult);
      } else {
        setHolidayList({});
      }
    } else {
      setHolidayList(originalList);
    }
  };
  // const searchUserHandler = (e) => {
  //   e.preventDefault();

  //   const srchQuery = searchInput.current.value.toLowerCase();
  //   if (srchQuery) {
  //     let srchResult = originalUserList.filter((list) => {
  //       return list["Event"].toLowerCase().includes(srchQuery);
  //     });

  //     if (srchResult) {
  //       setHolidayUserList(srchResult);
  //     } else {
  //       setHolidayUserList({});
  //     }
  //   } else {
  //     setHolidayUserList(originalUserList);
  //   }
  // };

  const day = (d) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const ddd = new Date(d.EnglishDate);
    const dayName = days[ddd.getDay()];
    return dayName;
  };

  const dataWithIndex = holidayList.map((d, i) => ({
    ...d,
    Index: i + 1,
    DaysRemaining:
      d.DaysRemaining < 0 && d.DaysRemaining !== -1
        ? `${Math.abs(d.DaysRemaining)} days ago`
        : d.DaysRemaining === -1
        ? "Yesterday"
        : d.DaysRemaining === 0
        ? "Today"
        : d.DaysRemaining === 1
        ? "Tommorow"
        : d.DaysRemaining > 0 && d.DaysRemaining !== 1
        ? `${d.DaysRemaining} days remaining`
        : "",
    Day: day(d),
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Event", field: "Event" },
    { header: "Nepali Date", field: "NepaliDate" },
    { header: "English Date", field: "EnglishDate" },
    { header: "Day", field: "Day" },
    { header: "Days Remaning", field: "DaysRemaining" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);

  };

  const dataToExcel = holidayList.map((d, i) => ({
    "S.N.": i + 1,
    Event: d.Event,
    "Nepali Date": d.NepaliDate,
    "English Date": d.EnglishDate,
    Day: day(d),
    "Days Remaning":
      d.DaysRemaining < 0 && d.DaysRemaining !== -1
        ? `${Math.abs(d.DaysRemaining)} days ago`
        : d.DaysRemaining === -1
        ? "Yesterday"
        : d.DaysRemaining === 0
        ? "Today"
        : d.DaysRemaining === 1
        ? "Tommorow"
        : d.DaysRemaining > 0 && d.DaysRemaining !== 1
        ? `${d.DaysRemaining} days remaining`
        : "",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Holiday");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Event" : "घटना"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            {userDetails.IsManager !== 0 && (
              <div className="upper-dataTbl">
                <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
                  <div className="btn-addlnote mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={addLeaveNote}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                    </button>
                  </div>

                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Excel"
                      onClick={toExcel}
                      className="me-1 ms-2 border-0"
                    >
                      <RiFileExcel2Fill size="1rem" color="#136438" />
                    </button>
                  </div>
                  <div className="export-btn">
                    <button
                      uk-tooltip="Export Pdf"
                      onClick={toPdf}
                      className="mx-1 border-0"
                    >
                      <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <DataTable
              columns={userDetails.IsManager !== 0 ? columns : usercolumns}
              data={holidayList}
              // data={userDetails.IsManager !== 0 ? holidayList : holidayUserList}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
              fixedHeader
              fixedHeaderScrollHeight="370px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder={
                              mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                            }
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            // onChange={
                            //   userDetails.IsManager !== 0
                            //     ? searchHandler
                            //     : searchUserHandler
                            // }
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </>
      </div>

      <HolidayPopup
        setHolidayPopup={setHolidayPopup}
        holidayPopup={holidayPopup}
        reload={reload}
        setReload={setReload}
        holiList={holiList}
        holidayFormValue={holidayFormValue}
        setHolidayFormValue={setHolidayFormValue}
        holidayFormError={holidayFormError}
        setHolidayFormError={setHolidayFormError}
        DFlag={DFlag}
        re={re}
      />

      <HolidayPopupEdit
        setHolidayEditPopup={setHolidayEditPopup}
        holidayEditPopup={holidayEditPopup}
        reload={reload}
        setReload={setReload}
        holiList={holiList}
        holidayFormValue={holidayFormValue}
        setHolidayFormValue={setHolidayFormValue}
        holidayFormError={holidayFormError}
        setHolidayFormError={setHolidayFormError}
        titleId={titleId}
        DFlag={DFlag}
      />

      {confirmPopup &&
        selected_note &&
        ConfirmPopup({
          FormData: {
            AuthCode: User.AuthCode,
            ComID: User.ComID,
            UserID: User.UID,
            Flag: "R",
            EventID: selected_note.EventID,
            // FetchURL: "https://esnep.com/easyoffice/api/leave-status",
            FetchURL: `${appURL}api/admin/event`,
            Type: "POST",
          },
          reload: reload,
          setReload: setReload,
          setTrigger: setConfirmPopup,
          message:
            mode === "en"
              ? "Are you sure you want to delete ?"
              : " के तपाइँ मेटाउन चाहनुहुन्छ",
        })}
    </>
  );
}
