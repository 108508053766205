import React, { useContext } from "react";
import AccountGroupContext from "../../account group/accountGroupState/AccountGroupContext";

const Basic = ({
  handleChange,
  formValues,
  formErrors,
  checkedVat,
  setCheckedVat,
  checkedActive,
  setCheckedActive,
  checkedInv,
  setCheckedInv,
  checkedBill,
  setCheckedBill,
  agentUnderList,
  areaUnderList,
  checkedBankCash,
  setCheckedBankCash,
  mode,
}) => {
  const { nonAcGrpList } = useContext(AccountGroupContext);
  return (
    <>
      <div className="row text-start align-items-end">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="acGrp" style={{ fontSize: "12px" }}>
              {/* {mode === "en"
                ? "Select Account Group"
                : "खाता समूह चयन गर्नुहोस्"} */}
              {mode === "en" ? "Under" : "अन्तर्गत"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="acGrp"
              id="acGrp"
              value={formValues.acGrp}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en"
                  ? "Select Account Group"
                  : "खाता समूह चयन गर्नुहोस्"}
              </option>
              {nonAcGrpList.map((props) => {
                return (
                  <option key={props.AccGroupID} value={props.AccGroupID}>
                    {props.AccGroup}
                  </option>
                );
              })}
            </select>
            {/* {formErrors.pan && (
      <p className="errormsg">{formErrors.pan}</p>
    )} */}
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <div class="form-check" style={{ fontSize: "12px" }}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="GRADE"
                // name="allowSpace"
                // checked={allowSpace}
                // onChange={() => setAllowSpace(!allowSpace)}
              />
              <label
                style={{ fontSize: "12px", cursor: "pointer" }}
                class="form-check-label"
                htmlFor="GRADE"
              >
                {mode === "en" ? "Grade" : "ग्रेड"}
              </label>
            </div>
          </div>
          <div className="form-group mt-0">
            <label htmlFor="grade" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Grade" : "ग्रेड चयन गर्नुहोस्"}
            </label>
            <div className="d-flex">
              <div style={{ flexGrow: "1" }}>
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example"
                  name="grade"
                  id="grade"
                  value={formValues.grade}
                  onChange={handleChange}
                >
                  <option disabled value="" selected>
                    {mode === "en" ? "Select Grade" : "ग्रेड चयन गर्नुहोस्"}
                  </option>
                  <option value="1">grade</option>
                  <option value="2">grade</option>
                  <option value="3">grade</option>
                </select>
              </div>

              <button
                type="button"
                className=" btn btn-primary border-0"
                //   onClick={() => handleRemoveClick(i)}
              >
                +
              </button>
            </div>
            {/* {formErrors.commission && (
      <p className="errormsg">{formErrors.commission}</p>
    )} */}
          </div>
        </div>
      </div>

      <div className="row text-start ">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="agent" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Agent" : "एजेन्ट चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="agent"
              id="agent"
              value={formValues.agent}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Agent" : "एजेन्ट चयन गर्नुहोस्"}
              </option>
              {agentUnderList.map((props) => {
                return (
                  <option key={props.AgentID} value={props.AgentID}>
                    {props.Agent}
                  </option>
                );
              })}
            </select>
            {/* {formErrors.pan && (
      <p className="errormsg">{formErrors.pan}</p>
    )} */}
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="area" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Area" : "क्षेत्र चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="area"
              id="area"
              value={formValues.area}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Area" : "क्षेत्र चयन गर्नुहोस्"}
              </option>
              {areaUnderList.map((props) => {
                return (
                  <option key={props.AreaID} value={props.AreaID}>
                    {props.Area}
                  </option>
                );
              })}
            </select>
            {/* {formErrors.commission && (
      <p className="errormsg">{formErrors.commission}</p>
    )} */}
          </div>
        </div>
      </div>
      <div className="row text-start align-items-end">
        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <div class="form-check" style={{ fontSize: "12px" }}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="checkedVat"
                name="checkedVat"
                checked={checkedVat}
                onChange={() => setCheckedVat(!checkedVat)}
              />
              <label
                style={{ fontSize: "12px", cursor: "pointer" }}
                class="form-check-label"
                htmlFor="checkedVat"
              >
                {mode === "en" ? "Reg. VAT" : "दर्ता भ्याट"}
              </label>
            </div>
          </div>
          <div className="form-group mt-0">
            <label htmlFor="pan" style={{ fontSize: "12px" }}>
              {mode === "en" ? "PAN No." : "प्यान नं."}
            </label>
            <input
              id="pan"
              style={{ fontSize: "13px" }}
              type="text"
              className="form-control form-control-sm "
              name="pan"
              placeholder={mode === "en" ? "PAN No." : "प्यान नं."}
              value={formValues.pan}
              onChange={handleChange}
            />
            {/* {formErrors.commission && (
      <p className="errormsg">{formErrors.commission}</p>
    )} */}
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-lg-6">
          <div className="form-group">
            <label htmlFor="currency" style={{ fontSize: "12px" }}>
              {mode === "en" ? "Select Currency" : "मुद्रा चयन गर्नुहोस्"}
            </label>
            <select
              class="form-select form-select-sm"
              aria-label="Default select example"
              name="currency"
              id="currency"
              value={formValues.currency}
              onChange={handleChange}
            >
              <option disabled value="" selected>
                {mode === "en" ? "Select Currency" : "मुद्रा चयन गर्नुहोस्"}
              </option>
              <option value="NPR">Nepali (NPR)</option>
              <option value="US">US Dollar ($)</option>
              <option value="IN">Indian Rupee (RS)</option>
            </select>
            {/* {formErrors.pan && (
      <p className="errormsg">{formErrors.pan}</p>
    )} */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group">
          <label htmlFor="description" style={{ fontSize: "12px" }}>
            {mode === "en" ? "Description" : "विवरण"}
          </label>
          <textarea
            id="description"
            value={formValues.description}
            onChange={handleChange}
            style={{ fontSize: "13px" }}
            class="form-control ps-2"
            name="description"
            rows="7"
            cols="12"
            placeholder={mode === "en" ? "Description" : "विवरण"}
          ></textarea>
        </div>
      </div>

      <div className="row text-start uk-flex uk-flex-wrap">
        <div className="form-group me-3" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkedActive"
              name="checkedActive"
              checked={checkedActive}
              onChange={() => setCheckedActive(!checkedActive)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="checkedActive"
            >
              {mode === "en" ? "Active" : "सक्रिय"}
            </label>
          </div>
        </div>

        <div className="form-group me-3" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkedInv"
              name="checkedInv"
              checked={checkedInv}
              onChange={() => setCheckedInv(!checkedInv)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="checkedInv"
            >
              {mode === "en" ? "Affect Inventory" : "इन्भेन्टरीलाई असर गर्छ"}
            </label>
          </div>
        </div>
        <div className="form-group me-3" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkedBill"
              name="checkedBill"
              checked={checkedBill}
              onChange={() => setCheckedBill(!checkedBill)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="checkedBill"
            >
              {mode === "en" ? "Bill Wise Detail" : "बिल बुद्धिमानी विवरण"}
            </label>
          </div>
        </div>
        <div className="form-group me-3" style={{ width: "max-content" }}>
          <div class="form-check" style={{ fontSize: "12px" }}>
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkedBankCash"
              name="checkedBankCash"
              checked={checkedBankCash}
              onChange={() => setCheckedBankCash(!checkedBankCash)}
            />
            <label
              style={{ fontSize: "12px", cursor: "pointer" }}
              class="form-check-label"
              htmlFor="checkedBankCash"
            >
              {mode === "en" ? "Bank/Cash" : "बैंक/नगद"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Basic;
