import React, { useContext, useEffect, useState } from "react";
import "./changePassword.css";
import CloseIcon from "../../images/CloseIcon.svg";
import UpperbarContext from "../context/upperbar-context";
import AuthContext from "../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../hooks/getData";
import Toast from "../Toast";
import $ from "jquery";

export default function ChangePassword() {
  const { User } = useContext(AuthContext);
  const initalvalue = { oldPassword: "", newPassword: "" };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const { changePassPopup, setChangePassPopup, mode, appURL } =
    useContext(UpperbarContext);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
  };

  const closePopUp = () => {
    setChangePassPopup(false);
    $(".changePasswordPopBg").fadeOut(500);
    $(".changePasswordPop").slideUp(500);
    setFormValues(initalvalue);
    setformErrors({});
    setIsSubmit(false);
  };

  useEffect(() => {
    if (changePassPopup) {
      $(".changePasswordPopBg").fadeIn(500);
      $(".changePasswordPop").slideDown(500);
    }
  }, [changePassPopup]);

  const validate = (values) => {
    const errors = {};
    const ptn = /^\w+$/;
    const digitPtn = /[0-9]/;
    const alphaptn = /[a-z]/;
    const capalpha = /[A-Z]/;

    if (values.oldPassword && values.newPassword) {
      //code here
    } else {
      if (!values.oldPassword) {
        errors.oldPassword = mode === "en" ? "Required" : "आवश्यक छ";
      }
      if (!values.newPassword) {
        errors.newPassword = mode === "en" ? "Required" : "आवश्यक छ";
      }
      return errors;
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        OldPwd: formValues.oldPassword,
        NewPwd: formValues.newPassword,
        FetchURL: `${appURL}api/change-password`,
        Type: "POST",
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          $(".changePasswordPopBg").fadeOut(500);
          $(".changePasswordPop").slideUp(500);
          setFormValues(initalvalue);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
          setChangePassPopup(false);
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [formErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg changePasswordPopBg">
        <div className="newpopup changePasswordPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {" "}
              {mode === "en" ? "Change Password" : "पासवर्ड परिवर्तन"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-padding">
              <div className="row text-start mt-1">
                <div className="form-group">
                  <label htmlFor="oldpass" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Old Password" : "पुरानो पासवर्ड"}
                  </label>
                  <input
                    id="oldpass"
                    style={{ fontSize: "13px" }}
                    type="password"
                    className="form-control form-control-sm "
                    name="oldPassword"
                    value={formValues.oldPassword}
                    onChange={handleChange}
                  />
                  <p className="errormsg">{formErrors.oldPassword}</p>
                </div>
              </div>

              <div className="row text-start mt-2">
                <div className="form-group">
                  <label htmlFor="newpass" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "New Password" : " नया पासवर्ड"}
                  </label>
                  <input
                    id="newpass"
                    style={{ fontSize: "13px" }}
                    type="password"
                    className="form-control form-control-sm "
                    name="newPassword"
                    value={formValues.newPassword}
                    onChange={handleChange}
                  />
                  <p className="errormsg">{formErrors.newPassword}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="new-footer">
            <div className="row  mt-2 mb-2">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={handleSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
