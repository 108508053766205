import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import "../../entry/voucher/voucher.css";
import { ToastContainer, toast } from "react-toastify";
import { englishToNepaliNumber } from "nepali-number";

export default function ProvidentFund() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [chooseSalary, setChooseSalary] = useState("1");

  // const handleChange = (e) => {
  //   const target = e.target;
  //   const name = target.name;
  //   const value = target.value;
  //   setChooseSalary(value);
  // };

  //  API to show list
  const [providentList, setProvidentList] = useState([]);

  useEffect(() => {
    providentLst();
  }, []);

  const providentLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      Flag: "S",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/provident-staff`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";

        const updatedData = postResult.map((item) => ({
          ...item,
          UserID: item.UserID.toString(),
        }));

        setProvidentList(updatedData);
        setOriginalList(updatedData);
        setLoading(false);
      } else {
        setProvidentList([]);
        setOriginalList([]);
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      name: "S.N.",
      width: "70px",
      center: true,
      selector: (row, index) => index + 1,
    },
    {
      name: "Full Name",
      selector: (row) => row.FullName,
    },

    {
      name: "Provident Fund (%)",
      center: true,
      width: "200px",
      cell: (row, index) => {
        return (
          <>
            <input
              type="number"
              style={{ fontSize: "13px" }}
              className="form-control form-control-sm text-end"
              value={row.ProvidentFund}
              onChange={(event) =>
                handleInputChange(event, row, "ProvidentFund")
              }
            />
          </>
        );
      },
    },
  ];

  const handleInputChange = (event, row, field) => {
    const value = parseFloat(event.target.value).toString();
    const updatedProvidentList = providentList.map((item) => {
      if (item.UserID === row.UserID) {
        return {
          ...item,
          [field]: value > 100 || value < 0 ? "0" : value, // limit input value to 100
        };
      }
      return item;
    });
    setProvidentList(updatedProvidentList);
  };

  const keysToRemove = ["FullName"];
  const providentData = providentList.map((obj) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });


  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    setIsSubmit(true);
  };

  useEffect(() => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;

    if (isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        Flag: "I",
        Values: providentData,
        BranchID: Branch,
        FiscalID: Fiscal,
        Type: "POST",
        FetchURL: `${appURL}api/acc/admin/provident-staff`,
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          providentLst();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
      setIsSubmit(false);
    }
  }, [isSubmit]);
  // ------------------
  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setProvidentList(srchResult);
      } else {
        setProvidentList({});
      }
    } else {
      setProvidentList(originalList);
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Provident Fund" : "सञ्चय कोष"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-2">
              <div className="uk-flex  uk-flex-wrap uk-flex-right uk-flex-middle">
                {/* <div className="uk-flex uk-flex-wrap uk-flex-bottom me-3">
                  <div className="me-2 mt-3">
                    <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                    </button>
                  </div>
                </div> */}

                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-100 mb-3 mt-3">
              <div className="d-flex uk-flex-middle justify-content-end">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div
              className="mt-3 mt-3 tableHeight"
              style={{ maxHeight: "400px" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <DataTable
                    columns={columns}
                    data={providentList}
                    customStyles={customStyles}
                    pagination
                    paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                    fixedHeader
                    fixedHeaderScrollHeight="280px"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    dense
                    striped
                  />
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
}
