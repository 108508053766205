import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import "../ledger report/ledger.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import {
  GetNepaliDate,
  GetCurrYear,
  GetCurrMonth,
  GetEnglishDate,
} from "../../../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";
export default function DayBook() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, dateMode,userDetails, darkText, mode } =
    useContext(UpperbarContext);
  const [DFlag, setDflag] = useState("N");
  const [dateData, setDateData] = useState("S");
  const [voucherData, setVoucherData] = useState("-1");
  const [loading, setLoading] = useState(true);

  const cur_year = new Date().getFullYear();
  const nYears = generateArrayOfNepYears(cur_year);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  var date = new Date();
  const initalvalue = {
    // specificDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    // fromDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    // toDate: mode === "np" ? GetNepaliDate(date) : GetEnglishDate(date),
    specificDate: GetNepaliDate(date),
    fromDate: GetNepaliDate(date),
    toDate: GetNepaliDate(date),
    year: GetCurrYear(),
    month: GetCurrMonth(),
  };
  const [inputValues, setInputValues] = useState(initalvalue);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setDateData(value);
  };
  const handleVoucher = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setVoucherData(value);
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, specificDate: bsDate });
  };
  const handleFromDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, fromDate: bsDate });
  };
  const handleToDate = ({ bsDate }) => {
    setInputValues({ ...inputValues, toDate: bsDate });
  };

  //   API to day book list
  const [dayBookList, setDayBookList] = useState([]);

  useEffect(() => {
    dayLst();
  }, [dateData, inputValues, voucherData]);

  const dayLst = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      CID: "1",
      Flag: "S",
      DFlag: "DB",
      SFlag: dateData,
      // DtFlag: mode === "np" ? "N" : "E",
      DtFlag: "N",
      VoucherType: voucherData,
      Values:
        dateData === "S"
          ? inputValues.specificDate
          : dateData === "Y"
          ? inputValues.year
          : dateData === "M"
          ? `${inputValues.year}/${inputValues.month}`
          : dateData === "F"
          ? ""
          : "",
      DateFrom: dateData === "F" ? inputValues.fromDate : "",
      DateTo: dateData === "F" ? inputValues.toDate : "",
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/report/daybook`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDayBookList(postResult);
        setLoading(false);
      } else {
        setDayBookList([]);
        setLoading(false);
      }
    });
  };

  const groupedData = dayBookList.reduce((acc, curr) => {
    if (!acc[curr.Date]) {
      acc[curr.Date] = {};
    }
    if (!acc[curr.Date][curr.VoucherNo]) {
      acc[curr.Date][curr.VoucherNo] = [];
    }
    acc[curr.Date][curr.VoucherNo].push(curr);
    return acc;
  }, {});


  const rows = [];
  let prevDate;

  const groupedDataByDate = Object.entries(groupedData).map(([date, group]) => {
    const voucherNos = Object.keys(group);
    const length = voucherNos.reduce(
      (acc, voucherNo) => acc + group[voucherNo].length,
      0
    );
    return { date, length };
  });

  for (const date in groupedData) {
    const dateRows = [];
    let totalDr = 0;
    let totalCr = 0;
    for (const voucherNo in groupedData[date]) {
      const voucherNoRows = groupedData[date][voucherNo].map((item, index) => {
        totalDr += item.DrAmt;
        totalCr += item.CrAmt;
        const showDate = date !== prevDate;
        prevDate = date;

        const length = groupedDataByDate.find(
          (group) => group.date === date
        ).length;
        const showVoucherNo = index === 0;
        const voucherNoLength = Object.keys(
          groupedData[date][voucherNo]
        ).length;

        return (
          <tr key={`${date}-${voucherNo}-${index}`}>
            {showDate && (
              <td
                style={{ minWidth: "120px" }}
                className="rdt_TableCell tablecell"
                rowSpan={length}
              >
                {date}
              </td>
            )}
            {showVoucherNo && (
              <td
                style={{ minWidth: "70px" }}
                className="rdt_TableCell tablecell text-center"
                rowSpan={voucherNoLength}
              >
                {voucherNo}
              </td>
            )}
            <td
              style={{ minWidth: "300px" }}
              className="rdt_TableCell tablecell "
            >
              {item.Ledger}
            </td>
            <td
              style={{ minWidth: "150px" }}
              className="rdt_TableCell tablecell "
            >
              {item.VoucherType}
            </td>
            <td
              style={{ minWidth: "150px" }}
              className="rdt_TableCell tablecell text-end"
            >
              {item.DrAmt.toFixed(2)}
            </td>
            <td
              style={{ minWidth: "150px" }}
              className="rdt_TableCell tablecell text-end"
            >
              {item.CrAmt.toFixed(2)}
            </td>
          </tr>
        );
      });
      dateRows.push(...voucherNoRows);
    }
    dateRows.push(
      <tr key={`${date}-total`} className="reportTotal">
        <td colSpan={4} className="rdt_TableCell tablecell fw-bold text-end">
          {mode === "en" ? "Total" : "कुल"}
        </td>
        <td className="rdt_TableCell tablecell fw-bold text-end">
          {totalDr.toFixed(2)}
        </td>
        <td className="rdt_TableCell tablecell fw-bold text-end">
          {totalCr.toFixed(2)}
        </td>
      </tr>
    );
    rows.push(...dateRows);
  }

  let dr = dayBookList.reduce(function (prev, current) {
    return prev + +current.DrAmt;
  }, 0);
  let cr = dayBookList.reduce(function (prev, current) {
    return prev + +current.CrAmt;
  }, 0);

  return (
    <>
      {/* <Toast /> */}

      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {" "}
              {mode === "en" ? "Day Book" : "दिन पुस्तक"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-5">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="ledger"
                      value={dateData}
                      onChange={handleChange}
                      className="form-select form-select-sm"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Option"
                          : "विकल्प चयन गर्नुहोस्"}
                      </option>
                      <option value="S">
                        {mode === "en" ? "Specific Date" : "विशिष्ट मिति"}
                      </option>
                      <option value="F">
                        {mode === "en" ? "Date From & To" : "मिति देखि र सम्म"}
                      </option>
                      <option value="Y">
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      <option value="M">
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                    </select>
                  </div>

                  {dateData === "S" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select English Date"
                          : "नेपाली मिति चयन गर्नुहोस्"}
                      </label>
                      {dateMode === "np" ? (
                        <Calendar
                          // id="eventDate"
                          className="form-control form-control-sm pt-0 pb-0 "
                          dateFormat="YYYY/MM/DD"
                          // defaultDate="2010/01/01"
                          theme="default"
                          language="en"
                          name="specificDate"
                          // value={holidayFormValue.eventDate}
                          onChange={handleDate}

                          // hideDefaultValue={true}
                          // placeholder={"Select Follow Date"}
                        />
                      ) : (
                        <input
                          type="date"
                          value={inputValues.specificDate}
                          placeholder="Select a Date"
                          className="form-control form-control-sm "
                          name="specificDate"
                          onChange={handleOnChange}
                          // id="specificDate"
                        />
                      )}
                    </div>
                  )}

                  {dateData === "F" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select English From Date"
                            : "मिति देखि नेपाली चयन गर्नुहोस्"}
                        </label>
                        {mode === "np" ? (
                          <Calendar
                            // id="eventDate"
                            className="form-control form-control-sm pt-0 pb-0 "
                            dateFormat="YYYY/MM/DD"
                            // defaultDate="2010/01/01"
                            theme="default"
                            language="en"
                            // value={holidayFormValue.eventDate}
                            onChange={handleFromDate}
                            name="fromDate"

                            // hideDefaultValue={true}
                            // placeholder={"Select Follow Date"}
                          />
                        ) : (
                          <input
                            type="date"
                            value={inputValues.fromDate}
                            placeholder="Select a Date"
                            className="form-control form-control-sm "
                            onChange={handleOnChange}
                            name="fromDate"
                            // id="eventDate"
                          />
                        )}
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select English To Date"
                            : "मिति सम्म नेपाली चयन गर्नुहोस्"}
                        </label>
                        {mode === "np" ? (
                          <Calendar
                            // id="eventDate"
                            className="form-control form-control-sm pt-0 pb-0 "
                            dateFormat="YYYY/MM/DD"
                            // defaultDate="2010/01/01"
                            theme="default"
                            language="en"
                            // value={holidayFormValue.eventDate}
                            onChange={handleToDate}
                            name="toDate"

                            // hideDefaultValue={true}
                            // placeholder={"Select Follow Date"}
                          />
                        ) : (
                          <input
                            type="date"
                            value={inputValues.toDate}
                            placeholder="Select a Date"
                            className="form-control form-control-sm "
                            onChange={handleOnChange}
                            name="toDate"
                            // id="eventDate"
                          />
                        )}
                      </div>
                    </>
                  )}

                  {dateData === "Y" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example "
                        onChange={handleOnChange}
                        name="year"
                        value={inputValues.year}
                      >
                        <option disabled value="" selected>
                          {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                        </option>
                        {nYears.map((list) => (
                          <>
                            <option key={list} value={list}>
                              {list}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  )}
                  {dateData === "M" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={handleOnChange}
                          name="year"
                          value={inputValues.year}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Year"
                              : "वर्ष चयन गर्नुहोस्"}
                          </option>
                          {nYears.map((list) => (
                            <>
                              <option key={list} value={list}>
                                {list}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>

                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Month"
                            : "महिना चयन गर्नुहोस्"}
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example "
                          onChange={handleOnChange}
                          name="month"
                          value={inputValues.month}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Month"
                              : "महिना चयन गर्नुहोस्"}
                          </option>
                          <option value="01">
                            {mode === "en" ? "Baishakh" : "बैशाख"}
                          </option>
                          <option value="02">
                            {mode === "en" ? "Jestha" : "जेष्ठ"}
                          </option>
                          <option value="03">
                            {mode === "en" ? "Ashar" : "असार"}
                          </option>
                          <option value="04">
                            {mode === "en" ? "Shrawan" : "श्रावण"}
                          </option>
                          <option value="05">
                            {mode === "en" ? "Bhadra" : "भाद्र"}
                          </option>
                          <option value="06">
                            {mode === "en" ? "Asoj" : "असोज"}
                          </option>
                          <option value="07">
                            {mode === "en" ? "Kartik" : "कार्तिक"}
                          </option>
                          <option value="08">
                            {mode === "en" ? "Mangsir" : "मंसिर"}
                          </option>
                          <option value="09">
                            {mode === "en" ? "Poush" : "पौष"}
                          </option>
                          <option value="10">
                            {mode === "en" ? "Magh" : "माघ"}
                          </option>
                          <option value="11">
                            {mode === "en" ? "Falgun" : "फाल्गुन"}
                          </option>
                          <option value="12">
                            {mode === "en" ? "Chaitra" : "चैत्र"}
                          </option>
                        </select>
                      </div>
                    </>
                  )}

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Voucher Type"
                        : "भाउचर प्रकार चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={voucherData}
                      onChange={handleVoucher}
                      className="form-select form-select-sm"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Voucher Type"
                          : "भाउचर प्रकार चयन गर्नुहोस्"}
                      </option>

                      <option value="-1">
                        {mode === "en" ? "All" : "सबै"}
                      </option>
                      <option value="JAV">
                        {mode === "en" ? "Journal voucher" : "जर्नल भाउचर"}
                      </option>
                      <option value="REV">
                        {mode === "en" ? "Receipt Voucher" : "रसिद भाउचर"}
                      </option>
                      <option value="PAV">
                        {mode === "en" ? "Payment Voucher" : "भुक्तानी भाउचर"}
                      </option>
                      <option value="COV">
                        {mode === "en" ? "Contra Voucher" : "कन्ट्रा भाउचर"}
                      </option>
                      <option value="PAV">
                        {mode === "en" ? "Purchase Invoice" : "खरिद इनभ्वाइस"}
                      </option>
                      <option value="SAV">
                        {mode === "en" ? "Sales Invoice" : "बिक्री इनभ्वाइस"}
                      </option>
                      <option value="DNV">
                        {mode === "en" ? "Debit Note" : "डेबिट नोट"}
                      </option>
                      <option value="CNV">
                        {mode === "en" ? "Credit Note" : "क्रेडिट नोट"}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 tableHeight">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {dayBookList.length > 0 ? (
                    <table className="uk-table reportTable">
                      <thead>
                        <tr className="fixed-head">
                          <td
                            className="rdt_TableHeadRow tableHead"
                            width="120px"
                          >
                            {mode === "en" ? "Date" : "मिति"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="70px"
                          >
                            {mode === "en" ? "V.N" : "वि.एन"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead"
                            width="300px"
                          >
                            {mode === "en" ? "Particular" : "विशेष"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead "
                            width="150px"
                          >
                            {mode === "en" ? "V. Type" : "वि. प्रकार"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead text-end"
                            width="150px"
                          >
                            {mode === "en" ? "Debit Amount" : "डेबिट रकम"}
                          </td>
                          <td
                            className="rdt_TableHeadRow tableHead text-end"
                            width="150px"
                          >
                            {mode === "en" ? "Credit Amount" : "क्रेडिट रकम"}
                          </td>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                      <tfoot>
                        <tr className="reportTotal">
                          <td
                            colSpan={4}
                            className="rdt_TableCell tablecell text-end"
                          >
                            <strong>
                              {mode === "en" ? "Grand Total" : "कूल जम्मा"}
                            </strong>
                          </td>
                          <td className="rdt_TableCell tablecell text-end">
                            <strong>{dr.toFixed(2)}</strong>
                          </td>
                          <td className="rdt_TableCell tablecell text-end">
                            <strong>{cr.toFixed(2)}</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  ) : (
                    <p className="text-center">
                      {" "}
                      {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
}
