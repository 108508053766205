import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";

import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BankPopup from "./BankPopup";
import BankPopupEdit from "./BankPopupEdit";

import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import ConfirmPopup from "../../../hooks/confirmPopup";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import {
  AiOutlineEdit,
  AiOutlineSearch,
  AiTwotonePrinter,
} from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Bank() {
  const {
    fiscalYear,
    customStyles,
    expires,
    todayDate,
    appURL,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const [bankPopup, setBankPopup] = useState(false);
  const [bankEditPopup, setBankEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");

  const [chooseDepartment, setChooseDepartment] = useState("-1");
  const [chooseSubDepartment, setChooseSubDepartment] = useState("-1");
  const [chooseStaff, setChooseStaff] = useState("-1");

  const bankValue = {
    department: "",
    subDepartment: "",
    staff: "",
    bank: "",
    accountName: "",
    accountNo: "",
    branch: "",
  };
  const [bankFormValue, setBankFormValue] = useState(bankValue);
  const [bankFormError, setBankFormError] = useState({});

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setBankPopup(true);
    setBankFormValue(bankValue);
  };

  const handleDepartment = (e) => {
    setChooseStaff("-1");
    setChooseSubDepartment("-1");

    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDepartment(value);
  };

  const handleSubDepartment = (e) => {
    setChooseStaff("-1");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseSubDepartment(value);
  };

  const handleStaff = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseStaff(value);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setBankEditPopup(true);
    setTitleID(datas.BankID);
    setBankFormValue({
      department: datas.DepartID,
      subDepartment: datas.SupDepartID,
      staff: datas.UserID,
      bank: datas.BankName,
      accountName: datas.AcName,
      accountNo: datas.AcNo,
      branch: datas.Branch,
    });
  };

  const updateRequest = (row) => {
    setSelectedNote(row);
    setConfirmPopup(true);
  };

  //API to hit Bank status

  // const stateInitial = [];
  // const [newStat, setNewStat] = useState(stateInitial);

  // const deactivateDepart = (ID, IsActive) => {
  //   const dataForm = {
  //     ComID: User.CompanyId,
  //     StaffID: User.UID,
  //     UserID: chooseStaff,
  //     BankID: ID,
  //     Flag: "US",
  //     Status: IsActive,
  //     FetchURL: `${appURL}api/admin/u-bank`,
  //     Type: "POST",
  //   };

  //   if (IsActive === 1) {
  //     dataForm.Status = 0;
  //   } else {
  //     dataForm.Status = 1;
  //   }
  //   Fetchdata(dataForm).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       bnkList();
  //       let statsN = JSON.parse(JSON.stringify(newStat));
  //       let pitchStatus;

  //       if (dataForm.Status === 1) {
  //         pitchStatus = "Activated";
  //       } else if (dataForm.Status === 0) {
  //         pitchStatus = "Deactivated";
  //       }

  //       setNewStat(statsN);
  //       toast.success(result.Message, {
  //         style: {
  //           color: "green",
  //           fontSize: "13px",
  //         },
  //         theme: "light",
  //       });
  //     } else {
  //       toast.error("Error: " + result.Message, {
  //         style: {
  //           color: "red",
  //           fontSize: "13px",
  //         },
  //         theme: "light",
  //       });
  //     }
  //   });
  // };

  // const changeStatus = (ID, IsActive) => {
  //     deactivateDepart(ID, IsActive);
  // };

  // const checkStatus = (IsActive) => {
  //   if (IsActive === 1) {
  //     return mode === "en" ? "Deactivate" : "निष्क्रिय";
  //   } else if (IsActive === 0) {
  //     return mode === "en" ? "Activate" : "सक्रिय";
  //   }
  // };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Full Name" : "पुरा नाम",
      // center: true,
      // grow: 0,
      // width: "300px",
      // minWidth: "200px",
      selector: (row) => row.FullName,
    },
    {
      name: mode === "en" ? "Bank" : "बैंक",
      // center: true,
      // grow: 0,
      // width: "300px",
      minWidth: "150px",
      selector: (row) => row.BankName,
    },
    {
      name: mode === "en" ? "Account Name" : "खाताको नाम",
      // center: true,
      // grow: 0,
      width: "250px",
      selector: (row) => row.AcName,
    },
    {
      name: mode === "en" ? "Account Number" : "खाता नम्बर",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) =>
        mode === "en" ? row.AcNo : englishToNepaliNumber(row.AcNo),
    },
    {
      name: mode === "en" ? "Branch" : "साखा",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => row.Branch,
    },

    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {/* <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "} */}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => updateRequest(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit Bank list
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    bnkList();
  }, [chooseStaff, reload]);

  const bnkList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      StaffID: chooseStaff,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/bank`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBankList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setBankList([]);
        setOriginalList([]);
        setLoading(true);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return (
          list["BankName"].toLowerCase().includes(srchQuery) ||
          list["AcName"].toLowerCase().includes(srchQuery)
        );
      });

      if (srchResult) {
        setBankList(srchResult);
      } else {
        setBankList({});
      }
    } else {
      setBankList(originalList);
    }
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [chooseDepartment]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: chooseDepartment,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: 1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  const dataWithIndex = bankList.map((d, i) => ({
    ...d,
    Index: i + 1,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Bank", field: "BankName" },
    { header: "Account Name", field: "AcName" },
    { header: "Account Number", field: "AcNo" },
    { header: "Branch", field: "Branch" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = bankList.map((d, i) => ({
    "S.N.": i + 1,
    Bank: d.BankName,
    "Account Name": d.AcName,
    "Account Number": d.AcNo,
    Branch: d.Branch,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 25 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Bank");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Bank" : "बैंक"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseDepartment}
                      onChange={handleDepartment}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {departmentList.map((item) => (
                        <option
                          key={item.DepartmentID}
                          value={item.DepartmentID}
                        >
                          {item.Department}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Sub-Department"
                        : "उप विभाग चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseSubDepartment}
                      onChange={handleSubDepartment}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Sub-Department"
                          : "उप विभाग चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {subdepartmentList.map((item) => (
                        <option key={item.SubDepartID} value={item.SubDepartID}>
                          {item.SubDepartName}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Staff"
                        : "कर्मचारी चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={chooseStaff}
                      onChange={handleStaff}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Staff"
                          : "कर्मचारी चयन गर्नुहोस्"}
                      </option>
                      <option value="-1">
                        {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                      </option>
                      {staffList.map((item) => (
                        <option key={item.UserID} value={item.UserID}>
                          {item.FullName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>

                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={bankList}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
              fixedHeader
              fixedHeaderScrollHeight="370px"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder={
                              mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                            }
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </>
      </div>

      <BankPopup
        setBankPopup={setBankPopup}
        bankPopup={bankPopup}
        reload={reload}
        setReload={setReload}
        bnkList={bnkList}
        bankFormValue={bankFormValue}
        setBankFormValue={setBankFormValue}
        bankFormError={bankFormError}
        setBankFormError={setBankFormError}
      />

      <BankPopupEdit
        setBankEditPopup={setBankEditPopup}
        bankEditPopup={bankEditPopup}
        reload={reload}
        setReload={setReload}
        bnkList={bnkList}
        bankFormValue={bankFormValue}
        setBankFormValue={setBankFormValue}
        bankFormError={bankFormError}
        setBankFormError={setBankFormError}
        chooseStaff={chooseStaff}
        titleId={titleId}
      />

      {confirmPopup &&
        selected_note &&
        ConfirmPopup({
          FormData: {
            AuthCode: User.AuthCode,
            ComID: User.ComID,
            UserID: User.UID,
            StaffID: selected_note.UserID,
            Flag: "R",
            BankID: selected_note.BankID,
            // FetchURL: "https://esnep.com/easyoffice/api/leave-status",
            FetchURL: `${appURL}api/admin/bank`,
            Type: "POST",
          },
          reload: reload,
          setReload: setReload,
          setTrigger: setConfirmPopup,
          message:
            mode === "en"
              ? "Are you sure you want to delete ?"
              : " के तपाइँ मेटाउन चाहनुहुन्छ",
        })}
    </>
  );
}
