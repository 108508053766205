import React, { useContext, useEffect, useRef, useState } from "react";
import "../../profile/profile.css";
import DataTable from "react-data-table-component";
// import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ShowImgPreview } from "../../../hooks/imagePreview";
import UpperbarContext from "../../context/upperbar-context";
import StaffContext from "../organization/staffState/StaffContext";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import { GetNepaliDate } from "../../hooks/dateConvertor";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { AiOutlineSearch, AiOutlineEye } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ViewLeaveReport from "./ViewPop";
import { GoPlus } from "react-icons/go";
import LeaveReportPop from "./LeaveReportPop";
import { englishToNepaliNumber } from "nepali-number";
import { NavLink } from "react-router-dom";
import Spinner from "../../loading/spinner";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { generateTablePdf } from "../../hooks/PdfExcel";

export default function LeaveBalance() {
  const {
    appURL,
    fiscalYear,
    expires,
    customStyles,
    todayDate,
    mode,
    userDetails,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [originalList, setOriginalList] = useState("");
  const [loading, setLoading] = useState(true);

  const searchInput = useRef("");

  // API to hit leave list

  const [leaveReport, setLeaveReport] = useState([]);

  useEffect(() => {
    leaveList();
  }, []);
  const leaveList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "LB",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/leave-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLeaveReport(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setLeaveReport([]);
      }
    });
  };

  const groupedByName = leaveReport.reduce((acc, curr) => {
    if (acc[curr.FullName]) {
      acc[curr.FullName].push(curr);
    } else {
      acc[curr.FullName] = [curr];
    }
    return acc;
  }, {});

  const newList = Object.keys(groupedByName).map((name) => ({
    FullName: name,
    data: groupedByName[name],
  }));

  console.log("leaveReport", groupedByName);

  const uniqueType = [...new Set(leaveReport.map((item) => item.LeaveType))];
  console.log("uniqueType", uniqueType);

  const leaveBalData = {};

  // loop through the data and populate the object
  newList.forEach((item) => {
    leaveBalData[item.FullName] = {};

    uniqueType.forEach((date) => {
      const data = item.data.find((d) => d.LeaveType === date);

      if (data) {
        leaveBalData[item.FullName][date] = {
          Balance: data.Balance,
          Used: data.Used,
        };
      } else {
        leaveBalData[item.FullName][date] = null;
      }
    });
  });
  console.log("leaveBalData", leaveBalData);

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveReport(srchResult);
      } else {
        setLeaveReport({});
      }
    } else {
      setLeaveReport(originalList);
    }
  };

  const dataWithIndex = leaveReport.map((d, i) => ({
    ...d,
    Index: i + 1,
    FromDate: d.FromDate,
    ToDate: d.ToDate,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Staff", field: "StaffName" },
    { header: "Title", field: "Title" },
    { header: "Cause", field: "Cause" },
    { header: "Day Type", field: "DayType" },
    { header: "Leave Type", field: "LeaveType" },
    { header: "From Date", field: "FromDate" },
    { header: "To Date", field: "ToDate" },
    { header: "Total Days", field: "TotalDays" },
    { header: "Is Field Work", field: "IsFieldWork" },
    { header: "Assigned To", field: "AssignedTo" },
    { header: "Is Verified", field: "LeaveVerified" },
    { header: "Verified By", field: "VerifiedBy" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generateTablePdf(userDetails);
  };

  const dataToExcel = leaveReport.map((d, i) => ({
    "S.N.": i + 1,
    Staff: d.StaffName,
    Title: d.Title,
    Cause: d.Cause,
    "Day Type": d.DayType,
    "Leave Type": d.LeaveType,
    "From Date": d.FromDate,
    "To Date": d.ToDate,
    "Total Days": d.TotalDays,
    "Is Field Work": d.IsFieldWork,
    "Assigned To": d.AssignedTo,
    "Is Verified": d.LeaveVerified,
    "Verified By": d.VerifiedBy,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Leave report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Leave Balance" : "बिदाको ब्यालेन्स"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable relative">
            <div>
              <div
                className="d-flex justify-content-between"
                style={{
                  marginBlock: "1.25rem",
                  backgroundColor: "#f4f8fb",
                  padding: "0",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <NavLink
                    to="/leave-notes"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Note
                  </NavLink>
                  <NavLink
                    to="/leave-report"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Report
                  </NavLink>
                  <NavLink
                    to="/leave-balance"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Balance
                  </NavLink>
                </div>
              </div>
              <div className="upper-dataTbl">
                <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                  <div></div>

                  <div className="">
                    <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                      <div className="export-btn" uk-tooltip="Export Excel">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="download-table-xls-button me-1 ms-2 border-0"
                          table="table-to-xls"
                          filename="Leave Balance"
                          sheet="tablexls"
                          buttonText={
                            <RiFileExcel2Fill size="1rem" color="#136438" />
                          }
                        />
                      </div>
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Pdf"
                          onClick={toPdf}
                          className="mx-1 border-0"
                        >
                          <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <div>
                {Object.keys(leaveBalData).length !== 0 ? (
                  <table className="uk-table" id="table-to-xls">
                    <tr className="head-top">
                      <td
                        className="rdt_TableHeadRow tableHead text-center"
                        width="70px"
                        style={{ verticalAlign: "middle" }}
                      >
                        {mode === "en" ? "S.N." : "क्र.सं"}
                      </td>
                      <td
                        className="rdt_TableHeadRow tableHead text-start"
                        //   width="200px"
                        style={{ verticalAlign: "middle" }}
                      >
                        {mode === "en" ? "Full Name" : "पुरा नाम"}
                      </td>

                      {uniqueType.map((type) => {
                        return (
                          <td
                            className="rdt_TableHeadRow tableHead text-center"
                            width="200px"
                          >
                            {type}
                          </td>
                        );
                      })}
                      <td
                        className="rdt_TableHeadRow tableHead text-center"
                        width="150px"
                        style={{ verticalAlign: "middle" }}
                      >
                        {mode === "en" ? "Total" : "कुल"}
                      </td>
                    </tr>

                    {Object.keys(leaveBalData).map((fullName, i) => {
                      const totalBalance = Object.values(
                        leaveBalData[fullName]
                      ).reduce((acc, data) => acc + (data ? data.Used : 0), 0);

                      return (
                        <>
                          <tr>
                            <td
                              // rowSpan="2"
                              className="rdt_TableCell tablecell  text-center"
                            >
                              {mode === "en"
                                ? i + 1
                                : englishToNepaliNumber(i + 1)}
                            </td>

                            <td
                              // rowSpan="2"
                              className="rdt_TableCell tablecell text-start"
                            >
                              {fullName}
                            </td>
                            {uniqueType.map((date) => {
                              const data = leaveBalData[fullName][date];

                              if (data) {
                                return (
                                  <React.Fragment key={date}>
                                    <td className="rdt_TableCell tablecell text-center">
                                      {data.Used}
                                    </td>
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <React.Fragment key={date}>
                                    <td className="rdt_TableCell tablecell text-center">
                                      0
                                    </td>
                                  </React.Fragment>
                                );
                              }
                            })}
                            <td
                              // rowSpan="2"
                              className="rdt_TableCell tablecell text-center fw-bold"
                            >
                              {totalBalance}
                            </td>
                          </tr>
                        </>
                      );
                    })}

                    <tr className="reportTotal">
                      <td
                        colSpan="2"
                        className="rdt_TableCell tablecell text-end fw-bold"
                      >
                        {mode === "en" ? "Total" : "कुल"}
                      </td>

                      {uniqueType.map((type, index) => {
                        const totalTypeBalance = Object.keys(
                          leaveBalData
                        ).reduce((acc, fullName) => {
                          return (
                            acc +
                            (leaveBalData[fullName][type]
                              ? leaveBalData[fullName][type].Used
                              : 0)
                          );
                        }, 0);
                        return (
                          <td
                            className="rdt_TableCell tablecell text-center fw-bold"
                            key={index}
                          >
                            {totalTypeBalance}
                          </td>
                        );
                      })}

                      <td className="rdt_TableCell tablecell text-center fw-bold">
                        {Object.keys(leaveBalData).reduce((acc, fullName) => {
                          return (
                            acc +
                            Object.values(leaveBalData[fullName]).reduce(
                              (innerAcc, data) => {
                                return innerAcc + (data ? data.Used : 0);
                              },
                              0
                            )
                          );
                        }, 0)}
                      </td>
                    </tr>
                  </table>
                ) : (
                  <p className="text-center">
                    {mode === "en" ? "No Data" : "डाटा उपलब्ध छैन"}
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
}
