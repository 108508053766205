import React, { useContext, useEffect, useRef, useState } from "react";
import "../../profile/profile.css";
import DataTable from "react-data-table-component";
// import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ShowImgPreview } from "../../../hooks/imagePreview";
import UpperbarContext from "../../context/upperbar-context";
import StaffContext from "../organization/staffState/StaffContext";
import AuthContext from "../../context/auth-context";
import { Fetchdata } from "../../hooks/getData";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";
import ApprovePop from "./ApprovePop";
import RejectPop from "./RejectPop";
import { GetNepaliDate } from "../../hooks/dateConvertor";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { AiOutlineSearch, AiOutlineEye } from "react-icons/ai";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ViewLeaveReport from "./ViewPop";
import { GoPlus } from "react-icons/go";
import LeaveReportPop from "./LeaveReportPop";
import { englishToNepaliNumber } from "nepali-number";
import { NavLink } from "react-router-dom";
import { generatePdf } from "../../hooks/PdfExcel";

export default function LeaveNote() {
  const {
    appURL,
    fiscalYear,
    expires,
    customStyles,
    todayDate,
    mode,
    userDetails,
    dateMode,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const [originalList, setOriginalList] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewPopup, setViewPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");

  const [reload, setReload] = useState(false);
  const [DFlag, setDFlag] = useState("N");
  const [leaveNotePopup, setLeaveNotePopup] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [re, setRe] = useState();

  const initalvalue = {
    leaveType: "",
    dayType: "",
    LeaveTitle: "",
    LeaveDescription: "",
    assignedStaff: "",
    fromDate: "",
    toDate: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);

  const [reportOption, setReportOption] = useState("day");
  const handleReport = (e) => {
    const target = e.target;
    const value = target.value;
    setReportOption(value);
  };

  const [nepaliDate, setNepaliDate] = useState({
    fromDate: "",
  });
  const handleDate = ({ bsDate }) => {
    setNepaliDate({ ...nepaliDate, fromDate: bsDate });
  };
  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNepaliDate({ ...nepaliDate, [name]: value });
  };
  const [year, setYear] = useState("");
  const handleYear = (e) => {
    const target = e.target;
    const value = target.value;
    setYear(value);
  };

  const [month, setMonth] = useState("");
  const handleMonth = (e) => {
    const target = e.target;
    const value = target.value;
    setMonth(value);
  };

  const searchInput = useRef("");

  const [approveGmail, setApproveGmail] = useState(false);
  const [rejectGmail, setRejectGmail] = useState(false);

  //Everything to pop up and approve the  list
  const [approvePopup, setApprovePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [rejectPopup, setRejectPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  // To approve the credit List

  const [Acdata, setACData] = useState();
  const [leaveAccess, setLeaveAccess] = useState();
  const [acceptUserId, setAcceptUserId] = useState();

  const handleApprove = async (data) => {
    setACData(data[0]);
    setLeaveAccess(data[1]);
    setAcceptUserId(data[2]);

    setApprovePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleApproveTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      StaffID: acceptUserId,
      Flag: "ULS",
      LeaveID: Acdata,
      LeaveStatus: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          leaveList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleApproveFalse = () => {
    setApprovePopup({
      show: false,
      data: null,
    });
    setApproveGmail(false);
  };

  //Everything to pop up and approve the leave list ends

  // To reject the leave List

  const [bcdata, setBcData] = useState();
  const [leavedata, setLeaveData] = useState();
  const [userId, setUserId] = useState();

  const handleReject = async (data) => {
    setBcData(data[0]);
    setLeaveData(data[1]);
    setUserId(data[2]);

    setRejectPopup({
      show: true,
      data,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleRejectTrue = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      StaffID: userId,
      Flag: "ULS",
      LeaveID: bcdata,
      LeaveStatus: "R",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Signature: "p0m76" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/leave-report`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          leaveList();
          toast.success(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    });
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  //
  // };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleRejectFalse = () => {
    setRejectPopup({
      show: false,
      data: null,
    });
    setRejectGmail(false);
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },

    {
      name: mode === "en" ? "Full Name" : "पुरा नाम",
      // grow: 0,
      // center: true,
      // minWidth: "200px",
      selector: (row) => row.StaffName,
    },

    {
      name: mode === "en" ? "Title" : "शीर्षक",
      // grow: 0,
      // center: true,
      width: "200px",
      selector: (row) => row.Title,
    },
    // {
    //   name: "Cause",
    //   // center: true,
    //   // grow: 0,
    //   width: "200px",
    //   selector: (row) => row.Cause,
    // },
    // {
    //   name: "Day Type",
    //   // center: true,
    //   // grow: 0,
    //   width: "200px",
    //   selector: (row) => row.Day Type,
    // },
    {
      name: mode === "en" ? "Leave Type" : "बिदाको प्रकार",
      // center: true,
      // grow: 0,
      width: "200px",
      selector: (row) => row.LeaveType,
    },
    {
      name: mode === "en" ? "From Date" : "मिति देखि",
      // center: true,
      // grow: 0,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.FromDate : englishToNepaliNumber(row.FromDate),
    },
    {
      name: mode === "en" ? "To Date" : "मिति सम्म",
      // center: true,
      // grow: 0,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.ToDate : englishToNepaliNumber(row.ToDate),
    },
    {
      name: mode === "en" ? "Days" : "दिन",
      // center: true,
      // grow: 0,
      width: "100px",
      selector: (row) =>
        mode === "en" ? row.TotalDays : englishToNepaliNumber(row.TotalDays),
    },
    // {
    //   name: "Is Field Work",
    //   // center: true,
    //   // grow: 0,
    //   width: "150px",
    //   selector: (row) => row.IsFieldWork,
    // },
    // {
    //   name: "Assigned To",
    //   // center: true,
    //   // grow: 0,
    //   width: "200px",
    //   selector: (row) => row.AssignedTo,
    // },
    // {
    //     name: "Leave Status",
    //     center: true,
    //     // grow: 0,
    //     width: "200px",
    //     selector: (row) => row.LeaveStatus,
    // },
    // {
    //   name: "Is Verified",
    //   // center: true,
    //   // grow: 0,
    //   width: "200px",
    //   selector: (row) => row.LeaveVerified,
    // },
    // {
    //   name: "Verified By",
    //   // center: true,
    //   // grow: 0,
    //   width: "200px",
    //   selector: (row) => row.VerifiedBy,
    // },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "200px",
      selector: (row) => {
        return (
          <>
            {row.LeaveStatus === "Pending" ? (
              <div className="ln-verition d-flex">
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex pe-1"
                  // style={{ background: "var(--button-color)", color: "white" }}
                  onClick={() =>
                    handleApprove([row.LeaveID, row.LeaveStatus, row.StaffID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-primary"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Approve" : "अनुमोदन"}
                  </span>
                </button>
                <button
                  type="button"
                  className="ln-verition btn btn-sm d-flex ps-1"
                  // style={{
                  //   background: "red",
                  //   color: "white",
                  //   marginLeft: "5px",
                  // }}
                  onClick={() =>
                    handleReject([row.LeaveID, row.LeaveStatus, row.StaffID])
                  }
                >
                  <span
                    className=" badge rounded-pill bg-danger"
                    style={{ fontSize: "11px" }}
                  >
                    {mode === "en" ? "Reject" : "अस्वीकार"}
                  </span>
                </button>
              </div>
            ) : row.LeaveStatus === "Approved" ? (
              <span
                className=" badge rounded-pill bg-success"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Approved" : "स्वीकृत"}
              </span>
            ) : row.LeaveStatus === "Rejected" ? (
              <span
                className=" badge rounded-pill bg-danger"
                style={{ fontSize: "11px" }}
              >
                {mode === "en" ? "Rejected" : "अस्वीकार गरियो"}
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => handleView(row)}
                uk-tooltip={mode === "en" ? "Show" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const handleView = (row) => {
    setViewPopup(true);
    setSelectedNote(row);
  };

  // API to hit leave list

  const [leaveReport, setLeaveReport] = useState([]);

  useEffect(() => {
    leaveList();
  }, [reportOption, nepaliDate, month, year]);
  const leaveList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: reportOption === "day" ? "D" : reportOption === "month" ? "M" : "",
      DFlag: "N",
      Values:
        reportOption === "day"
          ? nepaliDate.fromDate
          : reportOption === "month"
          ? `${year}/${month}`
          : "",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/leave-report`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLeaveReport(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setLeaveReport([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();

    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveReport(srchResult);
      } else {
        setLeaveReport({});
      }
    } else {
      setLeaveReport(originalList);
    }
  };

  const dataWithIndex = leaveReport.map((d, i) => ({
    ...d,
    Index: i + 1,
    FromDate: d.FromDate,
    ToDate: d.ToDate,
  }));

  const cols = [
    { header: "S.N", field: "Index" },
    { header: "Staff", field: "StaffName" },
    { header: "Title", field: "Title" },
    // { header: "Cause", field: "Cause" },
    // { header: "Day Type", field: "DayType" },
    { header: "Leave Type", field: "LeaveType" },
    { header: "From Date", field: "FromDate" },
    { header: "To Date", field: "ToDate" },
    { header: "Total Days", field: "TotalDays" },
    // { header: "Is Field Work", field: "IsFieldWork" },
    // { header: "Assigned To", field: "AssignedTo" },
    { header: "Status", field: "LeaveVerified" },
    // { header: "Verified By", field: "VerifiedBy" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  const dataToExcel = leaveReport.map((d, i) => ({
    "S.N.": i + 1,
    Staff: d.StaffName,
    Title: d.Title,
    // Cause: d.Cause,
    // "Day Type": d.DayType,
    "Leave Type": d.LeaveType,
    "From Date": d.FromDate,
    "To Date": d.ToDate,
    "Total Days": d.TotalDays,
    // "Is Field Work": d.IsFieldWork,
    // "Assigned To": d.AssignedTo,
    "Status": d.LeaveVerified,
    // "Verified By": d.VerifiedBy,
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Leave report");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const addLeaveNote = (e) => {
    setLeaveNotePopup(true);
    setFormValues(initalvalue);
    setRe(Math.random());
    setformErrors({});
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Leave Note" : "बिदाको नोट"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable relative">
            <div>
              <div
                className="d-flex justify-content-between"
                style={{
                  marginBlock: "1.25rem",
                  backgroundColor: "#f4f8fb",
                  padding: "0",
                  width: "100%",
                }}
              >
                <div className="d-flex">
                  <NavLink
                    to="/leave-notes"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Note
                  </NavLink>
                  <NavLink
                    to="/leave-report"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Report
                  </NavLink>
                  <NavLink
                    to="/leave-balance"
                    className="nav-link"
                    style={{
                      padding: "0.75rem 1rem",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    activeClassName="active"
                  >
                    Leave Balance
                  </NavLink>
                </div>
              </div>
              <div className="upper-dataTbl">
                <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                  <div className="uk-flex uk-flex-wrap me-5">
                    <div className="me-2 mt-3">
                      <label className="dark-gray d-block text-start">
                        {mode === "en" ? "Report Option" : "रिपोर्ट विकल्प"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="status"
                        className="form-select form-select-sm"
                        aria-label="Default select example "
                        value={reportOption}
                        onChange={handleReport}
                      >
                        <option
                          selected
                          disabled
                          value=""
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Report Option"
                            : "रिपोर्ट विकल्प चयन गर्नुहोस्"}
                        </option>
                        <option value="day">
                          {mode === "en" ? "Day" : "दिन"}
                        </option>
                        <option value="month">
                          {" "}
                          {mode === "en" ? "Month" : "महिना"}
                        </option>
                      </select>
                    </div>

                    {reportOption === "day" && (
                      <div className=" me-2 mt-3">
                        <label
                          style={{
                            textAlign: "left",
                            display: "block",
                          }}
                          className="dark-gray"
                        >
                          {mode === "en" ? "Select Date" : "मिति चयन गर्नुहोस्"}
                        </label>
                        {dateMode === "np" ? (
                          <Calendar
                            className="form-control form-control-sm mb-1"
                            dateFormat="YYYY/MM/DD"
                            theme="default"
                            language="en"
                            values={nepaliDate}
                            onChange={handleDate}
                          />
                        ) : (
                          <input
                            type="date"
                            value={nepaliDate.fromDate}
                            placeholder="Select a Date"
                            className="form-control form-control-sm "
                            name="fromDate"
                            onChange={handleOnChange}
                          />
                        )}
                      </div>
                    )}

                    {reportOption === "month" && (
                      <>
                        <div className=" me-2 mt-3">
                          <label
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                            className="dark-gray"
                          >
                            {mode === "en" ? "Year" : "वर्ष"}
                          </label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-select form-select-sm"
                            aria-label="Default select example "
                            value={year}
                            onChange={handleYear}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              {mode === "en"
                                ? "Select Year"
                                : "वर्ष चयन गर्नुहोस्"}
                            </option>
                            <option value="2079">
                              {mode === "en" ? "2079" : "२०७९"}
                            </option>
                            <option value="2080">
                              {mode === "en" ? "2080" : "२०८०"}
                            </option>
                            <option value="2081">
                              {mode === "en" ? "2081" : "२०८१"}
                            </option>
                          </select>
                        </div>

                        <div className=" me-2 mt-3">
                          <label
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                            className="dark-gray"
                          >
                            {mode === "en" ? "Month" : "महिना"}
                          </label>
                          <select
                            style={{ fontSize: "11px" }}
                            name="status"
                            className="form-select form-select-sm"
                            aria-label="Default select example "
                            value={month}
                            onChange={handleMonth}
                          >
                            <option
                              selected
                              disabled
                              value=""
                              style={{ fontSize: "11px" }}
                            >
                              {mode === "en"
                                ? "Select Month"
                                : "महिना चयन गर्नुहोस्"}
                            </option>
                            <option value="01">
                              {mode === "en" ? "Baishakh" : "बैशाख"}
                            </option>
                            <option value="02">
                              {mode === "en" ? "Jestha" : "जेष्ठ"}
                            </option>
                            <option value="03">
                              {mode === "en" ? "Ashar" : "असार"}
                            </option>
                            <option value="04">
                              {mode === "en" ? "Shrawan" : "श्रावण"}
                            </option>
                            <option value="05">
                              {mode === "en" ? "Bhadra" : "भाद्र"}
                            </option>
                            <option value="06">
                              {mode === "en" ? "Asoj" : "असोज"}
                            </option>
                            <option value="07">
                              {mode === "en" ? "Kartik" : "कार्तिक"}
                            </option>
                            <option value="08">
                              {mode === "en" ? "Mangsir" : "मंसिर"}
                            </option>
                            <option value="09">
                              {mode === "en" ? "Poush" : "पौष"}
                            </option>
                            <option value="10">
                              {mode === "en" ? "Magh" : "माघ"}
                            </option>
                            <option value="11">
                              {mode === "en" ? "Falgun" : "फाल्गुन"}
                            </option>
                            <option value="12">
                              {mode === "en" ? "Chaitra" : "चैत्र"}
                            </option>
                          </select>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="">
                    <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                      <div className="btn-addlnote mt-3">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--button-color)",
                            color: "white",
                          }}
                          onClick={addLeaveNote}
                        >
                          <GoPlus color="#fff" className="me-1" />
                          {mode === "en" ? "Add Leave" : "बिदा थप्नुहोस्"}
                        </button>
                      </div>
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Excel"
                          onClick={toExcel}
                          className="me-1 ms-2 border-0"
                        >
                          <RiFileExcel2Fill size="1rem" color="#136438" />
                        </button>
                      </div>
                      <div className="export-btn">
                        <button
                          uk-tooltip="Export Pdf"
                          onClick={toPdf}
                          className="mx-1 border-0"
                        >
                          <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={leaveReport}
              customStyles={customStyles}
              pagination
              paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
              fixedHeader
              fixedHeaderScrollHeight="410px"
              data-tag="allowRowEvents"
              highlightOnHover
              pointerOnHover
              responsive
              dense
              striped
              subHeader
              subHeaderComponent={
                <>
                  <div className=" w-100">
                    <div className="d-flex uk-flex-middle justify-content-end">
                      <div>
                        <div class="uk-search uk-search-default">
                          <AiOutlineSearch className="search-icon" />
                          <input
                            placeholder={
                              mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                            }
                            ref={searchInput}
                            type="text"
                            className="form-control form-control-sm searchField"
                            onChange={searchHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </>
      </div>
      {approvePopup.show && (
        <ApprovePop
          handleApproveTrue={handleApproveTrue}
          handleApproveFalse={handleApproveFalse}
          approveGmail={approveGmail}
          setApproveGmail={setApproveGmail}
        />
      )}

      {rejectPopup.show && (
        <RejectPop
          handleRejectTrue={handleRejectTrue}
          handleRejectFalse={handleRejectFalse}
          rejectGmail={rejectGmail}
          setRejectGmail={setRejectGmail}
        />
      )}

      {selected_note && (
        <ViewLeaveReport
          note={selected_note}
          viewPopup={viewPopup}
          setViewPopup={setViewPopup}
        />
      )}

      <LeaveReportPop
        setLeaveNotePopup={setLeaveNotePopup}
        leaveNotePopup={leaveNotePopup}
        reload={reload}
        setReload={setReload}
        DFlag={DFlag}
        initalvalue={initalvalue}
        setFormValues={setFormValues}
        formValues={formValues}
        setformErrors={setformErrors}
        formErrors={formErrors}
        re={re}
        leaveList={leaveList}
      />
    </>
  );
}
