import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import DepartmentPopup from "./DepartmentPopup";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import DepartmentPopupEdit from "./DepartmentPopupEdit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../loading/spinner";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineEdit,
  AiOutlineSearch,
  AiTwotonePrinter,
} from "react-icons/ai";
import PrintPopup from "../../../PrintPopup";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NepaliFont from "../../../../images/NepaliFont.otf";
import { englishToNepaliNumber } from "nepali-number";
import { generatePdf } from "../../../hooks/PdfExcel";

export default function Department() {
  const {
    fiscalYear,
    todayDate,
    appURL,
    customStyles,
    mode,
    printPopup,
    setPrintPopup,
    userDetails,
    expires,
  } = useContext(UpperbarContext);
  const [departmentPopup, setDepartmentPopup] = useState(false);
  const [departmentEditPopup, setDepartmentEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");

  const documentValue = {
    title: "",
    head: "-1",
  };
  const [documentFormValue, setDocumentFormValue] = useState(documentValue);
  const [departmentFormError, setDepartmentFormError] = useState({});

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setDepartmentPopup(true);

    setDocumentFormValue(documentValue);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setDepartmentEditPopup(true);
    setTitleID(datas.DepartmentID);
    setDocumentFormValue({
      title: datas.Department,
      head: datas.DepartHeadID,
    });
  };

  //API to hit Department status

  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const deactivateDepart = (ID, IsActive) => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "AI",
      DepartmentID: ID,
      IsActive: IsActive,
      FetchURL: `${appURL}api/admin/department`,
      Type: "POST",
    };
    if (IsActive === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        deptList();
        let statsN = JSON.parse(JSON.stringify(newStat));
        let pitchStatus;

        if (dataForm.IsActive === "A") {
          pitchStatus = "Activated";
        } else if (dataForm.IsActive === "I") {
          pitchStatus = "Deactivated";
        }

        setNewStat(statsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const changeStatus = (ID, IsActive) => {
    deactivateDepart(ID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Department" : "विभाग",
      // center: true,
      // grow: 0,
      minWidth: "200px",
      selector: (row) => row.Department,
    },
    {
      name: mode === "en" ? "Head" : "प्रमुख",
      // center: true,
      width: "200px",
      // grow: 0,
      selector: (row) =>
        row.DepartHead === null ? (
          <span>{mode === "en" ? "No head" : " प्रमुख छैन"}</span>
        ) : (
          row.DepartHead
        ),
    },
    // {
    //   name: mode === "en" ? "Sub Department" : "उप विभाग",
    //   // grow: 0,
    //   // center: true,
    //   width: "150px",
    //   selector: (row) =>
    //     mode === "en"
    //       ? row.NoOfSubDepart
    //       : englishToNepaliNumber(row.NoOfSubDepart),
    // },
    {
      name: mode === "en" ? "Designation" : "पदनाम",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.NoOfDesign : englishToNepaliNumber(row.NoOfDesign),
    },
    {
      name: mode === "en" ? "Staff" : "कर्मचारी",
      // grow: 0,
      // center: true,
      width: "150px",
      selector: (row) =>
        mode === "en" ? row.NoOfStaff : englishToNepaliNumber(row.NoOfStaff),
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      // grow: 0,
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                // class="btn btn-sm actvspan"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.DepartmentID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => editPop(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setLoading(false);
        setDepartmentList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setOriginalList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, []);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: "1",
  //     Flag: "S",
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Department"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDepartmentList(srchResult);
      } else {
        setDepartmentList({});
      }
    } else {
      setDepartmentList(originalList);
    }
  };

  const dataWithIndex = departmentList.map((d, i) => ({
    ...d,
    Index: i + 1,
    Head: d.DepartHead === null ? "No Head" : d.DepartHead,
    Status: d.Status === 1 ? "Inactive" : "Active",
  }));

  const cols = [
    { header: mode === "en" ? "S.N" : "क्र.सं", field: "Index" },
    { header: "Department", field: "Department" },
    { header: "Head", field: "Head" },
    { header: "Sub Department", field: "NoOfSubDepart" },
    { header: "Designation", field: "NoOfDesign" },
    { header: "Staff", field: "NoOfStaff" },
    { header: "Status", field: "Status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const toPdf = () => {
    generatePdf(userDetails, exportColumns, dataWithIndex);
  };

  // ---------------------------
  const dataToExcel = departmentList.map((d, i) => ({
    "S.N.": i + 1,
    Department: d.Department,
    Head: d.DepartHead === null ? "No Head" : d.DepartHead,
    "Sub Department": d.NoOfSubDepart,
    Designation: d.NoOfDesign,
    Staff: d.NoOfStaff,
    Status: d.Status === 1 ? "Inactive" : "Active",
  }));

  const toExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataToExcel);
      var wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Department");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const toPrint = () => {
    setPrintPopup(true);
  };

  const printColumns = [
    {
      name: "S.N.",
    },
    {
      name: "Department",
    },
    {
      name: "Head",
    },
    {
      name: "Sub Department",
    },
    {
      name: "Designation",
    },
    {
      name: "Staff",
    },
    {
      name: "Status",
    },
  ];
  const printData = [
    {
      name: "Index",
    },
    {
      name: "Department",
    },
    {
      name: "Head",
    },
    {
      name: "NoOfSubDepart",
    },
    {
      name: "NoOfDesign",
    },
    {
      name: "NoOfStaff",
    },
    {
      name: "Status",
    },
  ];

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Department" : "विभाग"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addLeaveNote}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                  </button>
                </div>

                <div className="export-btn">
                  <button
                    uk-tooltip="Export Excel"
                    onClick={toExcel}
                    className="me-1 ms-2 border-0"
                  >
                    <RiFileExcel2Fill size="1rem" color="#136438" />
                  </button>
                </div>
                <div className="export-btn">
                  <button
                    uk-tooltip="Export Pdf"
                    onClick={toPdf}
                    className="mx-1 border-0"
                  >
                    <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                  </button>
                </div>
                {/* <div className="export-btn">
                  <button
                    uk-tooltip="Print"
                    onClick={toPrint}
                    className="mx-1 border-0"
                  >
                    <AiTwotonePrinter size="1rem" color="#555" />
                  </button>
                </div> */}
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={departmentList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100 ">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <DepartmentPopup
        setDepartmentPopup={setDepartmentPopup}
        departmentPopup={departmentPopup}
        reload={reload}
        setReload={setReload}
        deptList={deptList}
        documentFormValue={documentFormValue}
        setDocumentFormValue={setDocumentFormValue}
        departmentFormError={departmentFormError}
        setDepartmentFormError={setDepartmentFormError}
      />

      <DepartmentPopupEdit
        setDepartmentEditPopup={setDepartmentEditPopup}
        departmentEditPopup={departmentEditPopup}
        reload={reload}
        setReload={setReload}
        deptList={deptList}
        documentFormValue={documentFormValue}
        setDocumentFormValue={setDocumentFormValue}
        departmentFormError={departmentFormError}
        setDepartmentFormError={setDepartmentFormError}
        titleId={titleId}
      />

      <PrintPopup
        data={dataWithIndex}
        column={printColumns}
        printData={printData}
      />
    </>
  );
}
