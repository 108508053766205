import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../../organization/department/DepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import Plus from "../../../../images/Plus.png";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../../../hooks/getData";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";
import validator from "validator";

export default function DocumentPopup({
  setDocumentPopup,
  reload,
  setReload,
  documentFormValue,
  setDocumentFormValue,
  documentFormError,
  setDocumentFormError,
  isUploaded,
  setIsUploaded,
  typeFile,
  image,
  setTypeFile,
  setImage,
  docList,
  documentPopup,
  files,
  setFile,
}) {
  const { User } = useContext(AuthContext);
  const [reportOption, setReportOption] = useState("A");
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setDocumentPopup(false);
    setDocumentFormError({});
    $(".addDocumentPopBg").fadeOut(500);
    $(".addDocumentPop").slideUp(500);
    setIsSubmit(false);
    setIsUploaded(false);
    setReportOption("A");
  };

  useEffect(() => {
    if (documentPopup) {
      $(".addDocumentPopBg").fadeIn(500);
      $(".addDocumentPop").slideDown(500);
    }
  }, [documentPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    if (name === "department") {
      documentFormValue.subDepartment = "";
      documentFormValue.staff = "";
      documentFormValue.designation = "";
    }
    if (name === "subDepartment") {
      documentFormValue.staff = "";
    }
    if (name === "subDepartment") {
      documentFormValue.staff = "";
    }

    setDocumentFormValue({ ...documentFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const ext = files.split(".").pop();
  const validate = (values) => {
    const errors = {};

    if (reportOption === "D" || reportOption === "Dg") {
      if (!values.department) {
        errors.department = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }
    // if (!values.subDepartment) {
    //   errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (reportOption === "I") {
      if (!values.staff) {
        errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }
    if (reportOption === "Dg") {
      if (!values.designation) {
        errors.designation = mode === "en" ? "Required" : "आवश्यक छ";
      }
    }
    if (!values.fileName) {
      errors.fileName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.radio) {
      errors.radio = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (documentFormValue.radio === "url") {
      if (!values.url) {
        errors.url = mode === "en" ? "Required" : "आवश्यक छ";
      } else if (!validator.isURL(values.url)) {
        errors.url =
          mode === "en" ? "Must be valid URL" : "मान्य युआरएल हुनुपर्छ";
      }
    }

    if (documentFormValue.radio === "pdf") {
      if (files === "") {
        if (!values.pdf) {
          errors.pdf = mode === "en" ? "Required" : "आवश्यक छ";
        }
      }
      if (ext !== "pdf") {
        errors.ext =
          mode === "en" ? "Must be in PDF format" : "PDF ढाँचामा हुनुपर्छ";
      }
    }

    return errors;
  };
  const formSubmit = (e) => {
    e.preventDefault();
    setDocumentFormError(validate(documentFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(documentFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        // StaffID: documentFormValue.staff,
        Flag: "i",
        Title: documentFormValue.fileName,
        FileType: documentFormValue.radio === "url" ? "5" : "2",
        FileMedium: documentFormValue.radio === "url" ? 1 : 2,
        FilePath:
          documentFormValue.radio === "pdf" && typeFile !== null
            ? typeFile.split(",")[1]
            : documentFormValue.url,

        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        Type: "POST",
        FetchURL: `${appURL}api/admin/document`,
      };
      let newDataForm;
      if (reportOption === "A") {
        newDataForm = { ...dataForm };
      } else if (reportOption === "I") {
        newDataForm = {
          ...dataForm,
          StaffID: documentFormValue.staff,
        };
      } else if (reportOption === "D") {
        newDataForm = { ...dataForm, DepartID: documentFormValue.department };
      } else if (reportOption === "Dg") {
        newDataForm = {
          ...dataForm,
          DepartID: documentFormValue.department,
          DesignID: documentFormValue.designation,
        };
      }

      Fetchdata(newDataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setDocumentPopup(false);
          // bnkList();
          setIsUploaded(false);
          docList();
          setReportOption("A");
          $(".addDocumentPopBg").fadeOut(500);
          $(".addDocumentPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [documentFormError]);

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  //API to hit Designation list

  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [documentFormValue.department]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: documentFormValue.department,
      IsActive: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [documentFormValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: documentFormValue.department,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };
  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, [documentFormValue.department]);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const departmentId = documentFormValue.department;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      Designation: departmentId,
      BranchID: BranchID,
      WorkingStatus: "-1",
      IsActive: "-1",
      FiscalID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  function handlePdfChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      setFile(e.target.files[0].name);
      let reader = new FileReader();

      reader.onload = function (e) {
        setTypeFile(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // const handlePdfChange = (e) => {
  //   setTypeFile(e.target.files[0].name);
  //   setIsUploaded(true);
  // };
  const handleOption = (e) => {
    const target = e.target;
    const name = target.name;
    setReportOption(e.target.value);

    if (name === "department") {
      documentFormValue.subDepartment = "";
      documentFormValue.staff = "";
      documentFormValue.designation = "";
    }
    if (name === "subDepartment") {
      documentFormValue.staff = "";
    }
    if (name === "subDepartment") {
      documentFormValue.staff = "";
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addDocumentPopBg">
        <div className="newpopup addDocumentPop">
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle">
              {mode === "en" ? "Document" : "कागजात"}
            </div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <div className="form-group mt-0">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Option"
                            : "विकल्प चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="snotifiaction"
                          value={reportOption}
                          onChange={handleOption}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Option"
                              : "विकल्प चयन गर्नुहोस्"}
                          </option>
                          <option value="A">
                            {mode === "en" ? "All" : "सबै"}
                          </option>

                          <option value="D">
                            {mode === "en" ? "Department" : "विभाग"}
                          </option>
                          <option value="Dg">
                            {mode === "en" ? "Designation" : "पदनाम"}
                          </option>
                          <option value="I">
                            {mode === "en" ? "Individual" : "व्यक्तिगत"}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {reportOption !== "A" && (
                    <div className="row text-start ">
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="form-group">
                          <label
                            htmlFor="department"
                            style={{ fontSize: "12px" }}
                          >
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                            <sup style={{ color: "red" }}>*</sup>
                          </label>
                          <select
                            class="form-select form-select-sm"
                            aria-label="Default select example"
                            name="department"
                            value={documentFormValue.department}
                            onChange={handleChange}
                          >
                            <option disabled value="" selected>
                              {mode === "en"
                                ? "Select Department"
                                : "विभाग चयन गर्नुहोस्"}
                            </option>
                            {departmentList.map((item) => (
                              <option
                                key={item.DepartmentID}
                                value={item.DepartmentID}
                              >
                                {item.Department}
                              </option>
                            ))}
                          </select>
                          {documentFormError.department && (
                            <p className="errormsg">
                              {documentFormError.department}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {reportOption === "I" && (
                    <div className="row text-start ">
                      <div className="form-group">
                        <label htmlFor="staff" style={{ fontSize: "12px" }}>
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="staff"
                          value={documentFormValue.staff}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Staff"
                              : "कर्मचारी चयन गर्नुहोस्"}
                          </option>
                          {staffList.map((item) => (
                             <option key={item.UserID} value={item.UID}>
                             {item.FirstName + " " + item.LastName}
                           </option>
                          ))}
                        </select>
                        {documentFormError.staff && (
                          <p className="errormsg">{documentFormError.staff}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {reportOption === "Dg" && (
                    <div className="row text-start ">
                      <div className="form-group">
                        <label htmlFor="staff" style={{ fontSize: "12px" }}>
                          {mode === "en"
                            ? "Select Designation"
                            : "पदनाम चयन गर्नुहोस्"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="designation"
                          value={documentFormValue.designation}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Designation"
                              : "पदनाम चयन गर्नुहोस्"}
                          </option>
                          {designationList.map((item) => (
                            <option
                              key={item.DesignationID}
                              value={item.DesignationID}
                            >
                              {item.Designation}
                            </option>
                          ))}
                        </select>
                        {documentFormError.designation && (
                          <p className="errormsg">
                            {documentFormError.designation}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="fileName" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "File Name" : "फाइलको नाम"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="fileName"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="fileName"
                        value={documentFormValue.fileName}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "File Name" : "फाइलको नाम"}
                      />
                      {documentFormError.fileName && (
                        <p className="errormsg">{documentFormError.fileName}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <input
                        id="link"
                        style={{ fontSize: "13px" }}
                        type="radio"
                        // className="form-control form-control-sm "
                        name="radio"
                        value="url"
                        onChange={handleChange}
                        checked={documentFormValue.radio === "url"}
                      />
                      <label
                        for="link"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {mode === "en" ? "URL" : "युआरएल"}
                      </label>

                      <input
                        id="pdf"
                        style={{ fontSize: "12px" }}
                        type="radio"
                        name="radio"
                        value="pdf"
                        onChange={handleChange}
                        checked={documentFormValue.radio === "pdf"}
                      />
                      <label
                        for="pdf"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {mode === "en" ? "File" : "फाइल"}
                      </label>

                      {documentFormError.radio && (
                        <p className="errormsg">{documentFormError.radio}</p>
                      )}
                    </div>
                  </div>

                  {documentFormValue.radio === "url" && (
                    <div className="row text-start ">
                      <div className="form-group">
                        <label htmlFor="url" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "URL" : "युआरएल"}
                        </label>
                        <input
                          id="url"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm "
                          name="url"
                          value={documentFormValue.url}
                          onChange={handleChange}
                          placeholder={mode === "en" ? "URL" : "युआरएल"}
                        />
                        {documentFormError.url && (
                          <p className="errormsg">{documentFormError.url}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* {documentFormValue.radio === "image" && (
                    <div className="form-group  ">
                      <div
                        className="form-label"
                        htmlFor="text"
                        style={{ fontSize: "12px" }}
                      >
                        Upload Image
                      </div>

                      <div className="BoxUpload">
                        <div className="image-upload">
                          {!isUploaded ? (
                            <>
                              <label htmlFor="upload-input">
                                <img
                                  src={Plus}
                                  draggable={"false"}
                                  alt="placeholder"
                                  style={{
                                    width: 90,
                                    height: 100,
                                    paddingTop: "10px",
                                  }}
                                />
                              </label>

                              <input
                                id="upload-input"
                                type="file"
                                accept=".png,.docx,.pdf,.jpg,.ppt"
                                onChange={handleImageChange}
                                name="image"
                              />
                            </>
                          ) : (
                            <div className="ImagePreview">
                              <img
                                className="close-icon"
                                src={CloseIcon}
                                alt="CloseIcon"
                                onClick={() => {
                                  setIsUploaded(false);
                                  setImage(null);
                                }}
                              />

                              <img
                                id="uploaded-image"
                                src={image}
                                draggable={false}
                                alt="uploaded-img"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}

                  {documentFormValue.radio === "pdf" && (
                    <div className="row">
                      <div className="form-group">
                        <label htmlFor="pdf" style={{ fontSize: "12px" }}>
                          PDF
                        </label>
                        {!isUploaded ? (
                          <>
                            <div className="pdfFile w-100">
                              <label htmlFor="pdfData">
                                <div
                                  // type="button"
                                  class="btn btn-sm"
                                  style={{
                                    background: "var(--button-color)",
                                    color: "white",
                                  }}
                                >
                                  {mode === "en"
                                    ? "Upload Pdf"
                                    : "PDF अपलोड गर्नुहोस्"}
                                </div>
                              </label>

                              <input
                                id="pdfData"
                                type="file"
                                accept=".pdf"
                                onChange={handlePdfChange}
                                name="pdf"
                                className="d-none"
                                value={documentFormValue.pdf}
                              />
                            </div>
                            {documentFormError.pdf && (
                              <p className="errormsg">
                                {documentFormError.pdf}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="w-100 ">
                              <div className="uk-position-relative">
                                <input
                                  style={{ fontSize: "13px" }}
                                  type="text"
                                  className="form-control form-control-sm "
                                  value={files}
                                  name="ext"
                                  disabled
                                />
                                <img
                                  className="close-icon"
                                  src={CloseIcon}
                                  alt="CloseIcon"
                                  onClick={() => {
                                    setIsUploaded(false);
                                    setTypeFile(null);
                                    setFile("");
                                    documentFormError.ext = "";
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    height: " 15px",
                                  }}
                                />
                              </div>
                              {documentFormError.ext && (
                                <p className="errormsg">
                                  {documentFormError.ext}
                                </p>
                              )}
                            </div>
                          </>
                        )}

                        {/* {documentFormError.pdf && (
                          <p className="errormsg">{documentFormError.pdf}</p>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
