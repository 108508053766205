import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../subdepartment/SubdepartmentPopup.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import "../../../hooks/imagePreview.css";
import "../leaveType/LeavePopup.css";
import Plus from "../../../../images/Plus.png";
import "../../../hooks/imagePreview.css";
import AuthContext from "../../../context/auth-context";
import { Fetchdata } from "../../../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StaffContext from "../staffState/StaffContext";
import UpperbarContext from "../../../context/upperbar-context";
import $ from "jquery";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import NepaliDate from "nepali-date-converter";

export default function Basic({ re, setRe }) {
  const { User } = useContext(AuthContext);
  const { appURL, mode } = useContext(UpperbarContext);

  const {
    staffPopup,
    setStaffPopup,
    staffEditPopup,
    setStaffEditPopup,
    currentStep,
    setStep,
    staffFormValue,
    setStaffFormValue,
    staffFormError,
    setStaffFormError,
    staffValue,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    image,
    setImage,
    isSubmit,
    setIsSubmit,
    notWorking,
    setNotWorking,
    checkedList,
    setCheckedList,
    managerChecked,
    setManagerChecked,
    departmentList,
    designationList,
    subdepartmentList,
    stfList,
    shiftList,
  } = useContext(StaffContext);

  const [data, setData] = useState([]);

  const [DFlag, setDFlag] = useState("N");

  useEffect(() => {
    dataList();
  }, []);

  const dataList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "DISTRICT",
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setData(postResult);
      } else {
        setData([]);
      }
    });
  };

  const closePopUp = (e) => {
    setStaffPopup(false);
    setManagerChecked(false);
    setStaffFormError({});
    setImage("");
    $(".addStaffPop").slideUp(500);
    $(".addStaffPopBg").fadeOut(500);
    setIsSubmit(false);
  };

  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    brnchList();
  }, []);

  const brnchList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/branch`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setBranchList(postResult);
      } else {
        setBranchList([]);
      }
    });
  };

  const getEnglishDate = (date) => {
    let nDate = new NepaliDate(date);

    let eDate = nDate.getAD();

    let enMonth = eDate.month + 1;
    let enM = enMonth < 10 ? `0${enMonth}` : enMonth;

    let enDat = eDate.date < 10 ? `0${eDate.date}` : eDate.date;
    return `${eDate.year}-${enM}-${enDat}`;
  };

  const handleDOB = ({ bsDate }) => {
    // setFromDate(bsDate);
    let name = "dateOfBirth";
    setStaffFormValue({ ...staffFormValue, [name]: bsDate });
  };
  const handleEnroll = ({ bsDate }) => {
    // setFromDate(bsDate);
    let name = "enrollDate";
    setStaffFormValue({ ...staffFormValue, [name]: bsDate });
  };
  // const handleLeaveDate = ({ bsDate }) => {
  //   // setFromDate(bsDate);
  //   let name = "leaveDate";
  //   setStaffFormValue({ ...staffFormValue, [name]: bsDate });
  // };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    if (name === "department") {
      staffFormValue.subDepartment = "";
      staffFormValue.designation = "";
    }
    if (name === "subDepartment") {
      staffFormValue.designation = "";
    }
    setStaffFormValue({ ...staffFormValue, [name]: value });
  };

  // const handleChecked = (e) => {
  //   let indexList = checkedList ? checkedList.indexOf(e.target.value) : -1;
  //   if (indexList === -1) {
  //     checkedList.push(e.target.value);
  //   } else {
  //     setCheckedList(checkedList.filter((list) => list !== e.target.value));
  //   }
  // };

  const handleChecked = (e) => {
    // Destructuring
    const { value, checked } = e.target;


    // Case 1 : The user checks the box
    if (checked) {
      checkedList.push(e.target.value);
    }

    // Case 2  : The user unchecks the box
    else {
      setCheckedList(checkedList.filter((list) => list !== e.target.value));
    }

  };

  const handleCheckList = (e) => {
    let uniqueCheckedList = [...new Set(checkedList)];
    uniqueCheckedList = uniqueCheckedList.filter((item) => item);
    staffFormValue.days = uniqueCheckedList.toString();
  };

  const formNext = (e) => {
    e.preventDefault();
    handleCheckList();
    setStaffFormError(validate(staffFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(staffFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        Flag: "i",
        UserID: User.UID,
        UserCode: staffFormValue.userName,
        DeviceCode: staffFormValue.deviceCode ? staffFormValue.deviceCode : "0",
        // MobileID: "ES01",
        UserName: staffFormValue.userName,
        FirstName: staffFormValue.firstName,
        MiddleName: staffFormValue.middleName,
        LastName: staffFormValue.lastName,
        Password: staffFormValue.password,
        Email: staffFormValue.email,
        Contact: staffFormValue.contact,
        Phone: staffFormValue.phone,
        Address: staffFormValue.address,
        District: staffFormValue.district,
        DateOfBirthEn: getEnglishDate(staffFormValue.dateOfBirth),
        DateOfBirthNp: staffFormValue.dateOfBirth,
        CitizenshipNo: staffFormValue.citizenshipNo,
        PAN: staffFormValue.pan,
        Gender: staffFormValue.gender,
        BloodGroup: staffFormValue.blood,
        Religion: staffFormValue.religion,
        MaritialStatus: staffFormValue.marital,
        Image: image !== null ? image.split(",")[1] : "",
        WorkingStatus: notWorking ? "N" : "Y",
        // LeaveDate: staffFormValue.leaveDate
        //   ? getEnglishDate(staffFormValue.leaveDate)
        //   : "",
        LeaveDate:"",
        EnrollDate: staffFormValue.enrollDate
          ? getEnglishDate(staffFormValue.enrollDate)
          : "",
        JobType: staffFormValue.jobType,
        Shift: staffFormValue.selectShift,
        ShiftType: staffFormValue.shiftType,
        Grade: staffFormValue.grade,
        Department: staffFormValue.department,
        Designation: staffFormValue.designation,
        WorkingDays: staffFormValue.days,
        IsManager: managerChecked ? "Y" : "N",
        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        FetchURL: `${appURL}api/admin/user`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setStaffPopup(false);
          stfList();
          $(".addStaffPopBg").fadeOut(500);
          $(".addStaffPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });
    }
    setIsSubmit(false);
    setImage("");
  }, [staffFormError]);

  const validate = (values) => {
    const errors = {};
    const numv = /^[0-9]+$/i;
    const digits = /^\d{10}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstName) {
      errors.firstName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.lastName) {
      errors.lastName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    // if (!values.userCode) {
    //   errors.userCode = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    // if (!values.deviceCode) {
    //   errors.deviceCode = mode === "en" ? "Required" : "आवश्यक छ";
    // } else if (!numv.test(values.deviceCode)) {
    //   errors.deviceCode =  mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    // }
    // if (!values.mobileCode) {
    //   errors.mobileCode = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (!values.userName) {
      errors.userName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.password) {
      errors.password = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.address) {
      errors.address = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.district) {
      errors.district = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.citizenshipNo) {
      errors.citizenshipNo = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.gender) {
      errors.gender = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.blood) {
      errors.blood = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.religion) {
      errors.religion = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.marital) {
      errors.marital = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (values.email.length === 0) {
      setIsSubmit(true);
    } else if (!regex.test(values.email)) {
      errors.email =
        mode === "en"
          ? "This is not a valid email format"
          : "यो मान्य इमेल ढाँचा होइन";
    }
    if (!values.contact) {
      errors.contact = mode === "en" ? "Required" : "आवश्यक छ";
    } else if (!numv.test(values.contact)) {
      errors.contact = mode === "en" ? "Must be digits" : "अंक हुनुपर्छ";
    } else if (!digits.test(values.contact)) {
      errors.contact = mode === "en" ? "Must be 10 digits" : "10 अंक हुनुपर्छ";
    }
    if (!values.enrollDate) {
      errors.enrollDate = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.jobType) {
      errors.jobType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.selectShift) {
      errors.selectShift = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.grade) {
      errors.grade = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.shiftType) {
      errors.shiftType = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.department) {
      errors.department = mode === "en" ? "Required" : "आवश्यक छ";
    }
    // if (!values.subDepartment) {
    //   errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (!values.designation) {
      errors.designation = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleOnChange = (e) => {
    setManagerChecked(!managerChecked);
    if (e.target.checked === true) {
      setManagerChecked(1);
    } else if (e.target.checked === false) {
      setManagerChecked(0);
    }
  };

  const handleWorkingChange = (e) => {
    setNotWorking(!notWorking);
    setRe(Math.random());
  };

  return (
    <>
      <div className="newpopup-body ps-3 pe-3 ">
        <div className="form-padding">
          <div className="col-md-12 col-sm-12 col-lg-12">
            <div className="row text-start ">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="firstName" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "First Name" : "पहिलो नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="firstName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="firstName"
                    value={staffFormValue.firstName}
                    onChange={handleChange}
                  />
                  {staffFormError.firstName && (
                    <p className="errormsg">{staffFormError.firstName}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="middleName" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Middle Name" : "बीचको नाम"}
                  </label>
                  <input
                    id="middleName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="middleName"
                    value={staffFormValue.middleName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="lastName" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Last Name" : "थर"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="lastName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="lastName"
                    value={staffFormValue.lastName}
                    onChange={handleChange}
                  />
                  {staffFormError.lastName && (
                    <p className="errormsg">{staffFormError.lastName}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start ">
              {/* <div className="col-md-3 col-sm-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="userID" style={{ fontSize: "12px" }}>
                  User ID
                </label>
                <input
                  id="userID"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm"
                  name="userID"
                  value={staffFormValue.userID}
                  onChange={handleChange}
                />
                {staffFormError.userID && (
                  <p className="errormsg">{staffFormError.userID}</p>
                )}
              </div>
            </div> */}

              {/* <div className="col-md-3 col-sm-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="userCode" style={{ fontSize: "12px" }}>
                  User Code<sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="userCode"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm "
                  name="userCode"
                  value={staffFormValue.userCode}
                  onChange={handleChange}
                />
                {staffFormError.userCode && (
                  <p className="errormsg">{staffFormError.userCode}</p>
                )}
              </div>
            </div> */}

              {/* <div className="col-md-3 col-sm-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="deviceCode" style={{ fontSize: "12px" }}>
                  Device Code<sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="deviceCode"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm "
                  name="deviceCode"
                  value={staffFormValue.deviceCode}
                  onChange={handleChange}
                />
                {staffFormError.deviceCode && (
                  <p className="errormsg">{staffFormError.deviceCode}</p>
                )}
              </div>
            </div> */}

              {/* <div className="col-md-3 col-sm-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="mobileCode" style={{ fontSize: "12px" }}>
                  Mobile Code<sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="mobileCode"
                  style={{ fontSize: "13px" }}
                  type="text"
                  className="form-control form-control-sm "
                  name="mobileCode"
                  value={staffFormValue.mobileCode}
                  onChange={handleChange}
                />
                {staffFormError.mobileCode && (
                  <p className="errormsg">{staffFormError.mobileCode}</p>
                )}
              </div>
            </div> */}
            </div>

            <div className="row text-start ">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="deviceCode" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Device Code" : "डिभाइस कोड"}
                  </label>
                  <input
                    id="deviceCode"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="deviceCode"
                    value={staffFormValue.deviceCode}
                    onChange={handleChange}
                  />
                  {/* {staffFormError.deviceCode && (
                    <p className="errormsg">{staffFormError.deviceCode}</p>
                  )} */}
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="userName" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "UserName" : "प्रयोगकर्ता नाम"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="userName"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm"
                    name="userName"
                    value={staffFormValue.userName}
                    onChange={handleChange}
                  />
                  {staffFormError.userName && (
                    <p className="errormsg">{staffFormError.userName}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="password" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Password" : "पासवर्ड"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="password"
                    style={{ fontSize: "13px" }}
                    type="password"
                    className="form-control form-control-sm "
                    name="password"
                    value={staffFormValue.password}
                    onChange={handleChange}
                  />
                  {staffFormError.password && (
                    <p className="errormsg">{staffFormError.password}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start ">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="email" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Email" : "इमेल"}
                  </label>
                  <input
                    id="email"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="email"
                    value={staffFormValue.email}
                    onChange={handleChange}
                  />
                  {staffFormError.email && (
                    <p className="errormsg">{staffFormError.email}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="contact" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Contact" : "सम्पर्क"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="contact"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="contact"
                    value={staffFormValue.contact}
                    onChange={handleChange}
                  />
                  {staffFormError.contact && (
                    <p className="errormsg">{staffFormError.contact}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="phone" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Phone" : "फोन"}
                  </label>
                  <input
                    id="phone"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="phone"
                    value={staffFormValue.phone}
                    onChange={handleChange}
                  />
                  {staffFormError.phone && (
                    <p className="errormsg">{staffFormError.phone}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start ">
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="address" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Address" : "ठेगाना"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="address"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm"
                    name="address"
                    value={staffFormValue.address}
                    onChange={handleChange}
                  />
                  {staffFormError.address && (
                    <p className="errormsg">{staffFormError.address}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="district" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select District" : "जिल्ला चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="district"
                    value={staffFormValue.district}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select District"
                        : "जिल्ला चयन गर्नुहोस्"}
                    </option>
                    {data.map((list) => (
                      <option key={list.DistrictID} value={list.DistrictID}>
                        {list.District}
                      </option>
                    ))}
                    {/* <option value="1">Kathmandu</option> */}
                    {/* {departmentList.map((item) => (<option key={item.DepartmentID} value={item.DepartmentID}>{item.Department}</option>))} */}
                  </select>
                  {staffFormError.district && (
                    <p className="errormsg">{staffFormError.district}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start ">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="dateOfBirth" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Date Of Birth" : "जन्म मिति"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {/* <input
                    id="dateOfBirth"
                    style={{ fontSize: "13px" }}
                    type="date"
                    className="form-control form-control-sm "
                    name="dateOfBirth"
                    value={staffFormValue.dateOfBirth}
                    onChange={handleChange}
                  /> */}
                  {DFlag === "N" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      value={staffFormValue.dateOfBirth}
                      // minDate={getNepaliDate()}
                      // maxDate={getMaxNepaliDate()}
                      onChange={handleDOB}
                      name="dateOfBirth"
                      hideDefaultValue={true}
                      placeholder={
                        mode === "en"
                          ? "Select Date Of Birth"
                          : "जन्म मिति चयन गर्नुहोस्"
                      }
                      key={re}
                    />
                  ) : (
                    <input
                      type="date"
                      value={staffFormValue.dateOfBirth}
                      name="dateOfBirth"
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {staffFormError.dateOfBirth && (
                    <p className="errormsg">{staffFormError.dateOfBirth}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="citizenshipNo" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Citizenship No." : "नागरिकता नं"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    id="citizenshipNo"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="citizenshipNo"
                    value={staffFormValue.citizenshipNo}
                    onChange={handleChange}
                  />
                  {staffFormError.citizenshipNo && (
                    <p className="errormsg">{staffFormError.citizenshipNo}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="pan" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "PAN" : "प्यान"}
                  </label>
                  <input
                    id="pan"
                    style={{ fontSize: "13px" }}
                    type="text"
                    className="form-control form-control-sm "
                    name="pan"
                    value={staffFormValue.pan}
                    onChange={handleChange}
                  />
                  {staffFormError.pan && (
                    <p className="errormsg">{staffFormError.pan}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row text-start ">
              <div className="col-md-3 col-sm-3 col-lg-3">
                <div className="form-group">
                  <label htmlFor="gender" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Gender" : "लिङ्ग"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="gender"
                    value={staffFormValue.gender}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Gender" : "लिङ्ग चयन गर्नुहोस्"}
                    </option>
                    <option value="M">
                      {mode === "en" ? "Male" : "पुरुष"}
                    </option>
                    <option value="F">
                      {mode === "en" ? "Female" : "महिला"}
                    </option>
                    <option value="O">
                      {mode === "en" ? "Other" : "अन्य"}
                    </option>
                  </select>
                  {staffFormError.gender && (
                    <p className="errormsg">{staffFormError.gender}</p>
                  )}
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-lg-3">
                <div className="form-group">
                  <label htmlFor="blood" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Blood Group" : "रक्त समूह"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="blood"
                    value={staffFormValue.blood}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Blood Group"
                        : "रक्त समूह चयन गर्नुहोस्"}
                    </option>
                    <option value="1">
                      {mode === "en" ? "A +ve" : "ए +ve"}
                    </option>
                    <option value="2">
                      {mode === "en" ? "A -ve" : "ए -ve"}
                    </option>
                    <option value="3">
                      {mode === "en" ? "B +ve" : "बि +ve"}
                    </option>
                    <option value="4">
                      {mode === "en" ? "B -ve" : "बि -ve"}
                    </option>
                    <option value="5">
                      {mode === "en" ? "AB +ve" : "एबि +ve"}
                    </option>
                    <option value="6">
                      {mode === "en" ? "AB -ve" : "एबि -ve"}
                    </option>
                    <option value="7">
                      {mode === "en" ? "O +ve" : "ओ +ve"}
                    </option>
                    <option value="8">
                      {mode === "en" ? "O -ve" : "ओ -ve"}
                    </option>
                  </select>
                  {staffFormError.blood && (
                    <p className="errormsg">{staffFormError.blood}</p>
                  )}
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-lg-3">
                <div className="form-group">
                  <label htmlFor="religion" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Religion" : "धर्म"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="religion"
                    value={staffFormValue.religion}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Religion" : "धर्म चयन गर्नुहोस्"}
                    </option>
                    <option value="H">
                      {mode === "en" ? "Hindu" : "हिन्दु"}
                    </option>
                    <option value="B">
                      {mode === "en" ? "Buddhist" : "बौद्ध"}
                    </option>
                    <option value="I">
                      {mode === "en" ? "Islam" : "इस्लाम"}
                    </option>
                    <option value="C">
                      {mode === "en" ? "Christian" : "क्रिस्चियन"}
                    </option>

                    <option value="K">
                      {mode === "en" ? "Kirat" : "किरात"}
                    </option>
                  </select>
                  {staffFormError.religion && (
                    <p className="errormsg">{staffFormError.religion}</p>
                  )}
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-lg-3">
                <div className="form-group">
                  <label htmlFor="marital" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Marital Status" : "वैवाहिक स्थिति"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="marital"
                    value={staffFormValue.marital}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Marital Status"
                        : "वैवाहिक स्थिति चयन गर्नुहोस्"}
                    </option>
                    <option value="S">
                      {mode === "en" ? "Single" : "अविवाहित"}
                    </option>
                    <option value="M">
                      {mode === "en" ? "Married" : "विवाहित"}
                    </option>
                    <option value="W">
                      {mode === "en" ? "Widowed" : "विधवा"}
                    </option>
                    <option value="D">
                      {mode === "en" ? "Divorced" : "सम्बन्धविच्छेद"}
                    </option>
                  </select>
                  {staffFormError.marital && (
                    <p className="errormsg">{staffFormError.marital}</p>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="row text-start ">
              <div className="col-md-4 offset-4 col-sm-4 col-lg-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck3"
                  name="notWorking"
                  onChange={handleWorkingChange}
                  checked={notWorking}
                  style={{ marginTop: "10px" }}
                />
                <label
                  class="form-check-label ms-1"
                  for="exampleCheck3"
                  style={{ fontSize: "14px", marginTop: "8px" }}
                >
                  {mode === "en" ? "Not Working" : "काम गरिरहेको छैन"}
                </label>
              </div>
            </div>  */}

            <div className="row text-start ">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="enrollDate" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Enroll Date" : "भर्ना मिति"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  {/* <input
                    id="enrollDate"
                    style={{ fontSize: "13px" }}
                    type="date"
                    className="form-control form-control-sm "
                    name="enrollDate"
                    value={staffFormValue.enrollDate}
                    onChange={handleChange}
                  /> */}
                  {DFlag === "N" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // minDate={getNepaliDate()}
                      // maxDate={getMaxNepaliDate()}
                      onChange={handleEnroll}
                      name="enrollDate"
                      value={staffFormValue.enrollDate}
                      hideDefaultValue={true}
                      placeholder={
                        mode === "en"
                          ? "Select Enroll Date"
                          : "भर्ना मिति चयन गर्नुहोस्"
                      }
                      key={re}
                    />
                  ) : (
                    <input
                      type="date"
                      name="enrollDate"
                      value={staffFormValue.enrollDate}
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                    />
                  )}
                  {staffFormError.enrollDate && (
                    <p className="errormsg">{staffFormError.enrollDate}</p>
                  )}
                </div>
              </div>

              {/* <div className="col-md-4 col-sm-4 col-lg-4">
                <div className={`form-group ${!notWorking ? "disDate" : ""}`}>
                  <label htmlFor="leaveDate" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Leave Date" : "छोडेको मिति"}
                  </label>
                  {DFlag === "N" ? (
                    <Calendar
                      className="form-control form-control-sm pt-0 pb-0 from-date"
                      dateFormat="YYYY/MM/DD"
                      // defaultDate="2010/01/01"
                      theme="default"
                      language="en"
                      // minDate={getNepaliDate()}
                      // maxDate={getMaxNepaliDate()}
                      onChange={handleLeaveDate}
                      name="leaveDate"
                      hideDefaultValue={true}
                      placeholder={
                        mode === "en"
                          ? "Select Leave Date"
                          : "छोडेको मिति चयन गर्नुहोस्"
                      }
                      key={re}
                    />
                  ) : (
                    <input
                      type="date"
                      value={staffFormValue.leaveDate}
                      placeholder="Select a Date"
                      className="form-control form-control-sm "
                      onChange={handleChange}
                      disabled={!notWorking}
                    />
                  )}
                  {staffFormError.leaveDate && (
                    <p className="errormsg">{staffFormError.leaveDate}</p>
                  )}
                </div>
              </div> */}

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="jobType" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Select Job Type"
                      : "कामको प्रकार चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="jobType"
                    value={staffFormValue.jobType}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Job Type"
                        : "कामको प्रकार चयन गर्नुहोस्"}
                    </option>
                    <option value="1">
                      {mode === "en" ? "Full Time" : "पुरै समय"}
                    </option>
                    <option value="2">
                      {mode === "en" ? "Part Time" : "आंशिक समय"}
                    </option>
                    <option value="3">
                      {mode === "en" ? "Intern" : "इन्टर्न"}
                    </option>
                    <option value="4">
                      {mode === "en" ? "Paid Intern" : "भुक्तान गरिएको इन्टर्न"}
                    </option>
                    <option value="5">
                      {mode === "en" ? "Freelance" : "स्वतन्त्र"}
                    </option>
                    <option value="6">
                      {mode === "en" ? "Contract" : "सम्झौता"}
                    </option>
                    <option value="7">
                      {mode === "en" ? "Training" : "प्रशिक्षण"}
                    </option>
                  </select>
                  {staffFormError.jobType && (
                    <p className="errormsg">{staffFormError.jobType}</p>
                  )}
                </div>
              </div>
           
           
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="selectShift" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Shift" : "शिफ्ट चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="selectShift"
                    value={staffFormValue.selectShift}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Shift" : "शिफ्ट चयन गर्नुहोस्"}
                    </option>
                    {shiftList.map((item) => (
                      <option key={item.ShiftID} value={item.ShiftID}>
                        {item.Shift}
                      </option>
                    ))}
                  </select>
                  {staffFormError.selectShift && (
                    <p className="errormsg">{staffFormError.selectShift}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="shiftType" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Select Shift Type"
                      : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="shiftType"
                    value={staffFormValue.shiftType}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Shift Type"
                        : "शिफ्ट प्रकार चयन गर्नुहोस्"}
                    </option>
                    <option value="1">
                      {" "}
                      {mode === "en" ? "Weekly" : "साप्ताहिक"}
                    </option>
                    <option value="2">
                      {" "}
                      {mode === "en" ? "Monthly" : "मासिक"}
                    </option>
                    <option value="3">
                      {" "}
                      {mode === "en" ? "Yearly" : "वार्षिक"}
                    </option>
                  </select>
                  {staffFormError.shiftType && (
                    <p className="errormsg">{staffFormError.shiftType}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="grade" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Grade" : "ग्रेड चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="grade"
                    value={staffFormValue.grade}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Grade" : "ग्रेड चयन गर्नुहोस्"}
                    </option>
                    <option value="0"> {mode === "en" ? "A" : "ए"}</option>
                  </select>
                  {staffFormError.grade && (
                    <p className="errormsg">{staffFormError.grade}</p>
                  )}
                </div>
              </div>
            

          
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="department" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Select Department"
                      : "विभाग चयन गर्नुहोस्"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="department"
                    value={staffFormValue.department}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Department"
                        : "विभाग चयन गर्नुहोस्"}
                    </option>

                    {departmentList.map((item) => (
                      <option key={item.DepartmentID} value={item.DepartmentID}>
                        {item.Department}
                      </option>
                    ))}
                  </select>
                  {staffFormError.department && (
                    <p className="errormsg">{staffFormError.department}</p>
                  )}
                </div>
              </div>

              {/* <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="subDepartment" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Select Sub-Department"
                      : "उप विभाग चयन गर्नुहोस्"}{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="subDepartment"
                    value={staffFormValue.subDepartment}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Sub-Department"
                        : "उप विभाग चयन गर्नुहोस्"}
                    </option>

                    {subdepartmentList.map((item) => (
                      <option key={item.SubDepartID} value={item.SubDepartID}>
                        {item.SubDepartName}
                      </option>
                    ))}
                  </select>
                  {staffFormError.subDepartment && (
                    <p className="errormsg">{staffFormError.subDepartment}</p>
                  )}
                </div>
              </div> */}

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="designation" style={{ fontSize: "12px" }}>
                    {mode === "en"
                      ? "Select Designation"
                      : "पदनाम चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="designation"
                    value={staffFormValue.designation}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en"
                        ? "Select Designation"
                        : "पदनाम चयन गर्नुहोस्"}
                    </option>

                    {designationList.map((item) => (
                      <option
                        key={item.DesignationID}
                        value={item.DesignationID}
                      >
                        {item.Designation}
                      </option>
                    ))}
                  </select>
                  {staffFormError.designation && (
                    <p className="errormsg">{staffFormError.designation}</p>
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="form-group">
                  <label htmlFor="branch" style={{ fontSize: "12px" }}>
                    {mode === "en" ? "Select Branch" : "साखा चयन गर्नुहोस्"}
                    <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Default select example"
                    name="branch"
                    value={staffFormValue.branch}
                    onChange={handleChange}
                  >
                    <option disabled value="" selected>
                      {mode === "en" ? "Select Branch" : "साखा चयन गर्नुहोस्"}
                    </option>

                    {branchList.map((item) => (
                      <option key={item.BranchID} value={item.BranchID}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                  {staffFormError.branch && (
                    <p className="errormsg">{staffFormError.branch}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group  ">
              <div
                className="form-label"
                htmlFor="text"
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                {mode === "en" ? "Upload Image" : "फोटो अपलोड गर्नुहोस्"}
              </div>

              <div className="BoxUpload">
                <div className="image-upload">
                  {!isUploaded ? (
                    <>
                      <label htmlFor="upload-input">
                        <img
                          src={Plus}
                          draggable={"false"}
                          alt="placeholder"
                          style={{ width: 90, height: 100, paddingTop: "10px" }}
                        />
                      </label>

                      <input
                        id="upload-input"
                        type="file"
                        accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                        onChange={handleImageChange}
                        name="image"
                      />
                    </>
                  ) : (
                    <div className="ImagePreview">
                      <img
                        className="close-icon"
                        src={CloseIcon}
                        alt="CloseIcon"
                        onClick={() => {
                          setIsUploaded(false);
                          setImage(null);
                        }}
                      />

                      <img
                        id="uploaded-image"
                        src={image}
                        draggable={false}
                        alt="uploaded-img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div style={{ textAlign: "left" }}>
                <label className="form-label" htmlFor="purpose">
                  {mode === "en" ? " Working Days" : "काम गर्ने दिनहरू"}
                  <sup style={{ color: "red" }}>*</sup>
                </label>
              </div>

              <div className="do-flex">
                <div class="feature">
                  <input
                    type="checkbox"
                    id="SUN"
                    value="SUN"
                    name="sun"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Sun" : "आइतबार"}
                    </span>
                  </div>
                </div>

                <div class="feature">
                  <input
                    type="checkbox"
                    id="MON"
                    value="MON"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Mon" : "सोमबार"}
                    </span>
                  </div>
                </div>

                <div class="feature">
                  <input
                    type="checkbox"
                    id="TUES"
                    value="TUES"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Tue" : "मंगलबार"}
                    </span>
                  </div>
                </div>

                <div class="feature">
                  <input
                    type="checkbox"
                    id="WED"
                    value="WED"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Wed" : "बुधबार"}
                    </span>
                  </div>
                </div>

                <div class="feature">
                  <input
                    type="checkbox"
                    id="THURS"
                    value="THURS"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Thurs" : "बिहीबार"}
                    </span>
                  </div>
                </div>

                <div class="feature">
                  <input
                    type="checkbox"
                    id="FRI"
                    value="FRI"
                    onChange={handleChecked}
                  />
                  <div>
                    <span style={{ fontSize: "13px" }}>
                      {mode === "en" ? "Fri" : "शुक्रबार"}
                    </span>
                  </div>
                </div>
              </div>

              <p className="errormsg ">{staffFormError.days}</p>
            </div>

            <div className="checkbox-close">
              {/* <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck2"
                name="closeChecked"
                onChange={handleOnChange}
                checked={managerChecked}
              />
              <label
                class="form-check-label ms-1"
                for="exampleCheck2"
                style={{ fontSize: "14px" }}
              >
                Manager
              </label> */}

              {/* <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck3"
                            name="notWorking"
                            onChange={handleWorkingChange}
                            checked={notWorking}
                            style={{ marginLeft: "10px" }}
                        />
                        <label class="form-check-label" for="exampleCheck3" style={{ fontSize: "14px" }}>
                            Not Working
                        </label> */}
            </div>
          </div>
        </div>
      </div>

      <div className="new-footer">
        <div className="row  mt-1 mb-1">
          <div>
            <button
              type="button"
              class="btn btn-sm me-2"
              style={{ background: "var(--button-color)", color: "white" }}
              onClick={formNext}
            >
              {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger me-3"
              style={{ color: "white" }}
              onClick={closePopUp}
            >
              {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
