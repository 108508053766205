import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { ToastContainer, toast } from "react-toastify";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import AllowancePopup from "./AllowancePopup";
import $ from "jquery";
import AllowanceContext from "../payrollState/AllowanceContext";
import EditAllowancePopup from "./EditAllowancePopup";
import Spinner from "../../../loading/spinner";
import { Fetchdata } from "../../../hooks/getData";
import { englishToNepaliNumber } from "nepali-number";
import AccountGroupContext from "../../setup/account group/accountGroupState/AccountGroupContext";

export default function Allowance() {
  const { User } = useContext(AuthContext);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);
  const { nonAcGrpList } = useContext(AccountGroupContext);
  // const [taxableCheck, setTaxableCheck] = useState("");

  const {
    loading,
    originalList,
    popup,
    setPopup,
    submit,
    setSubmit,
    perEditSubmit,
    setPerEditSubmit,
    initalvalue,
    formValues,
    setFormValues,
    formErrors,
    setFormErrors,
    allowanceList,
    setAllowanceList,
    editPopup,
    setEditPopup,
    handleEdit,
    editData,
    deactivateData,
    allowanceLst,
    checkedTaxable,
    setCheckedTaxable,
    addNew,
    setAddNew,
  } = useContext(AllowanceContext);

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Addition" : "थप",
      selector: (row) => row.Head,
    },
    {
      name: mode === "en" ? "Under" : "अन्तर्गत",
      selector: (row) => row.Ledger,
    },
    {
      name: mode === "en" ? "Absent" : "अनुपस्थित",
      width: "130px",
      center: true,
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              checked={row.IsAbsent === "Y" ? true : false}
              style={{ fontSize: "12px", cursor: "pointer" }}
              onChange={(e) => {
                handleChangeAbsent(e, row.HeadID);
              }}
            />
          </>
        );
      },
    },
    // {
    //   name: mode === "en" ? "Tax" : "कर",
    //   selector: (row) => (row.IsTax === "Y" ? "Taxable" : "Non-taxable"),
    // },

    {
      name: mode === "en" ? "Taxable" : "कर योग्य",
      center: true,
      // grow: 0,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              checked={row.IsTax === "Y" ? true : false}
              style={{ fontSize: "12px", cursor: "pointer" }}
              onChange={(e) => {
                handleChangeTax(e, row.HeadID);
              }}
            />
          </>
        );
      },
    },

    {
      name: mode === "en" ? "Provident Fund" : "सञ्चय कोष",
      center: true,
      // grow: 0,
      width: "150px",
      selector: (row) => {
        return (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              checked={row.IsProvident === "Y" ? true : false}
              // disabled={row.IsTax === "Y"}
              style={{ fontSize: "12px", cursor: "pointer" }}
              onChange={() => {
                handleChangeProvident(row.HeadID);
              }}
            />
          </>
        );
      },
    },

    {
      name: mode === "en" ? "Status" : "स्थिति",
      center: true,
      width: "140px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={() => changeStatus(row.HeadID, row.IsActive)}
              >
                <span
                  className=" badge rounded-pill bg-success"
                  style={{ fontSize: "11px" }}
                >
                  {checkStatus(row.IsActive)}
                </span>
              </button>
            </div>
          </>
        );
      },
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "100px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan"
                onClick={() => handleEdit(row)}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return mode === "en" ? "Deactivate" : "निष्क्रिय";
    } else if (IsActive === "I") {
      return mode === "en" ? "Activate" : "सक्रिय";
    }
  };

  const changeStatus = (ID, IsActive) => {
    deactivateData(ID, IsActive);
  };

  const handleChangeTax = (e, ID) => {
    const check = e.target.checked;
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "CT",
      HeadID: id,
      DFlag: "A",
      IsTaxable: check === true ? "Y" : "N",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        allowanceLst();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };
  const handleChangeAbsent = (e, ID) => {
    const check = e.target.checked;
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "CA",
      HeadID: id,
      DFlag: "A",
      IsAbsent: check === true ? "Y" : "N",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        allowanceLst();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const handleChangeProvident = (ID) => {
    const UserID = "" + User.UID;
    const id = "" + ID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "CP",
      HeadID: id,
      DFlag: "A",
      IsProvident: "Y",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-head`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        allowanceLst();
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  const handleAdd = () => {
    setPopup(true);
    setFormValues(initalvalue);
    setCheckedTaxable(false);
    setAddNew(false);
    $(".side__panel").addClass("low");
    $(".nav").addClass("high");
  };

  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Head"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAllowanceList(srchResult);
      } else {
        setAllowanceList({});
      }
    } else {
      setAllowanceList(originalList);
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="">
        <div className="upper-dataTbl pb-3">
          <div className="">
            <div className="d-flex uk-flex-middle uk-flex-right">
              <div className="btn-addlnote mt-3">
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--button-color)",
                    color: "white",
                  }}
                  onClick={handleAdd}
                >
                  <GoPlus color="#fff" className="me-1" />

                  {mode === "en" ? "Add Addition" : "थप थप्नुहोस्"}
                </button>
              </div>
              <div className="export-btn">
                <button
                  uk-tooltip="Export Excel"
                  // onClick={toExcel}
                  className="me-1 ms-2 border-0"
                >
                  <RiFileExcel2Fill size="1rem" color="#136438" />
                </button>
              </div>
              <div className="export-btn">
                <button
                  uk-tooltip="Export Pdf"
                  // onClick={toPdf}
                  className="mx-1 border-0"
                >
                  <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                </button>
              </div>
              <div className="export-btn">
                <button
                  uk-tooltip="Print"
                  // onClick={toPrint}
                  className="mx-1 border-0"
                >
                  <AiTwotonePrinter size="1rem" color="#555" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <DataTable
            columns={columns}
            data={allowanceList}
            customStyles={customStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="350px"
            highlightOnHover
            pointerOnHover
            responsive
            paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
            // progressPending={loading}
            dense
            striped
            subHeader
            subHeaderComponent={
              <>
                <div className=" w-100 mb-3 mt-3">
                  <div className="d-flex uk-flex-middle justify-content-end">
                    <div>
                      <form class="uk-search uk-search-default">
                        <AiOutlineSearch className="search-icon" />
                        <input
                          placeholder={
                            mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                          }
                          ref={searchInput}
                          type="text"
                          className="form-control form-control-sm searchField"
                          onChange={searchHandler}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        )}
      </div>

      <AllowancePopup
        popup={popup}
        setPopup={setPopup}
        submit={submit}
        setSubmit={setSubmit}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        allowanceLst={allowanceLst}
        checkedTaxable={checkedTaxable}
        setCheckedTaxable={setCheckedTaxable}
        addNew={addNew}
        setAddNew={setAddNew}
        nonAcGrpList={nonAcGrpList}
      />

      <EditAllowancePopup
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        editPopup={editPopup}
        setEditPopup={setEditPopup}
        perEditSubmit={perEditSubmit}
        setPerEditSubmit={setPerEditSubmit}
        editData={editData}
        checkedTaxable={checkedTaxable}
        setCheckedTaxable={setCheckedTaxable}
        addNew={addNew}
        setAddNew={setAddNew}
        nonAcGrpList={nonAcGrpList}
      />
    </>
  );
}
