import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../loading/spinner";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineEdit,
  AiOutlineSearch,
  AiTwotonePrinter,
  AiOutlineEye,
} from "react-icons/ai";
import "jspdf-autotable";
import { englishToNepaliNumber } from "nepali-number";
import TodoContext from "../todoState/TodoContext";
import UpperbarContext from "../context/upperbar-context";
import { MdOutlineDelete } from "react-icons/md";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { GetNepaliDate } from "../hooks/dateConvertor";
import CategoryPop from "./CategoryPop";
import HelpDeskPop from "./HelpDeskPop";
import HelpdeskContext from "../helpdeskState/HelpdeskContext";
import ViewPop from "./ViewPop";

export default function Helpdesk() {
  const { User } = useContext(AuthContext);
  const {
    fiscalYear,
    todayDate,
    appURL,
    customStyles,
    mode,
    userDetails,
    expires,
  } = useContext(UpperbarContext);

  const {
    formValues,
    setFormValues,
    initialValue,
    formErrors,
    setFormErrors,
    submit,
    setSubmit,
    formList,
    setFormList,
    loading,
    setLoading,
    formLst,
    originalList,
    setOriginalList,
    handleView,
    viewPop,
    setViewPop,
    viewList,
    setViewList,
    listValues,
    setListValues,
  } = useContext(HelpdeskContext);
  const [popUp, setPopUp] = useState(false);
  const [categoryPopUp, setCategoryPopUp] = useState(false);
  const [DFlag, setDFlag] = useState("N");

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Ticket No" : "शीर्षक",
      // center: true,
      // grow: 0,
      width: "110px",
      selector: (row) => row.TicketNo,
    },

    {
      name: mode === "en" ? "Subject" : "विषय",
      minWidth: "300px",
      selector: (row) => row.Subject,
    },
    {
      name: mode === "en" ? "Priority" : "प्राथमिकता",
      width: "110px",
      selector: (row) =>
        row.Priority === "M" ? (
          <> {mode === "en" ? "Medium" : "मध्यम"}</>
        ) : row.Priority === "H" ? (
          <>{mode === "en" ? "High" : "उच्च"}</>
        ) : row.Priority === "L" ? (
          <>{mode === "en" ? "Low" : "कम"}</>
        ) : (
          <> {mode === "en" ? "Urgent" : "अत्यावश्यक"}</>
        ),
    },
    {
      name: mode === "en" ? "Status" : "स्थिति",
      width: "110px",
      selector: (row) =>
        row.Status === "P" ? (
          <> {mode === "en" ? "Pending" : "विचाराधीन"}</>
        ) : row.Status === "A" ? (
          <> {mode === "en" ? "Approved" : "स्वीकृत"}</>
        ) : row.Status === "R" ? (
          <> {mode === "en" ? "Rejected" : "अस्वीकार"}</>
        ) : (
          row.Status
        ),
    },
    {
      name: mode === "en" ? "Due Date" : "अन्तिम मिति",
      width: "120px",
      selector: (row) => row.DueDate,
    },
    {
      name: mode === "en" ? "Created Date" : "सिर्जना गरिएको मिति",
      width: "180px",
      selector: (row) => row.CreatedDate,
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "90px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm downloadspan mx-1"
                onClick={() => handleView(row)}
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const addTicket = (e) => {
    setPopUp(true);
    setFormValues(initialValue);
    setFormErrors({});
  };
  const addCategory = (e) => {
    setCategoryPopUp(true);
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    setListValues({ ...listValues, [name]: value });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Help Desk" : "सहायता केन्द्र"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm me-2"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addCategory}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en"
                      ? "Manage Category"
                      : "श्रेणी व्यवस्थापन गर्नुहोस्"}
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addTicket}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    {mode === "en" ? "Issue Ticket" : "टिकट जारी गर्नुहोस्"}
                  </button>
                </div>
              </div>

              <div className="d-flex uk-flex-wrap uk-flex-middle mb-3">
                <div>
                  <input
                    type="radio"
                    className="radio--button"
                    name="name"
                    id="openTicket"
                    onChange={handleChange}
                    value="openTicket"
                    checked={listValues.name === "openTicket"}
                  />
                  <label
                    className="label-radio-routine px-3"
                    htmlFor="openTicket"
                    style={{ marginRight: "10px" }}
                  >
                    {mode === "en" ? "Open Ticket" : "खुला टिकट"}
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    className="radio--button"
                    name="name"
                    id="assignedToMe"
                    onChange={handleChange}
                    value="assignedToMe"
                    checked={listValues.name === "assignedToMe"}
                  />
                  <label
                    className="label-radio-routine px-3"
                    htmlFor="assignedToMe"
                    style={{ marginRight: "10px" }}
                  >
                    {mode === "en" ? "Assigned To Me" : "मलाई तोकियो"}
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    className="radio--button"
                    name="name"
                    id="assignedToOthers"
                    onChange={handleChange}
                    value="assignedToOthers"
                    checked={listValues.name === "assignedToOthers"}
                  />
                  <label
                    className="label-radio-routine px-3"
                    htmlFor="assignedToOthers"
                    style={{ marginRight: "10px" }}
                  >
                    {mode === "en" ? "Assigned To Others" : "अरूलाई तोकिएको"}
                  </label>
                </div>

                <div>
                  <input
                    type="radio"
                    className="radio--button"
                    name="name"
                    id="unassigned"
                    onChange={handleChange}
                    value="unassigned"
                    checked={listValues.name === "unassigned"}
                  />
                  <label
                    className="label-radio-routine px-3"
                    htmlFor="unassigned"
                    style={{ marginRight: "10px" }}
                  >
                    {mode === "en" ? "Unassigned" : "तोकिएको छैन"}
                  </label>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={formList}
                customStyles={customStyles}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
              />
            )}
          </div>
        </>
      </div>

      <HelpDeskPop setPopUp={setPopUp} popUp={popUp} DFlag={DFlag} />

      <ViewPop viewList={viewList} viewPop={viewPop} setViewPop={setViewPop} />

      <CategoryPop
        setCategoryPopUp={setCategoryPopUp}
        categoryPopUp={categoryPopUp}
      />
    </>
  );
}
