import React, { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../loading/spinner";
import DataTable from "react-data-table-component";
import "./leaveNoteList.css";
import LeaveNotePopup from "./leaveNotePopup";
import { Fetchdata } from "../hooks/getData";
import AuthContext from "../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NepaliDate from "nepali-date-converter";
import ConfirmPopup from "../hooks/confirmPopup";
import UpperbarContext from "../context/upperbar-context";
import { GetNepaliDate } from "../hooks/dateConvertor";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import ViewLeaveNote from "./ViewLeaveNote";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../Toast";
import { GoPlus } from "react-icons/go";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";

export default function LeaveNoteList() {
  const [loading, setLoading] = useState(true);
  const [leavelist, setLeaveList] = useState([]);
  const [originalList, setOriginalList] = useState(null);
  const [leaveNotePopup, setLeaveNotePopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [DFlag, setDFlag] = useState("N");
  const searchInput = useRef("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");
  const { User } = useContext(AuthContext);
  const { appURL, fiscalYear, customStyles, todayDate, darkText, mode } =
    useContext(UpperbarContext);
  const [formErrors, setformErrors] = useState({});
  const [re, setRe] = useState();

  const initalvalue = {
    leaveType: "",
    dayType: "",
    LeaveTitle: "",
    LeaveDescription: "",
    assignedStaff: "",
    fromDate: "",
    toDate: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);

  const columns = [
    {
      name: "S.N.",
      // grow: 0,
      width: "70px",
      center: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "Leave Type",
      // grow: 0,
      minWidth: "190px",
      center: true,
      selector: (row) => row.LeaveType,
    },
    // { name: "Date", grow: 0, center: true, selector: (row) => row.LeaveTitle },
    { name: "Title", grow: 1, center: true, selector: (row) => row.Title },
    {
      name: "Cause",
      // grow: 2,
      minWidth: "190px",
      selector: (row) => row.Cause,
    },
    {
      name: "From",
      grow: 0,
      center: true,
      width: "150px",
      selector: (row) => {
        return DFlag === "N" ? GetNepaliDate(row.FromDate) : row.FromDate;
      },
    },
    {
      name: "To",
      grow: 0,
      center: true,
      width: "150px",
      selector: (row) => {
        return DFlag === "N" ? GetNepaliDate(row.ToDate) : row.ToDate;
      },
    },
    {
      name: "Days",
      grow: 0,
      center: true,
      width: "70px",
      selector: (row) => row.TotalDays,
    },

    {
      name: "Status",
      center: true,
      minWidth: "90px",
      cell: (row) => row.LeaveStatus,
      // <>
      //   <label
      //     class="leave-status"
      //     style={{ background: "#0079bf", color: "white" }}
      //   >
      //     {row.LeaveStatus}
      //   </label>
      // </>
    },
    {
      name: "Verified By",
      center: true,
      minWidth: "150px",
      selector: (row) => {
        return row.LeaveVerified === "verified"
          ? row.VerifiedBy
          : "Not verified";
      },
    },
    {
      name: "Action",
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => handleView(row)}
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </button>{" "}
              {/* <span className="pt-1">|</span> */}
              {row.LeaveVerified === "unverified" && (
                <button
                  type="button"
                  class="btn btn-sm deletespan mx-1"
                  onClick={() => updateRequest(row)}
                  uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
                >
                  <MdOutlineDelete />
                </button>
              )}
            </div>
          </>
        );
      },
    },
  ];

  // const getNepaliDate = (date) => {
  //   const rDate = Date.parse(date);
  //   const nepDate = new NepaliDate(rDate).getBS();
  //   // const nepDate = new NepaliDate(new Date(newss));
  //   var cm = nepDate.month + 1;
  //   if (cm < 10) {
  //     cm = "0" + cm;
  //   }
  //   //
  //   let strDate = nepDate.year + "/" + cm + "/" + nepDate.date;
  //   //
  //   return strDate;
  // };

  const handleView = (row) => {
    setSelectedNote(row);
    setViewPopup(true);
  };
  const updateRequest = (row) => {
    setSelectedNote(row);
    setConfirmPopup(true);
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setLeaveList(srchResult);
      } else {
        setLeaveList({});
      }
    } else {
      setLeaveList(originalList);
    }
  };

  useEffect(() => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      LeaveTypeID: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      
      FetchURL: `${appURL}api/leave-note`,
      Type: "POST",
    };

    Fetchdata(params)
      .then(function (result) {
        if (result.StatusCode === 200) {
          const postResult = result.Values ? result.Values : "";
          setLeaveList(postResult);
          setOriginalList(postResult);
          setLoading(false);
        } else {
          setLeaveList([]);
          setOriginalList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLeaveList([]);
        setOriginalList([]);
        setLoading(false);
      });
  }, [reload]);

  const addLeaveNote = (e) => {
    setLeaveNotePopup(true);
    setFormValues(initalvalue);
    setRe(Math.random());
    setformErrors({});
  };

  return (
    <>
      {/* <Toast /> */}

      <div className="container-fluid leavenote-wrapper mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              All Leavenote
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span> Fiscal Year :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right mb-3">
                <div className="btn-addlnote mt-3">
                  <button
                    type="button"
                    class="btn btn-sm"
                    style={{
                      background: "var(--button-color)",
                      color: "white",
                    }}
                    onClick={addLeaveNote}
                  >
                    <GoPlus color="#fff" className="me-1" />
                    Request Leave
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {" "}
                <DataTable
                  columns={columns}
                  data={leavelist}
                  customStyles={customStyles}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="450px"
                  highlightOnHover
                  pointerOnHover
                  progressPending={loading}
                  responsive
                  subHeader
                  dense
                  striped
                  subHeaderComponent={
                    <>
                      <div className=" w-100 ">
                        <div className="d-flex uk-flex-middle justify-content-end">
                          <div>
                            <div class="uk-search uk-search-default">
                              <AiOutlineSearch className="search-icon" />
                              <input
                                placeholder="Search"
                                ref={searchInput}
                                type="text"
                                className="form-control form-control-sm searchField"
                                onChange={searchHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />
              </>
            )}
          </div>
        </>
      </div>

      <LeaveNotePopup
        setLeaveNotePopup={setLeaveNotePopup}
        leaveNotePopup={leaveNotePopup}
        reload={reload}
        setReload={setReload}
        DFlag={DFlag}
        initalvalue={initalvalue}
        setFormValues={setFormValues}
        formValues={formValues}
        setformErrors={setformErrors}
        formErrors={formErrors}
        re={re}
      />

      {selected_note && (
        <ViewLeaveNote
          note={selected_note}
          setViewPopup={setViewPopup}
          viewPopup={viewPopup}
          DFlag={DFlag}
        />
      )}

      {confirmPopup &&
        selected_note &&
        ConfirmPopup({
          FormData: {
            AuthCode: User.AuthCode,
            ComID: User.ComID,
            UserID: User.UID,
            Flag: "R",
            LeaveID: selected_note.LeaveID,
            // FetchURL: "https://esnep.com/easyoffice/api/leave-status",
            FetchURL: `${appURL}api/leave-note`,
            Type: "POST",
          },
          reload: reload,
          setReload: setReload,
          setTrigger: setConfirmPopup,
          message: "Are you sure you want to delete this item ?",
        })}
    </>
  );
}
