import React, { useRef, useState } from "react";
import UpperbarContext from "../../../context/upperbar-context";
import { useContext } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiTwotonePrinter } from "react-icons/ai";
import PrintableComp from "./PrintableComp";
import ReactToPrint from "react-to-print";
import NepaliDate from "nepali-date-converter";
import PersonalSlipContext from "../payrollState/PersonalSlipContext";

const PersonalSlip = () => {
  const { fiscalYear, todayDate, mode } = useContext(UpperbarContext);
  const { inputValues, setInputValues, slipList, staffList } =
    useContext(PersonalSlipContext);
  const componentRef = useRef(null);

  const cur_year = new Date().getFullYear();
  const nYears = generateArrayOfNepYears(cur_year);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <div className="container-fluid classatten-wrapper ps-4 pe-4">
      <div className="row ">
        <div className="page-header">
          <div className="text-start  page-title">
            {mode === "en" ? "Personal Slip" : "व्यक्तिगत पर्ची"}
          </div>
          <div className="page-date">
            <div className="sec-content">
              <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
              {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
              {fiscalYear.StartDate}
              <span>-</span>
              {fiscalYear.EndDate}
            </div>
          </div>
        </div>
        <hr className="title-hr" />
      </div>

      <div className="sec-dataTable">
        <div className="upper-dataTbl  border-0">
          <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
            <div className="uk-flex uk-flex-wrap">
              <div className="me-2 mt-3">
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                </label>
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example "
                  onChange={handleOnChange}
                  name="year"
                  value={inputValues.year}
                >
                  <option disabled value="" selected>
                    {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                  </option>
                  {nYears.map((list) => (
                    <>
                      <option key={list} value={list}>
                        {list}
                      </option>
                    </>
                  ))}
                </select>
              </div>

              <div className="me-2 mt-3">
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                </label>
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example "
                  onChange={handleOnChange}
                  name="month"
                  value={inputValues.month}
                >
                  <option disabled value="" selected>
                    {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                  </option>
                  <option value="01">
                    {mode === "en" ? "Baishakh" : "बैशाख"}
                  </option>
                  <option value="02">
                    {mode === "en" ? "Jestha" : "जेष्ठ"}
                  </option>
                  <option value="03">{mode === "en" ? "Ashar" : "असार"}</option>
                  <option value="04">
                    {mode === "en" ? "Shrawan" : "श्रावण"}
                  </option>
                  <option value="05">
                    {mode === "en" ? "Bhadra" : "भाद्र"}
                  </option>
                  <option value="06">{mode === "en" ? "Asoj" : "असोज"}</option>
                  <option value="07">
                    {mode === "en" ? "Kartik" : "कार्तिक"}
                  </option>
                  <option value="08">
                    {mode === "en" ? "Mangsir" : "मंसिर"}
                  </option>
                  <option value="09">{mode === "en" ? "Poush" : "पौष"}</option>
                  <option value="10">{mode === "en" ? "Magh" : "माघ"}</option>
                  <option value="11">
                    {mode === "en" ? "Falgun" : "फाल्गुन"}
                  </option>
                  <option value="12">
                    {mode === "en" ? "Chaitra" : "चैत्र"}
                  </option>
                </select>
              </div>

              <div className="me-2 mt-3">
                <label className="d-block text-start dark-gray mb-1">
                  {mode === "en" ? "Select User" : "प्रयोगकर्ता चयन गर्नुहोस्"}
                </label>
                <select
                  class="form-select form-select-sm"
                  aria-label="Default select example "
                  onChange={handleOnChange}
                  name="user"
                  value={inputValues.user}
                >
                  <option disabled value="" selected>
                    {mode === "en"
                      ? "Select User"
                      : "प्रयोगकर्ता चयन गर्नुहोस्"}
                  </option>
                  <option value="-1">
                    {" "}
                    {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                  </option>
                  {staffList.map((item) => (
                    <option key={item.UserID} value={item.UserID}>
                      {item.FullName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <div className="d-flex uk-flex-middle uk-flex-right">
                <div className="export-btn">
                  <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() => (
                      <button uk-tooltip="Print" className="mx-1 border-0">
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PrintableComp ref={componentRef} slipList={slipList} />
      </div>
    </div>
  );
};

export default PersonalSlip;
