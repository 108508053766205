import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import AuthContext from "../../../context/auth-context";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Fetchdata } from "../../../hooks/getData";
import Spinner from "../../../loading/spinner";
import "../../entry/voucher/voucher.css";
import { ToastContainer, toast } from "react-toastify";
import { englishToNepaliNumber } from "nepali-number";
import NepaliDate from "nepali-date-converter";
import { GetCurrYear, GetCurrMonth } from "../../../hooks/dateConvertor";

export default function Absent() {
  const { User } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  //   const searchInput = useRef("");
  const {
    fiscalYear,
    todayDate,
    customStyles,
    appURL,
    userDetails,
    darkText,
    mode,
  } = useContext(UpperbarContext);

  const initalvalue = {
    year: GetCurrYear(),
    month: GetCurrMonth(),
  };

  const [inputValues, setInputValues] = useState(initalvalue);

  const cur_year = new Date().getFullYear();
  const nYears = generateArrayOfNepYears(cur_year);

  function getNepaliDate(date) {
    const nepDate = new NepaliDate().getBS();
    //
    return nepDate;
  }

  function generateArrayOfNepYears(cur_year) {
    let nCur_date = getNepaliDate(cur_year);
    var max = nCur_date.year;
    var min = 2079;
    var years = [];

    // for (var i = max; i >= min; i--) {
    //   years.push(i);
    // }
    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    return years;
  }

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Full Name" : "पुरा नाम",
      // center: true,
      // grow: 0,

      selector: (row) => row.StaffName,
    },
    {
      name: mode === "en" ? "Absent" : "अनुपस्थित",
      // center: true,
      // grow: 0,

      selector: (row) => row.TotalAbsent,
    },
    {
      name: mode === "en" ? "Leave" : "छोड",
      // center: true,
      // grow: 0,

      selector: (row) => row.Leave,
    },
  ];

  //  API to show absent list
  const [absentList, setAbsentList] = useState([]);

  useEffect(() => {
    absentLst();
  }, [inputValues]);

  const absentLst = () => {
    const UserID = "" + User.UID;
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: UserID,
      CID: "1",
      Flag: "SA",
      DFlag: "-1",
      MonthNp: `${inputValues.year}/${inputValues.month}`,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/payroll-generate`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAbsentList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setAbsentList([]);
        setLoading(false);
      }
    });
  };

  // ------------------
  const searchInput = useRef("");

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["StaffName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setAbsentList(srchResult);
      } else {
        setAbsentList({});
      }
    } else {
      setAbsentList(originalList);
    }
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title" style={darkText}>
              {mode === "en" ? "Absent" : "अनुपस्थित"}
            </div>
            <div className="page-date">
              <div className="sec-content" style={darkText}>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl pb-2">
              <div className="uk-flex  uk-flex-wrap uk-flex-between uk-flex-middle">
                <div className="uk-flex uk-flex-wrap uk-flex-bottom me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="year"
                      value={inputValues.year}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Year" : "वर्ष चयन गर्नुहोस्"}
                      </option>
                      {nYears.map((list) => (
                        <>
                          <option key={list} value={list}>
                            {list}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                    </label>
                    <select
                      class="form-select form-select-sm"
                      aria-label="Default select example "
                      onChange={handleOnChange}
                      name="month"
                      value={inputValues.month}
                    >
                      <option disabled value="" selected>
                        {mode === "en" ? "Select Month" : "महिना चयन गर्नुहोस्"}
                      </option>
                      <option value="01">
                        {mode === "en" ? "Baishakh" : "बैशाख"}
                      </option>
                      <option value="02">
                        {mode === "en" ? "Jestha" : "जेष्ठ"}
                      </option>
                      <option value="03">
                        {mode === "en" ? "Ashar" : "असार"}
                      </option>
                      <option value="04">
                        {mode === "en" ? "Shrawan" : "श्रावण"}
                      </option>
                      <option value="05">
                        {mode === "en" ? "Bhadra" : "भाद्र"}
                      </option>
                      <option value="06">
                        {mode === "en" ? "Asoj" : "असोज"}
                      </option>
                      <option value="07">
                        {mode === "en" ? "Kartik" : "कार्तिक"}
                      </option>
                      <option value="08">
                        {mode === "en" ? "Mangsir" : "मंसिर"}
                      </option>
                      <option value="09">
                        {mode === "en" ? "Poush" : "पौष"}
                      </option>
                      <option value="10">
                        {mode === "en" ? "Magh" : "माघ"}
                      </option>
                      <option value="11">
                        {mode === "en" ? "Falgun" : "फाल्गुन"}
                      </option>
                      <option value="12">
                        {mode === "en" ? "Chaitra" : "चैत्र"}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      {/* <button
                      type="button"
                      class="btn btn-sm"
                      style={{
                        background: "var(--button-color)",
                        color: "white",
                      }}
                      //   onClick={handleAdd}
                    >
                      <GoPlus color="#fff" className="me-1" />
                      Add Area
                    </button> */}
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mt-3 mt-3 tableHeight"
              style={{ maxHeight: "400px" }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <DataTable
                  columns={columns}
                  data={absentList}
                  customStyles={customStyles}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="350px"
                  highlightOnHover
                  pointerOnHover
                  paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                  responsive
                  // progressPending={loading}
                  dense
                  striped
                  subHeader
                  subHeaderComponent={
                    <>
                      <div className=" w-100 mb-3 mt-3">
                        <div className="d-flex uk-flex-middle justify-content-end">
                          <div>
                            <form class="uk-search uk-search-default">
                              <AiOutlineSearch className="search-icon" />
                              <input
                                placeholder={
                                  mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                                }
                                ref={searchInput}
                                type="text"
                                className="form-control form-control-sm searchField"
                                onChange={searchHandler}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
}
