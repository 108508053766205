import React, { useContext, useEffect, useState } from "react";
import Job from "./Job";
import Candidate from "./Candidate/Candidate";
import Company from "./Company/Company";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../../context/auth-context";
import UpperbarContext from "../../context/upperbar-context";
import { Fetchdata } from "../../hooks/getData";
import { FaRegCalendarAlt } from "react-icons/fa";
const EliteJobDashboard = () => {
  const {
    fiscalYear,
    todayDate,
    appURL,
    customStyles,
    mode,
    userDetails,
    expires,
  } = useContext(UpperbarContext);
  const { User } = useContext(AuthContext);

  const handleSubmit = () => {
    const dataForm = {
      ComID: User.ComID,
      Flag: "U",
      Email: User.Email,
      Address: User.ComAddress,
      District: User.ComDistrictID,
      IndustryID: "1",
      Website: User.ComWebsite,
      Bio: "",
      Latitude: User.ComLat,
      Longitude: User.ComLong,
      Facebook: User.ComFb,
      Instagram: "",
      Linkedin: "",
      Logo: User.ComLogo,
      Telephone: User.ComTel,
      FetchURL: `${appURL}api/job/com-sync`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };
  return (
    <>
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row">
          <div className="page-header">
            <div className="text-start  page-title">Job</div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        
          <Job handleSubmit={handleSubmit} />
        
      </div>
    </>
  );
};

export default EliteJobDashboard;
