import React, { useState, useEffect, useContext } from "react";
import NotificationContext from "./NotificationContext";
import { Fetchdata } from "../hooks/getData";
import { ToastContainer, toast } from "react-toastify";
import AuthContext from "../context/auth-context";
import UpperbarContext from "../context/upperbar-context";
import { defaultThemes } from "react-data-table-component";
// import { getBoundingClientObj } from "react-select/dist/declarations/src/utils";
import { GetEnglishDate } from "../hooks/dateConvertor";
import { GetNepaliDate } from "../hooks/dateConvertor";
import { red } from "@material-ui/core/colors";
import { nepaliToEnglishNumber } from "nepali-number";
import $ from "jquery";

function NotificationState(props) {
  const { User } = useContext(AuthContext);
  const { appURL } = useContext(UpperbarContext);

  const [loading, setLoading] = useState(false);

  var d = new Date();

  // for notification starts

  const initialvalue = {
    all: "a",
    department: "0",
    subDepartment: "0",
    staff: "0",
    designation: "0",
    title: "",
    description: "",
    actionButton: "",
    actionUrl: "",
    pubDate: GetNepaliDate(d),
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");
  const [image, setImage] = useState("");

  const [notificationErrors, setNotificationErrors] = useState({});
  const [notificationValues, setNotificationValues] = useState(initialvalue);
  const [notificationPopup, setNotificationPopup] = useState(false);

  const [notificationList, setNotificationList] = useState([]);
  const [DFlag, setDFlag] = useState("N");
  const [notifyOriginalList, setNotifyOriginalList] = useState(null);

  const [chooseNotifyDepartment, setChooseNotifyDepartment] = useState("0");
  const [chooseNotifySubDepartment, setChooseNotifySubDepartment] =
    useState("0");
  const [chooseNotifyIndividual, setChooseNotifyIndividual] = useState("0");
  const [chooseNotifyDesignation, setChooseNotifyDesignation] = useState("0");
  const [chooseNotifyFlag, setChooseNotifyFlag] = useState("a");

  useEffect(() => {
    notifyList();
  }, [
    chooseNotifyDepartment,
    chooseNotifySubDepartment,
    chooseNotifyDesignation,
    chooseNotifyIndividual,
  ]);

  const notifyList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "S",
      UserID: -1,
      DepartmentID: chooseNotifyDepartment,
      SubDepartmentID: chooseNotifySubDepartment,
      DesignationID: chooseNotifyDesignation,
      StaffID: chooseNotifyIndividual,
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };

    Fetchdata(params).then(function (resp) {
      if (resp.StatusCode === 200) {
        const postResult = resp.Values ? resp.Values : "";
        setLoading(false);
        setNotificationList(postResult);
        setNotifyOriginalList(postResult);
      } else {
        setNotificationList([]);
        setNotifyOriginalList([]);
        setLoading(false);
      }
    });
  };

  // sessionStorage.setItem("NotificationList", JSON.stringify(notificationList));

  const [perID, setPerId] = useState();
  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = (data) => {
    setPerId(data.NotificationID);
    setNotificationValues({
      all:
        data.DepartID !== 0 && data.SubDepartID !== 0 && data.DesignID !== 0
          ? "de"
          : data.DesignID !== 0 && data.SubDepartID !== 0 && data.UserID !== 0
          ? "i"
          : data.DepartID !== 0 && data.SubDepartID !== 0
          ? "s"
          : data.DepartID !== 0
          ? "d"
          : "a",
      department: data.DepartID,
      subDepartment: data.SubDepartID,
      designation: data.DesignID,
      staff: data.UserID,
      title: data.Title,
      description: data.Description,
      actionButton: data.AcBtn,
      actionUrl: data.AcUrl,
      pubDate: data.PublishedDateNp,
    });
    setImage(data.Image);
    setEditPopup(true);
  };

  // to edit notification

  const editdata = () => {
    const dataForm = {
      ComID: User.CompanyId,
      StaffID: User.UID,
      Flag: "U",
      NFlag: notificationValues.all !== " " ? notificationValues.all : " ",
      UserID: notificationValues.staff !== " " ? notificationValues.staff : " ",
      NotificationID: perID,
      Title: notificationValues.title !== " " ? notificationValues.title : " ",
      Description:
        notificationValues.description !== " "
          ? notificationValues.description
          : " ",
      Image: image !== null ? image.split(",")[1] : "",
      AcBtn:
        notificationValues.actionButton !== " "
          ? notificationValues.actionButton
          : " ",
      AcUrl:
        notificationValues.actionUrl !== " "
          ? notificationValues.actionUrl
          : " ",
      PublishedDate:
        DFlag === "N"
          ? GetEnglishDate(notificationValues.pubDate)
          : notificationValues.pubDate,

      DepartmentID:
        notificationValues.department !== " "
          ? notificationValues.department
          : " ",
      // SubDepartmentID:
      //   notificationValues.subDepartment !== " "
      //     ? notificationValues.subDepartment
      //     : " ",
      DesignationID:
        notificationValues.designation !== " "
          ? notificationValues.designation
          : " ",
      BranchID: User.BranchId,
      FiscalID: User.FiscalID,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (resp) {
      if (resp.StatusCode === 200) {
        setEditPopup(false);
        $(".editNotificationPop").slideUp(500);
        $(".editNotificationPopBg").fadeOut(500);
        notifyList();
        toast.success(resp.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + resp.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // to hit notification status
  const stat = [];
  const [newStatus, setNewStatus] = useState(stat);

  const deactivateNotify = (ID, IsActive) => {
    const dataForm = {
      ComID: User.CompanyId,
      StaffID: User.UID,
      NotificationID: ID,
      Flag: "US",
      Status: IsActive,
      BranchID: User.BranchId,
      FiscalID: User.FiscalID,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };
    if (IsActive === 1) {
      dataForm.Status = 0;
    } else {
      dataForm.Status = 1;
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        notifyList();
        let NewstatsN = JSON.parse(JSON.stringify(newStatus));
        let pitchNewStatus;

        if (dataForm.Status === 1) {
          pitchNewStatus = "Activated";
        } else if (dataForm.Status === 0) {
          pitchNewStatus = "Deactivated";
        }
        setNewStatus(NewstatsN);
        toast.success(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast.error("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // for notification ends

  return (
    <NotificationContext.Provider
      value={{
        loading,
        isUploaded,
        setIsUploaded,
        typeFile,
        setTypeFile,
        image,
        setImage,
        setLoading,
        notificationList,
        setNotificationList,
        notificationValues,
        setNotificationValues,
        notificationErrors,
        setNotificationErrors,
        notificationPopup,
        setNotificationPopup,
        notifyList,
        handleEdit,
        DFlag,
        setDFlag,
        editdata,
        setEditPopup,
        editPopup,
        deactivateNotify,
        chooseNotifyDepartment,
        chooseNotifySubDepartment,
        chooseNotifyDesignation,
        chooseNotifyFlag,
        setChooseNotifyDepartment,
        setChooseNotifyDesignation,
        setChooseNotifyFlag,
        setChooseNotifySubDepartment,

        chooseNotifyIndividual,
        setChooseNotifyIndividual,
        notifyOriginalList,
        // fetchNotification,
        // image1,
        // setImage1,
        initialvalue,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}
export default NotificationState;
