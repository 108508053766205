import NepaliDate from "nepali-date-converter";
import React, { useContext, useState } from "react";
import { defaultThemes } from "react-data-table-component";
import AuthContext from "./auth-context";
import { useNavigate } from "react-router-dom";
const UpperbarContext = React.createContext();

export const UpperbarContextProvider = (props) => {
  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const appURL = process.env.REACT_APP_URL;
  const logo = process.env.REACT_APP_LOGO;
  const map = process.env.REACT_APP_MAP;
  const name = process.env.REACT_APP_NAME;
  const address = process.env.REACT_APP_ADDRESS;
  const tel = process.env.REACT_APP_TEL;
  const mail = process.env.REACT_APP_MAIL;
  const comID = process.env.REACT_APP_COMID;
  const url = process.env.REACT_APP_URLPREVIEW;
  const [logoutDropdown, setLogoutDropdown] = useState(null);
  const [logoutAlert, setLogoutAlert] = useState(false);
  const [sidebarLogout, setSidebarLogout] = useState(false);
  const [changePassPopup, setChangePassPopup] = useState(false);

  const [fiscalYear, setFiscalYear] = useState([]);
  const [todayDate] = useState(getNepaliDate());
  function getNepaliDate() {
    let date = new NepaliDate().getBS();
    let tm = date.month + 1 < 10 ? `0${date.month + 1}` : `${date.month + 1}`;
    let td = date.date < 10 ? `0${date.date}` : `${date.date}`;
    // if (date.month < 10) {
    //   if (date.date < 10) {
    //     return `${date.year}/0${date.month + 1}/0${date.date}`;
    //   } else {
    //     return `${date.year}/0${date.month + 1}/0${date.date}`;
    //   }
    // } else {
    //   return `${date.year}/${date.month + 1}/${date.date}`;
    // }
    return `${date.year}/${tm}/${td}`;
  }
  const [printPopup, setPrintPopup] = useState(false);
  const [mode, setMode] = useState("en");
  const [dateMode, setDateMode] = useState("np");
  // const [langText, setLangText] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [padding, setPadding] = useState(true);
  // const darkText = { color: mode === "light" ? "#000" : "#fff" };
  // const darkBg = { background: mode === "light" ? "#fff" : "#040412" };
  // const darkBg2 = { background: mode === "light" ? "#e6edf7" : "#0b0b45" };
  // const sidePanelBg = { background: mode === "light" ? "#004aad" : "#0b0b45" };
  // const mainBg = { background: mode === "light" ? "#fafdfb" : "#040423" };
  const [reload, setReload] = useState();
  const toggleLang = () => {
    if (mode === "en") {
      setMode("np");
      // setLangText(true);
    } else {
      setMode("en");
      // setLangText(false);
    }
  };
  const toggleDate = () => {
    if (dateMode === "en") {
      setDateMode("np");
      // setLangText(true);
    } else {
      setDateMode("en");
      // setLangText(false);
    }
  };

  const [userDetails, setUserDetails] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobList, setFilteredJobList] = useState("");
  //  calculate expiry date
  // Get today's date
  const today = new Date();

  // Create a new date object with the date "2025-02-16"
  const futureDate = new Date(userDetails.ComExpiry);

  // Calculate the time difference in milliseconds
  const timeDiff = futureDate - today;

  // Convert the time difference to days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Calculate the years, months, and days from the days value
  const years = Math.floor(daysDiff / 365);
  const months = Math.floor((daysDiff % 365) / 30);
  const remainingDays = daysDiff % 30;

  const expires = `${years} yrs ${months} months ${remainingDays} days`;
  // css for table other than hami chhimeki, client management, espay and cooperative

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "10px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      style: {
        minHeight: "60px",
      },
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
  };

  const customStylesForImage = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "10px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    cells: {
      style: {
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
        borderLeftColor: defaultThemes.default.divider.default,
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f2f0f0",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        fontWeight: "650",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
  };

  //customstyles end

  const logoutHandler = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    logout();
    setLogoutAlert(null);
    setLogoutDropdown(null);
    navigate("/login");
  };

  const [isActive, setIsActive] = useState(true);

  return (
    <UpperbarContext.Provider
      value={{
        expires,
        setSearchTerm,
        searchTerm,
        filteredJobList,
        setFilteredJobList,
        userDetails,
        setUserDetails,
        logoutDropdown,
        setLogoutDropdown,
        logoutAlert,
        setLogoutAlert,
        sidebarLogout,
        setSidebarLogout,
        changePassPopup,
        setChangePassPopup,
        fiscalYear,
        setFiscalYear,
        todayDate,
        appURL,
        mode,
        setMode,
        // langText,
        // setLangText,
        toggleLang,
        toggleDate,
        dateMode,
        sideMenu,
        setSideMenu,
        padding,
        setPadding,
        // darkText,
        // darkBg,
        // darkBg2,
        // sidePanelBg,
        // mainBg,
        reload,
        setReload,

        printPopup,
        setPrintPopup,

        logo,
        map,
        name,
        address,
        tel,
        mail,
        comID,
        url,
        customStyles,
        customStylesForImage,
        logoutHandler,
        isActive,
        setIsActive,
      }}
    >
      {props.children}
    </UpperbarContext.Provider>
  );
};

export default UpperbarContext;
