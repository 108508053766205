import React, { useContext, useEffect, useRef, useState } from "react";
import "./notification.css";
import Spinner from "../loading/spinner";
import { Fetchdata } from "../hooks/getData";
import DataTable from "react-data-table-component";
import AuthContext from "../context/auth-context";
import { ShowImgPreview } from "../hooks/imagePreview";
import NepaliDate from "nepali-date-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewNotificationPopup from "./viewNotificationPopup";
import UpperbarContext from "../context/upperbar-context";
import { GetNepaliDate } from "../hooks/dateConvertor";
import StaffContext from "../adminPanel/organization/staffState/StaffContext";
import NotificationPopup from "./NotificationPopup";
import EditNotificationPopup from "./editNotificationPopup";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BiPlusCircle } from "react-icons/bi";
import Toast from "../Toast";
import ConfirmPopup from "../hooks/confirmPopup";
import { GoPlus } from "react-icons/go";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import DeleteNotification from "./DeleteNotification";
import { englishToNepaliNumber } from "nepali-number";
import NotificationContext from "../notificationState/NotificationContext";

export default function Notification() {
  const {
    notificationList,
    setNotificationList,
    notificationValues,
    setNotificationValues,
    notificationErrors,
    setNotificationErrors,
    notificationPopup,
    setNotificationPopup,
    handleEdit,
    DFlag,
    setDFlag,
    editPopup,
    setEditPopup,
    deactivateNotify,
    chooseNotifyDepartment,
    chooseNotifySubDepartment,
    chooseNotifyDesignation,
    chooseNotifyFlag,
    chooseNotifyIndividual,
    setChooseNotifyDepartment,
    setChooseNotifyDesignation,
    setChooseNotifyFlag,
    setChooseNotifySubDepartment,
    setChooseNotifyIndividual,
    loading,
    notifyOriginalList,
    initialvalue,
    notifyList,
  } = useContext(NotificationContext);
  const { User } = useContext(AuthContext);

  const [imgPrv, setImgPrv] = useState(false);
  const [ImgPreview, setImgPreview] = useState("");
  const [selected_notification, setSelectedNotification] = useState("");
  const [viewPopup, setViewPopup] = useState(false);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState();
  const [reload, setReload] = useState(false);

  const [submit, setSubmit] = useState(false);
  const searchInput = useRef("");
  const { fiscalYear, customStyles,expires, appURL, todayDate, userDetails, mode } =
    useContext(UpperbarContext);

  const [re, setRe] = useState();

  const addNotification = () => {
    setNotificationPopup(true);
    setNotificationValues(initialvalue);
    setRe(Math.random());
  };

  const handleAll = (e) => {
    setChooseNotifyDepartment("0");
    setChooseNotifyDesignation("0");
    setChooseNotifySubDepartment("0");
    setChooseNotifyIndividual("0");

    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseNotifyFlag(value);
  };
  const handleDepartment = (e) => {
    setChooseNotifyDesignation("0");
    setChooseNotifySubDepartment("0");
    setChooseNotifyIndividual("0");

    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseNotifyDepartment(value);
  };

  const handleSubDepartment = (e) => {
    setChooseNotifyDesignation("0");
    setChooseNotifyIndividual("0");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseNotifySubDepartment(value);
  };

  const handleIndividual = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseNotifyIndividual(value);
  };

  const handleDesignation = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseNotifyDesignation(value);
  };


  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      // grow: 0,
      width: "70px",
      center: true,
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    {
      name: mode === "en" ? "Nepali Date" : "नेपाली मिति",
      // grow: 1,
      // center: true,
      width: "120px",
      selector: (row) => row.PublishedDateNp,
    },
    {
      name: mode === "en" ? "English Date" : "अंग्रेजी मिति",
      // grow: 1,
      // center: true,
      width: "120px",
      selector: (row) => row.PublishedDateEn,
    },
    {
      name: mode === "en" ? "Title" : "शीर्षक",
      // grow: 0,
      // center: true,
      minWidth: "200px",
      selector: (row) => row.Title,
    },
    // { name: "Date", grow: 0, center: true, selector: (row) => row.LeaveTitle },
    {
      name: mode === "en" ? "Description" : "विवरण",
      // grow: 1,
      minWidth: "350px",
      // center: true,
      selector: (row) => row.Description,
    },

    // {
    //   name: "Created By",
    //   // grow: 0,
    //   center: true,
    //   selector: (row) => row.CreatedBy,
    // },

    {
      name: mode === "en" ? "Action" : "कार्य",
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {/* {row.Image !== "-" && (
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--button-color)",
                    color: "white",
                  }}
                  onClick={() => {
                    setImgPreview(row.Image);
                    setImgPrv(true);
                  }}
                >
                  View Image
                </button>
              )} */}
              {/* <span className="pt-1">|</span> */}
              {/* <button
                type="button"
                class="btn my-1 btn-sm notispan"
                onClick={() => {
                  setSelectedNotification(row);
                  setViewPopup(true);
                }}
              >
                View
              </button> */}
              {/* <button
                type="button"
                class="btn btn-sm editspan mx-1"
                onClick={() => {
                  handleEdit(row);
                }}
                uk-tooltip={mode === "en" ? "Edit" : "सम्पादन गर्नुहोस्"}
              >
                <AiOutlineEdit />
              </button>{" "} */}
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => updateRequest(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
              {/* <span className="pt-1">|</span>
              {row.AcBtn !== "-" && row.RedUrl !== "-" && (
                <button
                  type="button"
                  class="btn btn-sm"
                  style={{
                    background: "var(--button-color)",
                    color: "white",
                  }}
                  // onClick={addLeaveNote}
                >
                  Action
                </button>
              )} */}
            </div>
          </>
        );
      },
    },
  ];

  const updateRequest = (row) => {
    setSelectedNote(row.NotificationID);
    setConfirmPopup(true);
  };

  const deleteNotice = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      Flag: "R",
      UserID: User.UID,
      NotificationID: selected_note,
      FetchURL: `${appURL}api/admin/notification`,
      Type: "POST",
    };

    Fetchdata(dataForm)
      .then(function (result) {
        if (result.StatusCode === 200) {
          setConfirmPopup(false);
          notifyList();
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      })
      .catch((err) => {
        toast.error("Error: " + err, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      });
  };

  // const changeStatus = (ID, IsActive) => {
  //   deactivateNotify(ID, IsActive);
  // };

  // const checkStatus = (IsActive) => {
  //   if (IsActive === 1) {
  //     return "Deactivate";
  //   } else if (IsActive === 0) {
  //     return "Activate";
  //   }
  // };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = notifyOriginalList.filter((list) => {
        return list["Title"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setNotificationList(srchResult);
      } else {
        setNotificationList([]);
      }
    } else {
      setNotificationList(notifyOriginalList);
    }
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "-1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [chooseNotifyDepartment]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: chooseNotifyDepartment,
  //     Flag: "S",
  //     Type: "POST",
  //     Status: 1,
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to hit Staff list
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  //API to hit Designation list

  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [chooseNotifyDepartment]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: chooseNotifyDepartment,
      IsActive: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid notification-wrapper mt-3 ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Notification" : "सूचना"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="all"
                      value={chooseNotifyFlag}
                      onChange={handleAll}
                      className="form-select form-select-sm"
                    >
                      <option value="a">{mode === "en" ? "All" : "सबै"}</option>
                      {/* <option value="d">Department Wise</option>
                      <option value="s">Sub Department Wise</option>
                      <option value="de">Designation</option> */}
                      <option value="i">
                        {mode === "en" ? "Individual" : "व्यक्तिगत"}
                      </option>
                    </select>
                  </div>

                  {chooseNotifyFlag === "d" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="department"
                        value={chooseNotifyDepartment}
                        onChange={handleDepartment}
                        className="form-select form-select-sm"
                      >
                        <option
                          value="0"
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </option>
                        {departmentList.map((item) => (
                          <option
                            key={item.DepartmentID}
                            value={item.DepartmentID}
                          >
                            {item.Department}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {chooseNotifyFlag === "s" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="department"
                          value={chooseNotifyDepartment}
                          onChange={handleDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="subDepartment"
                          value={chooseNotifySubDepartment}
                          onChange={handleSubDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>
                          {subdepartmentList.map((item) => (
                            <option
                              key={item.SubDepartID}
                              value={item.SubDepartID}
                            >
                              {item.SubDepartName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}

                  {chooseNotifyFlag === "i" && (
                    <>
                      {/* <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="department"
                          value={chooseNotifyDepartment}
                          onChange={handleDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      {/* <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="subDepartment"
                          value={chooseNotifySubDepartment}
                          onChange={handleSubDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>
                          {subdepartmentList.map((item) => (
                            <option
                              key={item.SubDepartID}
                              value={item.SubDepartID}
                            >
                              {item.SubDepartName}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="designation"
                          value={chooseNotifyIndividual}
                          onChange={handleIndividual}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Staff"
                              : "कर्मचारी चयन गर्नुहोस्"}
                          </option>
                          {/* <option value="-1">All</option> */}
                          {staffList.map((item) => (
                            <option key={item.UserID} value={item.UserID}>
                              {item.FullName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}

                  {chooseNotifyFlag === "de" && (
                    <>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="department"
                          value={chooseNotifyDepartment}
                          onChange={handleDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="subDepartment"
                          value={chooseNotifySubDepartment}
                          onChange={handleSubDepartment}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>
                          {subdepartmentList.map((item) => (
                            <option
                              key={item.SubDepartID}
                              value={item.SubDepartID}
                            >
                              {item.SubDepartName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          {mode === "en"
                            ? "Select Designation"
                            : "पदनाम चयन गर्नुहोस्"}
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="designation"
                          value={chooseNotifyDesignation}
                          onChange={handleDesignation}
                          className="form-select form-select-sm"
                        >
                          <option
                            value="0"
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            {mode === "en"
                              ? "Select Designation"
                              : "पदनाम चयन गर्नुहोस्"}
                          </option>
                          {/* <option value="-1">All</option> */}
                          {designationList.map((item) => (
                            <option
                              key={item.DesignationID}
                              value={item.DesignationID}
                            >
                              {item.Designation}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    {userDetails.IsManager !== 0 && (
                      <div className="btn-addlnote mt-3">
                        <button
                          type="button"
                          class="btn btn-sm"
                          style={{
                            background: "var(--button-color)",
                            color: "white",
                          }}
                          onClick={addNotification}
                        >
                          <GoPlus color="#fff" className="me-1" />

                          {mode === "en"
                            ? "Add Notification"
                            : "नयाँ सूचना थप्नुहोस्"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <Spinner />
              </>
            ) : (
              <DataTable
                columns={columns}
                data={notificationList}
                customStyles={customStyles}
                pagination
                fixedHeader
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeaderScrollHeight="350px"
                highlightOnHover
                pointerOnHover
                responsive
                // progressPending={loading}
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>
      {imgPrv &&
        ShowImgPreview({
          img: ImgPreview,
          setTrigger: setImgPrv,
        })}

      {/* {selected_notification && viewPopup && (
        <ViewNotificationPopup
          notification={selected_notification}
          setViewPopup={setViewPopup}
          DFlag={DFlag}
        />
      )} */}

      <EditNotificationPopup
        setEditPopup={setEditPopup}
        editPopup={editPopup}
        DFlag={DFlag}
        notificationValues={notificationValues}
        setNotificationValues={setNotificationValues}
        notificationErrors={notificationErrors}
        setNotificationErrors={setNotificationErrors}
        re={re}
      />

      <NotificationPopup
        setNotificationPopup={setNotificationPopup}
        notificationPopup={notificationPopup}
        // crList={crList}
        DFlag={DFlag}
        notificationValues={notificationValues}
        setNotificationValues={setNotificationValues}
        notificationErrors={notificationErrors}
        setNotificationErrors={setNotificationErrors}
        submit={submit}
        setSubmit={setSubmit}
        re={re}
        // fetchNotification={fetchNotification}
        // image1={image1}
        // setImage1={setImage1}
        // chooseCooperative={chooseCooperative}
      />

      {/* {confirmPopup &&
        selected_note &&
        ConfirmPopup({
          FormData: {
            ComID: User.CompanyId,
            StaffID: User.UID,
            NotificationID: selected_note.NotificationID,
            Flag: "US",
            Status: 0,
            BranchID: User.BranchId,
            FiscalID: User.FiscalID,
            // FetchURL: "https://esnep.com/easyoffice/api/leave-status",
            FetchURL: `${appURL}api/admin/notification`,
            Type: "POST",
          },
          reload: reload,
          setReload: setReload,
          setTrigger: setConfirmPopup,
          message: "Are you sure you want to delete this item ?",
        })} */}

      {confirmPopup && (
        <DeleteNotification
          deleteNotice={deleteNotice}
          setConfirmPopup={setConfirmPopup}
        />
      )}
    </>
  );
}
