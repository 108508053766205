import React, { useContext, useEffect, useState } from "react";
import "../../../leaveNotes/leaveNotePopup.css";
import "../../organization/department/DepartmentPopup.css";
import "./Bank.css";
import CloseIcon from "../../../../images/CloseIcon.svg";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetchdata } from "../../../hooks/getData";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import $ from "jquery";

export default function BankPopup({
  setBankPopup,
  reload,
  setReload,
  bankFormValue,
  setBankFormValue,
  bankFormError,
  setBankFormError,
  bnkList,
  bankPopup,
}) {
  const { User } = useContext(AuthContext);
  const { mode, appURL } = useContext(UpperbarContext);
  const closePopUp = (e) => {
    setBankPopup(false);
    setBankFormError({});
    $(".addBankPopBg").fadeOut(500);
    $(".addBankPop").slideUp(500);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (bankPopup) {
      $(".addBankPopBg").fadeIn(500);
      $(".addBankPop").slideDown(500);
    }
  }, [bankPopup]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    if (name === "department") {
      bankFormValue.subDepartment = "";
      bankFormValue.staff = "";
    }
    if (name === "subDepartment") {
      bankFormValue.staff = "";
    }

    setBankFormValue({ ...bankFormValue, [name]: value });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.department) {
      errors.department = mode === "en" ? "Required" : "आवश्यक छ";
    }
    // if (!values.subDepartment) {
    //   errors.subDepartment = mode === "en" ? "Required" : "आवश्यक छ";
    // }
    if (!values.staff) {
      errors.staff = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.bank) {
      errors.bank = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.accountName) {
      errors.accountName = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.accountNo) {
      errors.accountNo = mode === "en" ? "Required" : "आवश्यक छ";
    }
    if (!values.branch) {
      errors.branch = mode === "en" ? "Required" : "आवश्यक छ";
    }

    return errors;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setBankFormError(validate(bankFormValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(bankFormError).length === 0 && isSubmit) {
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: User.UID,
        StaffID: bankFormValue.staff,
        Flag: "i",
        BankName: bankFormValue.bank,
        AcName: bankFormValue.accountName,
        AcNo: bankFormValue.accountNo,
        Branch: bankFormValue.branch,
        BranchID: User.BranchID,
        FiscalID: User.FiscalID,
        Type: "POST",
        FetchURL: `${appURL}api/admin/bank`,
      };

      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          setBankPopup(false);
          bnkList();
          $(".addBankPopBg").fadeOut(500);
          $(".addBankPop").slideUp(500);
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setIsSubmit(false);
    }
  }, [bankFormError]);

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [bankFormValue.department]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: bankFormValue.department,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };
  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, []);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      Flag: "S",
      DFlag: "STAFF",
      BranchID: BranchID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/ref-values`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };
  return (
    <>
      {/* <Toast /> */}
      <div className="container newpopup-bg addBankPopBg">
        <div className="newpopup addBankPop" style={{ width: "50%" }}>
          <div className="newpopup-head ps-0 pe-0">
            <div className="popUpTitle"> {mode === "en" ? "Bank" : "बैंक"}</div>
            <div className="popUpClose">
              <img
                className="popUpCloseIcon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={closePopUp}
              />
            </div>
          </div>
          <div className="newpopup-body ps-3 pe-3">
            <div className="form-in-padding">
              <div className="row text-start mt-2 ">
                <div className="col-md-12 col-sm-12 col-lg-12">
                  <div className="row text-start ">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <div className="form-group mt-0">
                        <label
                          htmlFor="department"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="department"
                          value={bankFormValue.department}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Department"
                              : "विभाग चयन गर्नुहोस्"}
                          </option>
                          {departmentList.map((item) => (
                            <option
                              key={item.DepartmentID}
                              value={item.DepartmentID}
                            >
                              {item.Department}
                            </option>
                          ))}
                        </select>
                        {bankFormError.department && (
                          <p className="errormsg">{bankFormError.department}</p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group mt-0">
                        <label
                          htmlFor="subDepartment"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en"
                            ? "Select Sub-Department"
                            : "उप विभाग चयन गर्नुहोस्"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          class="form-select form-select-sm"
                          aria-label="Default select example"
                          name="subDepartment"
                          value={bankFormValue.subDepartment}
                          onChange={handleChange}
                        >
                          <option disabled value="" selected>
                            {mode === "en"
                              ? "Select Sub-Department"
                              : "उप विभाग चयन गर्नुहोस्"}
                          </option>

                          {subdepartmentList.map((item) => (
                            <option
                              key={item.SubDepartID}
                              value={item.SubDepartID}
                            >
                              {item.SubDepartName}
                            </option>
                          ))}
                        </select>
                        {bankFormError.subDepartment && (
                          <p className="errormsg">
                            {bankFormError.subDepartment}
                          </p>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="staff" style={{ fontSize: "12px" }}>
                        {mode === "en"
                          ? "Select Staff"
                          : "कर्मचारी चयन गर्नुहोस्"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        class="form-select form-select-sm"
                        aria-label="Default select example"
                        name="staff"
                        value={bankFormValue.staff}
                        onChange={handleChange}
                      >
                        <option disabled value="" selected>
                          {mode === "en"
                            ? "Select Staff"
                            : "कर्मचारी चयन गर्नुहोस्"}
                        </option>
                        {staffList.map((item) => (
                          <option key={item.UserID} value={item.UserID}>
                            {item.FullName}
                          </option>
                        ))}
                      </select>
                      {bankFormError.staff && (
                        <p className="errormsg">{bankFormError.staff}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="bank" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Bank" : "बैंक"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="bank"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="bank"
                        value={bankFormValue.bank}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Bank" : "बैंक"}
                      />
                      {bankFormError.bank && (
                        <p className="errormsg">{bankFormError.bank}</p>
                      )}
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label
                          htmlFor="accountName"
                          style={{ fontSize: "12px" }}
                        >
                          {mode === "en" ? "Account Name" : "खाताको नाम"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="accountName"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm "
                          name="accountName"
                          value={bankFormValue.accountName}
                          onChange={handleChange}
                          placeholder={
                            mode === "en" ? "Account Name" : "खाताको नाम"
                          }
                        />
                        {bankFormError.accountName && (
                          <p className="errormsg">
                            {bankFormError.accountName}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="accountNo" style={{ fontSize: "12px" }}>
                          {mode === "en" ? "Account No." : "खाता नम्बर"}
                          <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          id="accountNo"
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm "
                          name="accountNo"
                          value={bankFormValue.accountNo}
                          onChange={handleChange}
                          placeholder={
                            mode === "en" ? "Account No." : "खाता नम्बर"
                          }
                        />
                        {bankFormError.accountNo && (
                          <p className="errormsg">{bankFormError.accountNo}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row text-start ">
                    <div className="form-group">
                      <label htmlFor="branch" style={{ fontSize: "12px" }}>
                        {mode === "en" ? "Branch" : "साखा"}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        id="branch"
                        style={{ fontSize: "13px" }}
                        type="text"
                        className="form-control form-control-sm "
                        name="branch"
                        value={bankFormValue.branch}
                        onChange={handleChange}
                        placeholder={mode === "en" ? "Branch" : "साखा"}
                      />
                      {bankFormError.branch && (
                        <p className="errormsg">{bankFormError.branch}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-footer">
            <div className="row  mt-1 mb-1">
              <div>
                <button
                  type="button"
                  class="btn btn-sm me-2"
                  style={{ background: "var(--button-color)", color: "white" }}
                  onClick={formSubmit}
                >
                  {mode === "en" ? "Submit" : "बुझाउनुहोस्"}
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger me-3"
                  style={{ color: "white" }}
                  onClick={closePopUp}
                >
                  {mode === "en" ? "Cancel" : "बन्द गर्नुहोस्"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
