import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../profile/profile.css";
import DataTable from "react-data-table-component";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../loading/spinner";
import DocumentPopup from "./DocumentPopup";
import DocumentEditPopup from "./DocumentEditPopup";
import UpperbarContext from "../../../context/upperbar-context";
import StaffContext from "../staffState/StaffContext";
import { FaRegCalendarAlt } from "react-icons/fa";
import Toast from "../../../Toast";
import ConfirmPopup from "../../../hooks/confirmPopup";
import { GoPlus } from "react-icons/go";
import { AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { englishToNepaliNumber } from "nepali-number";

export default function Document() {
  const { fiscalYear, expires, appURL, customStylesForImage, todayDate, mode } =
    useContext(UpperbarContext);
  const [documentPopup, setDocumentPopup] = useState(false);
  const [documentEditPopup, setDocumentEditPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const searchInput = useRef("");
  const [files, setFile] = useState("");

  const documentValue = {
    department: "",
    subDepartment: "",
    designation: "",
    staff: "",
    fileName: "",
    radio: "url",
    url: "",
    pdf: "",
    ext: "",
  };
  const [documentFormValue, setDocumentFormValue] = useState(documentValue);
  const [documentFormError, setDocumentFormError] = useState({});

  const [chooseDepartment, setChooseDepartment] = useState("");
  const [chooseSubDepartment, setChooseSubDepartment] = useState("");
  const [chooseStaff, setChooseStaff] = useState("");
  const [chooseDesignation, setChooseDesignation] = useState("");

  const [reportOption, setReportOption] = useState("A");

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [image, setImage] = useState("");

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [selected_note, setSelectedNote] = useState("");

  const { User } = useContext(AuthContext);

  const addLeaveNote = (e) => {
    setDocumentPopup(true);
    setFile("");
    setDocumentFormValue(documentValue);
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setDocumentEditPopup(true);
    setTitleID(datas.DocID);
    setDocumentFormValue({
      fileName: datas.FileName,
    });
  };

  //API to hit Designation status

  // const stateInitial = [];
  // const [newStat, setNewStat] = useState(stateInitial);

  // const deactivateDepart = (ID, IsActive) => {
  //     const dataForm = {
  //         ComID: User.CompanyId,
  //         StaffID: User.UID,
  //         DesigID: ID,
  //         Flag: "US",
  //         Status: IsActive,
  //         BranchID: User.BranchId,
  //         FiscalID: User.FiscalID,
  //         FetchURL: "https://esnep.com/easyoffice/api/admin/designation",
  //         Type: "POST",
  //     }
  //     if (IsActive === 1) {
  //         dataForm.Status = 0
  //     } else {
  //         dataForm.Status = 1
  //     }
  //     Fetchdata(dataForm).then(function (result) {
  //
  //         if (result.StatusCode === 200) {
  //             desgList();
  //             let statsN = JSON.parse(JSON.stringify(newStat));
  //             let pitchStatus;

  //             if (dataForm.Status === 1) {
  //                 pitchStatus = "Activated";

  //             } else if (dataForm.Status === 0) {
  //                 pitchStatus = "Deactivated";
  //             }
  //
  //             setNewStat(statsN)
  //             toast(result.Message, {
  //                 style: {
  //                     color: "green",
  //                     fontSize: "13px",
  //                 },
  //             });

  //         } else {
  //             toast("Error: " + result.Message, {
  //                 style: {
  //                     color: "red",
  //                     fontSize: "13px",
  //                 },
  //             });

  //         }
  //     });
  // }

  // const changeStatus = (ID, IsActive) => {
  //   deactivateDepart(ID, IsActive);
  // };

  // const checkStatus = (IsActive) => {
  //   if (IsActive === 1) {
  //     return mode === "en" ? "Deactivate" : "निष्क्रिय";
  //   } else if (IsActive === 0) {
  //     return mode === "en" ? "Activate" : "सक्रिय";
  //   }
  // };

  const columns = [
    {
      name: mode === "en" ? "S.N." : "क्र.सं",
      grow: 0,
      center: true,
      width: "70px",
      cell: (row, index) =>
        mode === "en" ? index + 1 : englishToNepaliNumber(index + 1),
    },
    // {
    //   name: mode === "en" ? "Full Name" : "पुरा नाम",
    //   // grow: 0,
    //   // center: true,
    //   width: "200px",
    //   selector: (row) => row.FullName,
    // },
    {
      name: mode === "en" ? "File Name" : "फाइल नाम",
      // grow: 0,
      // center: true,
      // width: "200px",
      selector: (row) => row.Title,
    },
    {
      name: mode === "en" ? "Type" : "प्रकार",
      // center: true,
      width: "150px",
      // grow: 0,
      selector: (row) => row.FileType,
    },
    {
      name: mode === "en" ? "Action" : "कार्य",
      // grow: 0,
      center: true,
      width: "130px",
      selector: (row) => {
        return (
          <>
            <div className="ln-verition d-flex">
              {/* <button
                type="button"
                class="btn btn-sm downloadspan mx-1"
                // onClick={() => editPop(row)}
                uk-tooltip="Download"
              >
                <BsDownload />
              </button>{" "} */}
              <a
                href={row.FilePath}
                target="_blank"
                class="btn btn-sm editspan"
                uk-tooltip={mode === "en" ? "View" : "हेर्नुहोस्"}
              >
                <AiOutlineEye />
              </a>
              <button
                type="button"
                class="btn btn-sm deletespan mx-1"
                onClick={() => updateRequest(row)}
                uk-tooltip={mode === "en" ? "Delete" : "हटाउनुहोस्"}
              >
                <MdOutlineDelete />
              </button>{" "}
            </div>
          </>
        );
      },
    },
  ];

  const updateRequest = (row) => {
    setSelectedNote(row);
    setConfirmPopup(true);
  };

  //API to hit Document list
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    docList();
  }, [reportOption, chooseStaff, chooseDepartment, chooseDesignation, reload]);

  const docList = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/document`,
    };

    let newDataForm;
    if (reportOption === "A") {
      newDataForm = { ...dataForm, Flag: "SA" };
    } else if (reportOption === "I") {
      newDataForm = {
        ...dataForm,
        Flag: "SI",
        StaffID: chooseStaff,
      };
    } else if (reportOption === "D") {
      newDataForm = { ...dataForm, Flag: "SD", DepartID: chooseDepartment };
    } else if (reportOption === "Dg") {
      newDataForm = {
        ...dataForm,
        Flag: "SP",
        DepartID: chooseDepartment,
        DesignID: chooseDesignation,
      };
    }

    Fetchdata(newDataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDocumentList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setLoading(false);
        setOriginalList([]);
        setDocumentList([]);
      }
    });
  };

  //API to hit Department list
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    deptList();
  }, []);

  const deptList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      IsActive: "A",
      Type: "POST",
      FetchURL: `${appURL}api/admin/department`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDepartmentList(postResult);
      } else {
        setDepartmentList([]);
      }
    });
  };

  //API to hit Designation list

  const [designationList, setDesignationList] = useState([]);

  useEffect(() => {
    desgList();
  }, [chooseDepartment]);

  const desgList = () => {
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: -1,
      Flag: "S",
      DepartID: chooseDepartment,
      IsActive: "-1",
      BranchID: User.BranchID,
      FiscalID: User.FiscalID,
      Type: "POST",
      FetchURL: `${appURL}api/admin/designation`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setDesignationList(postResult);
      } else {
        setDesignationList([]);
      }
    });
  };

  // API to hit Sub-Department list
  const [subdepartmentList, setSubdepartmentList] = useState([]);

  // useEffect(() => {
  //   subdeptList();
  // }, [chooseDepartment]);

  // const subdeptList = () => {
  //   const params = {
  //     ComID: User.CompanyId,
  //     StaffID: -1,
  //     DepartID: chooseDepartment,
  //     Flag: "S",
  //     Status: 1,
  //     Type: "POST",
  //     BranchID: User.BranchId,
  //     FetchURL: `${appURL}api/admin/sub-department`,
  //   };

  //   Fetchdata(params).then(function (result) {
  //     if (result.StatusCode === 200) {
  //       const postResult = result.SubDepList ? result.SubDepList : "";
  //       setLoading(false);
  //       setSubdepartmentList(postResult);
  //     } else {
  //       setLoading(false);
  //       setSubdepartmentList([]);
  //     }
  //   });
  // };

  //API to get staff list

  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    staffLst();
  }, [chooseDepartment]);

  const staffLst = () => {
    const BranchID = "" + User.BranchID;
    const departmentId = chooseDepartment;
    const params = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: User.UID,
      Flag: "S",
      Designation: departmentId,
      BranchID: BranchID,
      WorkingStatus: "-1",
      IsActive: "-1",
      FiscalID: "1",
      Type: "POST",
      FetchURL: `${appURL}api/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setStaffList(postResult);
      } else {
        setStaffList([]);
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    const srchQuery = searchInput.current.value.toLowerCase();
    if (srchQuery) {
      let srchResult = originalList.filter((list) => {
        return list["FullName"].toLowerCase().includes(srchQuery);
      });

      if (srchResult) {
        setDocumentList(srchResult);
      } else {
        setDocumentList({});
      }
    } else {
      setDocumentList(originalList);
    }
  };

  const handleOption = (e) => {
    setChooseDepartment("");
    setChooseDesignation("");
    setChooseStaff("");
    setReportOption(e.target.value);
  };

  const handleChanges = (e) => {
    setChooseSubDepartment("");
    setChooseDesignation("");
    setChooseStaff("");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDepartment(value);
  };

  const handleChange = (e) => {
    setChooseStaff("");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseSubDepartment(value);
  };

  const handleStaff = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseStaff(value);
  };
  const handleDesignation = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setChooseDesignation(value);
  };

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper  mt-3 ps-4 pe-4">
        <div className="row mt-3">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Document" : "कागजात"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <span className="me-2 ">Expires in: {expires} </span>
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>
        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-3">
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select Option" : "विकल्प चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={reportOption}
                      onChange={handleOption}
                      className="form-select form-select-sm"
                    >
                      <option
                        value="0"
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Option"
                          : "विकल्प चयन गर्नुहोस्"}
                      </option>
                      <option value="A">{mode === "en" ? "All" : "सबै"}</option>

                      <option value="D">
                        {mode === "en" ? "Department" : "विभाग"}
                      </option>
                      <option value="Dg">
                        {mode === "en" ? "Designation" : "पदनाम"}
                      </option>
                      <option value="I">
                        {mode === "en" ? "Individual" : "व्यक्तिगत"}
                      </option>
                    </select>
                  </div>

                  {reportOption !== "A" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Department"
                          : "विभाग चयन गर्नुहोस्"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={chooseDepartment}
                        onChange={handleChanges}
                        className="form-select form-select-sm"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Department"
                            : "विभाग चयन गर्नुहोस्"}
                        </option>
                        {/* <option value="-1">
                          {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                        </option> */}
                        {departmentList.map((item) => (
                          <option
                            key={item.DepartmentID}
                            value={item.DepartmentID}
                          >
                            {item.Department}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {reportOption === "I" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Individual"
                          : "व्यक्तिगत चयन गर्नुहोस्"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="snotifiaction"
                        value={chooseStaff}
                        onChange={handleStaff}
                        className="form-select form-select-sm"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Individual"
                            : "व्यक्तिगत चयन गर्नुहोस्"}
                        </option>
                        {/* <option value="-1">
                          {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                        </option> */}
                        {staffList.map((item) => (
                          <option key={item.UserID} value={item.UID}>
                            {item.FirstName + " " + item.LastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {reportOption === "Dg" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en"
                          ? "Select Designation"
                          : "पदनाम चयन गर्नुहोस्"}
                      </label>
                      <select
                        style={{ fontSize: "11px" }}
                        name="designation"
                        value={chooseDesignation}
                        onChange={handleDesignation}
                        className="form-select form-select-sm"
                      >
                        <option
                          value=""
                          disabled
                          selected
                          style={{ fontSize: "11px" }}
                        >
                          {mode === "en"
                            ? "Select Designation"
                            : "पदनाम चयन गर्नुहोस्"}
                        </option>
                        {/* <option value="-1">
                          {mode === "en" ? "Select All" : "सबै चयन गर्नुहोस्"}
                        </option> */}
                        {designationList.map((item) => (
                          <option
                            key={item.DesignationID}
                            value={item.DesignationID}
                          >
                            {item.Designation}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="">
                  <div className="d-flex uk-flex-wrap uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={addLeaveNote}
                      >
                        <GoPlus color="#fff" className="me-1" />
                        {mode === "en" ? "Add New" : "नयाँ थप्नुहोस्"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <Spinner />
            ) : (
              <DataTable
                columns={columns}
                data={documentList}
                customStyles={customStylesForImage}
                pagination
                paginationRowsPerPageOptions={[10, 20, 50, 100]} // Customizable options
                fixedHeader
                fixedHeaderScrollHeight="370px"
                highlightOnHover
                pointerOnHover
                responsive
                dense
                striped
                subHeader
                subHeaderComponent={
                  <>
                    <div className=" w-100">
                      <div className="d-flex uk-flex-middle justify-content-end">
                        <div>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch className="search-icon" />
                            <input
                              placeholder={
                                mode === "en" ? "Search" : "खोजी गर्नुहोस्"
                              }
                              ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            )}
          </div>
        </>
      </div>

      <DocumentPopup
        setDocumentPopup={setDocumentPopup}
        documentPopup={documentPopup}
        reload={reload}
        setReload={setReload}
        docList={docList}
        documentFormValue={documentFormValue}
        setDocumentFormValue={setDocumentFormValue}
        documentFormError={documentFormError}
        setDocumentFormError={setDocumentFormError}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        image={image}
        setTypeFile={setTypeFile}
        setImage={setImage}
        files={files}
        setFile={setFile}
        reportOption={reportOption}
      />

      <DocumentEditPopup
        setDocumentEditPopup={setDocumentEditPopup}
        documentEditPopup={documentEditPopup}
        reload={reload}
        setReload={setReload}
        docList={docList}
        documentFormValue={documentFormValue}
        setDocumentFormValue={setDocumentFormValue}
        documentFormError={documentFormError}
        setDocumentFormError={setDocumentFormError}
        titleId={titleId}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        typeFile={typeFile}
        image={image}
        setTypeFile={setTypeFile}
        setImage={setImage}
      />

      {confirmPopup &&
        selected_note &&
        ConfirmPopup({
          FormData: {
            AuthCode: User.AuthCode,
            ComID: User.ComID,
            UserID: User.UID,
            // StaffID: selected_note.UserID,
            DocID: selected_note.DocID,
            Flag: "R",
            BranchID: User.BranchID,
            FiscalID: User.FiscalID,
            // FetchURL: "https://esnep.com/easyoffice/api/leave-status",
            FetchURL: `${appURL}api/admin/document`,
            Type: "POST",
          },
          reload: reload,
          setReload: setReload,
          setTrigger: setConfirmPopup,
          message:
            mode === "en"
              ? "Are you sure you want to delete ?"
              : " के तपाइँ मेटाउन चाहनुहुन्छ",
        })}
    </>
  );
}
