import React, { useContext, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import UpperbarContext from "../../../context/upperbar-context";
import Toast from "../../../Toast";
import { GoPlus } from "react-icons/go";
import {
  AiOutlineSearch,
  AiOutlineEdit,
  AiTwotonePrinter,
} from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill, BsInfoCircleFill } from "react-icons/bs";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import $ from "jquery";
import RatePopup from "./RatePopup";
import EditRatePopup from "./EditRatePopup";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./voucher.css";
import { Fetchdata } from "../../../hooks/getData";
import AuthContext from "../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { GetEnglishDate, GetNepaliDate } from "../../../hooks/dateConvertor";
import NepaliDate from "nepali-date-converter";

export default function Voucher() {
  const { User } = useContext(AuthContext);
  const [DFlag, setDflag] = useState("N");
  const [voucherData, setVoucherData] = useState("JAV");
  const [rateIndex, setRateIndex] = useState("");
  const [nameIndex, setNameIndex] = useState("");
  const [rateCurrency, setRateCurrency] = useState("");

  const [re, setRe] = useState();
  //   const searchInput = useRef("");
  const { fiscalYear, todayDate, appURL, dateMode, userDetails, mode } =
    useContext(UpperbarContext);

  const [popup, setPopup] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [saveVoucher, setSaveVoucher] = useState(false);

  // info box popup
  const popvalue = {
    refNo: "1",
    name: "Kismat",
    amt: "9000",
  };
  const [popUpValue, setPopUpValue] = useState(popvalue);

  const initalvalue = {
    rate: "",
    ledgerRate: "",
  };
  const [formValues, setFormValues] = useState(initalvalue);
  const [formErrors, setFormErrors] = useState({});

  // const [re, setRe] = useState();
  const inputRef = useRef();
  const [autoFocused, setAutoFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [addInput, setAddInput] = useState(false);

  const [empty, setEmpty] = useState(true);
  //   API to get account head

  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    accountLst();
  }, [voucherData]);

  const accountLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      IsBank:
        voucherData === "JAV" || voucherData === "CLV"
          ? "N"
          : voucherData === "COV"
          ? "Y"
          : "-1",
      IsPrimary: "N",
      CID: "1",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setAccountList(postResult);
      } else {
        setAccountList([]);
      }
    });
  };
  const [newaccountList, setnewAccountList] = useState([]);

  useEffect(() => {
    newaccountLst();
  }, [voucherData]);

  const newaccountLst = () => {
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "-1",
      DFlag: "LEDGER",
      IsBank: "Y",
      IsPrimary: "N",
      CID: "1",
      Flag: "S",
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/ref-values`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setnewAccountList(postResult);
      } else {
        setnewAccountList([]);
      }
    });
  };
  const [voucherNumber, setVoucherNumber] = useState([]);

  useEffect(() => {
    voucherno();
  }, [voucherData]);

  const voucherno = () => {
    const Branch = "" + User.BranchID;
    const Fiscal = "" + User.FiscalID;
    const dataForm = {
      AuthCode: User.AuthCode,
      ComID: User.ComID,
      UserID: "1",
      CID: "1",
      Flag: "SV",
      VType: voucherData === "CLV" ? "JAV" : voucherData,
      BranchID: Branch,
      FiscalID: Fiscal,
      Type: "POST",
      FetchURL: `${appURL}api/acc/admin/trans-info`,
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values[0] ? result.Values[0] : "";
        setVoucherNumber(postResult);
      } else {
        setVoucherNumber([]);
      }
    });
  };

  // list for a/c head

  const dropDownValue = accountList.map((item) => ({
    value: item.LedgerID,
    label: item.Ledger,
    balance: item.Balance,
    currency: item.BaseCurrency,
  }));
  let newdropDownValue = newaccountList.map((item) => ({
    value: item.LedgerID,
    label: item.Ledger,
    balance: item.Balance,
    currency: item.BaseCurrency,
  }));

  const dataRef = [
    {
      id: "1",
      Type: "Against Ref",
    },
    {
      id: "2",
      Type: "New Ref",
    },
    {
      id: "3",
      Type: "Advance Ref",
    },
  ];
  // list for ref type
  const refValue = dataRef.map((item) => ({
    id: item.id,
    name: item.Type,
  }));

  const initialValue = {
    LedgerID: "",
    Ledger: "",
    Balance: "",
    RefNo: "1",
    RefType: "N",
    Particulars: "",
    DueDate: "",
    DrAmt: "",
    DrCBaseCurr: "N",
    DrLBaseCurr: "U",
    DrBaseRate: "",
    CrAmt: "",
    CrCBaseCurr: "U",
    CrLBaseCurr: "N",
    CrBaseRate: "",
    BaseCurrency: "",
  };

  const [inputList, setInputList] = useState([initialValue]);

  const [cash, setCash] = useState(false);
  const [bank, setBank] = useState(false);

  const includesCash = inputList.some((item) =>
    item.Ledger.includes("Cash Account")
  );
  // const includesBank = inputList.some((item) =>
  //   item.Ledger.includes("Bank Account")
  // );

  const cashData = $("#isCash").val() ? $("#isCash").val() : "";
  const [cashAmtt, setCashAmt] = useState("");
  const handleCashSelect = (data) => {
    setCashAmt(data);
  };

  useEffect(() => {
    if (includesCash || cashData === "Cash Account") {
      setCash(true);
    } else {
      setCash(false);
    }

    // if (includesBank) {
    //   setBank(true);
    // } else {
    //   setBank(false);
    // }
  }, [inputList]);

  useEffect(() => {
    if (cashData === "Cash Account") {
      setCash(true);
    } else {
      setCash(false);
    }
  }, [cashData, voucherData]);

  useEffect(() => {
    if (cashAmtt.label === "Cash Account") {
      setCash(true);
    } else {
      setCash(false);
    }
  }, [cashAmtt]);

  useEffect(() => {
    if (!cashData) {
      setCash(false);
    }
  }, [cashData, voucherData]);

  // to filter "Cash Account" from dropDownValue
  let newCash;
  let newCashDropdown = [];
  let newBank;
  if (cash) {
    newCash = dropDownValue.filter((item) => item.label !== "Cash Account");
  }
  if (includesCash) {
    newCashDropdown = newdropDownValue.filter(
      (item) => item.label !== "Cash Account"
    );
  }
  // if (bank) {
  //   newBank = cashDownValue.filter((item) => item.label !== "Bank A/C");
  // }

  // to get total dr and cr
  let dr = inputList.reduce(function (prev, current) {
    return prev + +current.DrAmt;
  }, 0);

  let cr = inputList.reduce(function (prev, current) {
    return prev + +current.CrAmt;
  }, 0);
  let cashAmount = dr - cr;
  let cashAmt = Math.abs(cashAmount);

  // to get total in case of receipt and payment
  let DEBIT;
  let CREDIT;
  if (voucherData === "REV") {
    // return (dr = dr + cashAmt);
    DEBIT = Math.abs(dr + cashAmount);
  }
  if (voucherData === "PAV") {
    // return (dr = dr + cashAmt);
    CREDIT = Math.abs(cr + cashAmount);
  }

  // to remove keys
  const keysToRemove = ["Ledger", "Balance"];
  const newArr = inputList.map((obj) => {
    const newObj = { ...obj };
    keysToRemove.forEach((key) => delete newObj[key]);
    return newObj;
  });

  // to focus on next input on pressing enter
  $(".particularsInputs").keydown(function (e) {
    if (e.which === 13) {
      $(".rateInputs").focus();
    }
  });
  const handleAcKeyChange = (e, i) => {
    if (e.key === "Enter") {
      $(".particularInput").eq(i).focus();
    }
  };
  const handleParticularKeyChange = (e, i) => {
    if (e.key === "Enter") {
      if (voucherData === "REV") {
        $(".crInputs").eq(i).focus();
      } else if (voucherData === "PAV") {
        $(".drInputs").eq(i).focus();
      } else {
        $(".drInputs").eq(i).focus();
      }
    }
  };
  const handleDrKeyChange = (e, i) => {
    if (e.which === 13) {
      let value = e.target.value;

      const number = parseFloat(value);
      let formattedNumber = number.toFixed(2);
      if (isNaN(formattedNumber)) {
        formattedNumber = "";
      }

      let name = "DrAmt";
      const list = [...inputList];
      list[i][name] = formattedNumber;
      setInputList(list);
      $(".crInputs").eq(i).focus();
      if (voucherData === "PAV") {
        if (empty === true) {
          handleAdd();
        } else {
          $(".autocompleteInput .MuiAutocomplete-inputFocused").focus();
        }
      }
    }
  };

  const handleCrKeyChange = (e, i) => {
    if (e.which === 13) {
      const value = e.target.value;
      const number = parseFloat(value);
      let formattedNumber = number.toFixed(2);
      if (isNaN(formattedNumber)) {
        formattedNumber = "";
      }
      let name = "CrAmt";
      const list = [...inputList];
      list[i][name] = formattedNumber;
      setInputList(list);

      const nextIndex = i + 1;
      const nextInput = $(".autocompleteInput .MuiAutocomplete-inputFocused")[
        nextIndex
      ];
      if (nextInput) {
        nextInput.focus();
      }

      if (empty === true) {
        handleAdd();
      } else {
        $(".autocompleteInput .MuiAutocomplete-inputFocused").focus();
      }
    }
  };

  $(".refInputs").keydown(function (e) {
    if (e.which === 13) {
      $(".refNoInputs").focus();
    }
  });
  $(".refNoInputs").keydown(function (e) {
    if (e.which === 13) {
      $(".refAmtInputs").focus();
    }
  });

  // to get max date
  const getMaxDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;
    var cd = nepDate.getDate() + 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  // to get min date
  const getMinDate = () => {
    var news = new Date();
    var newss = news.toLocaleDateString();
    const nepDate = new NepaliDate(new Date(newss));
    var cm = nepDate.getMonth() + 1;
    var cd = nepDate.getDate() - 1;
    //
    let strDate = nepDate.getYear() + "-" + cm + "-" + cd;
    //
    return strDate;
  };

  // handle autocomplete input change
  const handleOnSelect = (e, index) => {
    let name = "Ledger";
    let id = "LedgerID";
    let balance = "Balance";
    let currency = "BaseCurrency";
    const list = [...inputList];
    const ledgerid = e.value;
    const lid = "" + ledgerid;

    let bal = e.balance;
    const check = Math.sign(bal);
    if (check === -1) {
      bal = `${Math.abs(e.balance)} CR`;
    } else {
      bal = `${e.balance} DR`;
    }

    list[index][name] = e.label;
    list[index][currency] = e.currency;
    list[index][balance] = bal;
    list[index][id] = lid;
    // list[index][id] = e.LedgerID;
    setInputList(list);

    list[index]["CrAmt"] = "";

    list[index]["DrAmt"] = "";
  };

  const handleRefSelect = (e, index) => {
    let name = "refType";
    const list = [...inputList];
    list[index][name] = e.id;
    setInputList(list);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;

    if (name === "DrAmt") {
      list[index]["CrAmt"] = "";
    }
    if (name === "CrAmt") {
      list[index]["DrAmt"] = "";
    }
    setInputList(list);

    if (inputList[index].BaseCurrency) {
      if (inputList[index].BaseCurrency !== userDetails.BaseCurrency) {
        // handleRate(e, index, name);
        if (name === "DrAmt") {
          list[index]["DrAmt"] = "";
        }
        if (name === "CrAmt") {
          list[index]["CrAmt"] = "";
        }
      }
    }
  };

  const handleInputClick = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (inputList[index].BaseCurrency) {
      if (inputList[index].BaseCurrency !== userDetails.BaseCurrency) {
        handleRate(e, index, name);
        if (name === "DrAmt") {
          list[index]["CrAmt"] = "";
        }
        if (name === "CrAmt") {
          list[index]["DrAmt"] = "";
        }
      }
    }
  };

  const handleKeyPress = (event) => {
    // Check if the key pressed is not a number

    if (isNaN(Number(event.key))) {
      event.preventDefault(); // Prevent the character from being entered
    } // Prevent the character from being entered
  };
  // handle focus change
  // const handleFocusChange = (e, index) => {
  //   if (inputList[index].BaseCurrency !== userDetails.BaseCurrency) {
  //     handleRate(e, index);
  //   }
  // };

  // to select rate
  const handleRate = (e, index, name) => {
    setPopup(true);
    setFormValues(initalvalue);
    setRateIndex(index);
    setNameIndex(name);
    setRateCurrency(inputList[index].BaseCurrency);
    // setAutoFocused(false);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];

    const deleteItems = list.filter((item, i) => {
      return i != index;
    });
    setInputList(deleteItems);
    // setAutoFocused(false);
    setPopup(false);
  };

  // handle click event of the Add button
  const handleAdd = () => {
    setInputList([...inputList, initialValue]);
    setAddInput(!addInput);
    setEmpty(false);
    // setAutoFocused(false);
    // setRe(Math.random());
  };

  // to add voucher
  const handleVoucher = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setVoucherData(value);
    setInputList([initialValue]);
    setRe(Math.random());
    setOpen(!open);
    setCash(false);
  };

  var date = new Date();

  const voucherInitalvalue = {
    // voucherNo: "",
    enDate: GetEnglishDate(date),
    npDate: GetNepaliDate(date),
    narration: "",
  };
  const [voucherValues, setVoucherValues] = useState(voucherInitalvalue);
  const [voucherErrors, setVoucherErrors] = useState({});
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setVoucherValues({ ...voucherValues, [name]: value });
  };
  const validate = (values) => {
    const errors = {};

    // if (!values.voucherNo) {
    //   errors.voucherNo = "Required";
    // }
    return errors;
  };

  const handleVoucherChange = (event, field) => {
    const value = event.target.value.toString();
    const data = [];
    setVoucherNumber({ ...data, [field]: value });
  };

  const handleDate = ({ bsDate }) => {
    setVoucherValues({ ...voucherValues, npDate: bsDate });
  };

  // to add class to autocomplete textfield
  useEffect(() => {
    const elements = document.querySelectorAll(".field .sc-ciZhAO input");

    elements.forEach((element) => {
      element.setAttribute("name", "LedgerID");
      element.setAttribute("autocomplete", "off");
      // element.setAttribute("value", inputList[ind].LedgerID);
      element.classList.add("accountInputs");
    });
  }, [addInput, open]);
  useEffect(() => {
    const elements = document.querySelectorAll(".childRow .sc-ciZhAO input");

    elements.forEach((element) => {
      element.setAttribute("name", "refType");
      element.setAttribute("autocomplete", "off");
      // element.setAttribute("value", inputList[ind].LedgerID);
      element.classList.add("refInputs");
    });
  }, [addInput, open, inputList]);

  // to focus on amount field after selecting rate

  // useEffect(() => {
  //   if (autoFocused) {
  //     inputRef.current.focus();
  //   }
  // }, [autoFocused]);

  // to get the balance between debit and credit
  const { drTotal, crTotal, hasEmpty } = inputList.reduce(
    (acc, item) => {
      if (item.DrAmt) {
        acc.drTotal += Number(item.DrAmt);
      }
      if (item.CrAmt) {
        acc.crTotal += Number(item.CrAmt);
      }
      if (!item.DrAmt && !item.CrAmt) {
        acc.hasEmpty = true;
      }
      return acc;
    },
    { drTotal: 0, crTotal: 0, hasEmpty: false }
  );

  // const isBalanced = !hasEmpty && drTotal === crTotal;
  let isBalanced;
  if (voucherData === "REV") {
    isBalanced = !hasEmpty && DEBIT === cr;
  } else if (voucherData === "PAV") {
    isBalanced = !hasEmpty && dr === CREDIT;
  } else {
    isBalanced = !hasEmpty && drTotal === crTotal;
  }

  // to validate if account head is not selected
  const hasEmptyId = inputList.some((obj) => obj.LedgerID === "");

  useEffect(() => {
    if (hasEmptyId) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  }, [hasEmptyId]);

  const handleSave = (e) => {
    e.preventDefault();
    setVoucherErrors(validate(voucherValues));
    if (hasEmptyId) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    if (empty === false) {
      setSaveVoucher(false);
      toast.error("Account Head must be selected !", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else if (!isBalanced) {
      setSaveVoucher(false);
      toast.error("Debit and Credit Amount should be equal", {
        style: {
          color: "red",
          fontSize: "13px",
        },
        theme: "light",
      });
    } else {
      setSaveVoucher(true);
    }
    // call this api even if there is validation
    voucherno();
  };

  // to call api only after the validation is done
  useEffect(() => {
    if (Object.keys(voucherErrors).length === 0 && saveVoucher) {
      const UserID = "" + User.UID;
      const Branch = "" + User.BranchID;
      const Fiscal = "" + User.FiscalID;
      const dataForm = {
        AuthCode: User.AuthCode,
        ComID: User.ComID,
        UserID: UserID,
        CID: "1",
        Flag: "i",
        VoucherNo: voucherData === "BD" ? "-1" : voucherNumber.VoucherNo,
        // VoucherNepDate:
        //   mode === "np" ? GetEnglishDate(voucherValues.npDate) : "",
        // VoucherEngDate: mode === "en" ? voucherValues.enDate : "",
        VoucherNepDate: GetEnglishDate(voucherValues.npDate),
        VoucherEngDate: voucherValues.enDate,
        VType: voucherData === "CLV" ? "JAV" : voucherData,
        SOI: "S",
        GeneralNarration: voucherValues.narration,
        VAmounts: newArr,
        BranchID: Branch,
        FiscalID: Fiscal,
        FetchURL: `${appURL}api/acc/admin/voucher`,
        Type: "POST",
      };
      Fetchdata(dataForm).then(function (result) {
        if (result.StatusCode === 200) {
          toast.success(result.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
            theme: "light",
          });
        } else {
          toast.error("Error: " + result.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
            theme: "light",
          });
        }
      });

      setSaveVoucher(false);
    }
  }, [voucherErrors]);

  return (
    <>
      {/* <Toast /> */}
      <div className="container-fluid classatten-wrapper ps-4 pe-4">
        <div className="row ">
          <div className="page-header">
            <div className="text-start  page-title">
              {mode === "en" ? "Voucher" : "भाउचर"}
            </div>
            <div className="page-date">
              <div className="sec-content">
                <FaRegCalendarAlt /> {todayDate} <span>|</span>{" "}
                {mode === "en" ? "Fiscal Year" : "वित्तीय वर्ष"} :{" "}
                {fiscalYear.StartDate}
                <span>-</span>
                {fiscalYear.EndDate}
              </div>
            </div>
          </div>
          <hr className="title-hr" />
        </div>

        <>
          <div className="sec-dataTable">
            <div className="upper-dataTbl">
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-flex-middle mb-3">
                <div className="uk-flex uk-flex-wrap me-5">
                  {voucherData !== "BD" && (
                    <div className="me-2 mt-3">
                      <label className="d-block text-start dark-gray mb-1">
                        {mode === "en" ? "Voucher No." : "भाउचर नम्बर"}
                      </label>

                      <input
                        id="voucherNo"
                        style={{ fontSize: "13px" }}
                        type="number"
                        className="form-control form-control-sm "
                        name="voucherNo"
                        placeholder={
                          mode === "en" ? "Voucher No." : "भाउचर नम्बर"
                        }
                        defaultValue={voucherNumber.VoucherNo}
                        value={voucherNumber.VoucherNo}
                        onChange={(e) => handleVoucherChange(e, "VoucherNo")}
                      />
                      {/* {voucherErrors.voucherNo && (
                        <p className="errormsg">{voucherErrors.voucherNo}</p>
                      )} */}
                    </div>
                  )}

                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en" ? "Select  Date" : " मिति चयन गर्नुहोस्"}
                    </label>
                    {dateMode === "np" ? (
                      <Calendar
                        // id="eventDate"
                        className="form-control form-control-sm pt-0 pb-0 "
                        dateFormat="YYYY/MM/DD"
                        // defaultDate="2010/01/01"
                        theme="default"
                        language="en"
                        // value={holidayFormValue.eventDate}
                        onChange={handleDate}
                        // name="enDate"
                        maxDate={voucherData === "CLV" && getMaxDate()}
                        minDate={voucherData === "CLV" && getMinDate()}

                        // hideDefaultValue={true}
                        // placeholder={"Select Follow Date"}
                      />
                    ) : (
                      <input
                        type="date"
                        value={voucherValues.enDate}
                        placeholder="Select a Date"
                        className="form-control form-control-sm "
                        name="enDate"
                        onChange={handleChange}
                      />
                    )}
                    {/* {mode === "np" ? (
                      <Calendar
                        // id="eventDate"
                        className="form-control form-control-sm pt-0 pb-0 "
                        dateFormat="YYYY/MM/DD"
                        // defaultDate="2010/01/01"
                        theme="default"
                        language="en"
                        // value={holidayFormValue.eventDate}
                        onChange={handleDate}
                        // name="enDate"

                        // hideDefaultValue={true}
                        // placeholder={"Select Follow Date"}
                      />
                    ) : (
                      <input
                        type="date"
                        value={voucherValues.enDate}
                        // placeholder="Select a Date"
                        className="form-control form-control-sm "
                        name="enDate"
                        onChange={handleChange}
                        // id="eventDate"
                      />
                    )} */}
                  </div>
                  <div className="me-2 mt-3">
                    <label className="d-block text-start dark-gray mb-1">
                      {mode === "en"
                        ? "Select Voucher Type"
                        : "भाउचर प्रकार चयन गर्नुहोस्"}
                    </label>
                    <select
                      style={{ fontSize: "11px" }}
                      name="snotifiaction"
                      value={voucherData}
                      onChange={handleVoucher}
                      className="form-select form-select-sm"
                    >
                      <option
                        value=""
                        disabled
                        selected
                        style={{ fontSize: "11px" }}
                      >
                        {mode === "en"
                          ? "Select Voucher Type"
                          : "भाउचर प्रकार चयन गर्नुहोस्"}
                      </option>
                      <option value="JAV">
                        {mode === "en" ? "Journal Voucher" : "जर्नल भाउचर"}
                      </option>
                      <option value="REV">
                        {mode === "en" ? "Receipt Voucher" : "रसिद भाउचर"}
                      </option>
                      <option value="PAV">
                        {mode === "en" ? "Payment Voucher" : "भुक्तानी भाउचर"}
                      </option>
                      <option value="COV">
                        {mode === "en" ? "Contra Voucher" : "कन्ट्रा भाउचर"}
                      </option>
                      <option value="PUV">
                        {mode === "en" ? "Purchase Invoice" : "खरिद इनभ्वाइस"}
                      </option>
                      <option value="SAV">
                        {mode === "en" ? "Sales Invoice" : "बिक्री इनभ्वाइस"}
                      </option>
                      <option value="BD">
                        {mode === "en" ? "Balance b/d" : "ब्यालेन्स b/d"}
                      </option>
                      <option value="CLV">
                        {mode === "en" ? "Closing Voucher" : "बन्द भाउचर"}
                      </option>
                      <option value="DNV">
                        {mode === "en" ? "Debit Note" : "डेबिट नोट"}
                      </option>
                      <option value="CNV">
                        {mode === "en" ? "Credit Note" : "क्रेडिट नोट"}
                      </option>
                    </select>
                  </div>
                  {voucherData !== "JAV" &&
                    voucherData !== "CLV" &&
                    voucherData !== "COV" &&
                    voucherData !== "REV" &&
                    voucherData !== "PAV" &&
                    voucherData !== "PUV" &&
                    voucherData !== "SAV" &&
                    voucherData !== "BD" &&
                    voucherData !== "DNV" &&
                    voucherData !== "CNV" && (
                      <>
                        {/* <div className="me-2 mt-3">
                        <label className="d-block text-start dark-gray mb-1">
                          Select Ledger
                        </label>
                        <select
                          style={{ fontSize: "11px" }}
                          name="ledger"
                          // value={voucherData}
                          // onChange={handleVoucher}
                          className="form-select form-select-sm"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            style={{ fontSize: "11px" }}
                          >
                            Select Ledger
                          </option>
                          {accountList.map((props) => {
                            return (
                              <option
                                key={props.LedgerID}
                                value={props.LedgerID}
                              >
                                {props.Ledger}
                              </option>
                            );
                          })}
                        </select>
                      </div> */}

                        <div className="me-2 mt-3">
                          <label className="d-block text-start dark-gray mb-1">
                            {mode === "en"
                              ? "Reference number"
                              : "सन्दर्भ नम्बर"}
                          </label>
                          <div class="uk-search uk-search-default">
                            <AiOutlineSearch
                              className="search-icon"
                              style={{ cursor: "pointer" }}
                            />
                            <div uk-dropdown="mode: click;animation: reveal-top; animate-out: true; duration: 500;">
                              <h1>""</h1>
                            </div>
                            <input
                              placeholder={
                                mode === "en"
                                  ? "Reference number"
                                  : "सन्दर्भ नम्बर"
                              }
                              // ref={searchInput}
                              type="text"
                              className="form-control form-control-sm searchField"
                              // onChange={searchHandler}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>
                <div className="">
                  <div className="d-flex uk-flex-middle uk-flex-right">
                    <div className="btn-addlnote mt-3">
                      <button
                        type="button"
                        class="btn btn-sm"
                        style={{
                          background: "var(--button-color)",
                          color: "white",
                        }}
                        onClick={handleSave}
                      >
                        {/* <GoPlus color="#fff" className="me-1" /> */}

                        {mode === "en" ? "Save" : "बुझाउनुहोस्"}
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Excel"
                        // onClick={toExcel}
                        className="me-1 ms-2 border-0"
                      >
                        <RiFileExcel2Fill size="1rem" color="#136438" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Export Pdf"
                        // onClick={toPdf}
                        className="mx-1 border-0"
                      >
                        <BsFileEarmarkPdfFill size="1rem" color="#ff0002" />
                      </button>
                    </div>
                    <div className="export-btn">
                      <button
                        uk-tooltip="Print"
                        // onClick={toPrint}
                        className="mx-1 border-0"
                      >
                        <AiTwotonePrinter size="1rem" color="#555" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex uk-flex-middle justify-content-end mt-3">
                <div>
                  <form class="uk-search uk-search-default">
                    <AiOutlineSearch className="search-icon" />
                    <input
                      placeholder={mode === "en" ? "Search" : "खोजी गर्नुहोस्"}
                      // ref={searchInput}
                      type="text"
                      className="form-control form-control-sm searchField"
                      // onChange={searchHandler}
                    />
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <table className="uk-table uk-table-striped voucher-table">
                <thead>
                  <tr>
                    <td
                      className="rdt_TableHeadRow tableHead text-start"
                      style={{ width: "550px" }}
                    >
                      {mode === "en" ? "Account Head" : "खाता प्रमुख"}
                    </td>

                    <td className="rdt_TableHeadRow tableHead text-start">
                      {mode === "en" ? "Particulars" : "विशेष"}
                    </td>

                    <td
                      className="rdt_TableHeadRow tableHead text-end"
                      style={{ width: "150px" }}
                    >
                      {mode === "en" ? "Debit" : "डेबिट"}
                    </td>
                    <td
                      className="rdt_TableHeadRow tableHead text-end"
                      style={{ width: "150px" }}
                    >
                      {mode === "en" ? "Credit" : "क्रेडिट"}
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <tr className="voucherField">
                          <td className="rdt_TableCell tablecell">
                            <div className="field">
                              <div className="autocompleteInput">
                                <Autocomplete
                                  className="w-100"
                                  disablePortal
                                  id="autoCompleteInput"
                                  options={
                                    cash === false ? dropDownValue : newCash
                                  }
                                  name="LedgerID"
                                  isOptionEqualToValue={(option) =>
                                    option.label
                                  }
                                  onChange={(event, newValue) => {
                                    handleOnSelect(newValue, i);
                                  }}
                                  onKeyDown={(event, newValue) => {
                                    handleAcKeyChange(event, i);
                                  }}
                                  value={x.Ledger}
                                  autoFocus={true}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </div>

                              {/* <div className="icon">
                                <BsInfoCircleFill />
  
                                <div
                                  className="infoBox"
                                  uk-dropdown="animation: reveal-top; animate-out: true; duration: 700;"
                                >
                                  <table className="uk-table">
                                    <tr>
                                      <td
                                        className="rdt_TableHeadRow tableHead "
                                        width="90px"
                                      >
                                        Ref No.
                                      </td>
                                      <td
                                        className="rdt_TableHeadRow tableHead"
                                        // width="70px"
                                      >
                                        Name
                                      </td>
                                      <td
                                        className="rdt_TableHeadRow tableHead"
                                        // width="70px"
                                      >
                                        Amount
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="rdt_TableCell tablecell ">
                                        <input
                                          style={{ fontSize: "13px" }}
                                          type="text"
                                          className="form-control form-control-sm voucher-input "
                                          name="refNo"
                                          // placeholder="Voucher No."
                                          value={popUpValue.refNo}
                                          //   onChange={handleChange}
                                        />
                                      </td>
                                      <td className="rdt_TableCell tablecell">
                                        <input
                                          style={{ fontSize: "13px" }}
                                          type="text"
                                          className="form-control form-control-sm voucher-input "
                                          name="name"
                                          // placeholder="Voucher No."
                                          value={popUpValue.name}
                                          //   onChange={handleChange}
                                        />
                                      </td>
                                      <td className="rdt_TableCell tablecell">
                                        <input
                                          style={{ fontSize: "13px" }}
                                          type="text"
                                          className="form-control form-control-sm voucher-input "
                                          name="amt"
                                          // placeholder="Voucher No."
                                          value={popUpValue.amt}
                                          //   onChange={handleChange}
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div> */}
                            </div>
                            {voucherData !== "COV" && (
                              <>
                                {inputList[i].Balance !== "" && (
                                  <label className="text-start w-100">
                                    {`Cur Bal: ${x.Balance}`}
                                  </label>
                                )}
                              </>
                            )}
                          </td>

                          <td className="rdt_TableCell tablecell">
                            <input
                              style={{ fontSize: "13px" }}
                              type="text"
                              className="form-control form-control-sm particularInput voucher-input"
                              name="Particulars"
                              value={x.Particulars}
                              onChange={(e) => handleInputChange(e, i)}
                              onKeyDown={(e) => {
                                handleParticularKeyChange(e, i);
                              }}
                            />
                          </td>

                          {voucherData !== "" && (
                            <>
                              <td className="rdt_TableCell tablecell voucherCell">
                                <div
                                  className="d-flex uk-position-relative"
                                  onClick={(e) => {
                                    if (voucherData !== "REV") {
                                      handleInputClick(e, i);
                                    }
                                  }}
                                >
                                  <input
                                    style={{ fontSize: "13px" }}
                                    type="number"
                                    className="form-control form-control-sm  drInputs voucher-input"
                                    name="DrAmt"
                                    value={
                                      voucherData === "REV" ? "0.00" : x.DrAmt
                                    }
                                    disabled={voucherData === "REV"}
                                    onChange={(e) => handleInputChange(e, i)}
                                    onKeyDown={(e) => handleDrKeyChange(e, i)}
                                    autoComplete="off"
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                              </td>
                              <td className="rdt_TableCell tablecell voucherCell">
                                <div className="d-flex">
                                  <div
                                    onClick={(e) => {
                                      if (voucherData !== "PAV") {
                                        handleInputClick(e, i);
                                      }
                                    }}
                                  >
                                    <input
                                      style={{ fontSize: "13px" }}
                                      type="number"
                                      className="form-control form-control-sm crInputs voucher-input"
                                      name="CrAmt"
                                      value={
                                        voucherData === "PAV" ? "0.00" : x.CrAmt
                                      }
                                      disabled={voucherData === "PAV"}
                                      onChange={(e) => handleInputChange(e, i)}
                                      onKeyDown={(e) => handleCrKeyChange(e, i)}
                                      onKeyPress={handleKeyPress}
                                      autoComplete="off"
                                    />
                                  </div>
                                  {inputList.length !== 1 && (
                                    <button
                                      type="button"
                                      className=" btn btn-primary border-0"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                        {/* )} */}
                      </>
                    );
                  })}
                  {voucherData === "REV" || voucherData === "PAV" ? (
                    <tr className="voucherField">
                      <td className="rdt_TableCell tablecell uk-position-relative">
                        <Autocomplete
                          className="w-100"
                          id="isCash"
                          options={
                            newdropDownValue.length > 0 && cash === false
                              ? newdropDownValue
                              : includesCash === true
                              ? newCashDropdown
                              : newdropDownValue
                          }
                          defaultValue={newdropDownValue.find(
                            (item) => item.label === "Cash Account"
                          )}
                          name="LedgerID"
                          isOptionEqualToValue={(option) => option.label}
                          onChange={(event, newValue) => {
                            handleCashSelect(newValue);
                          }}
                          // value={
                          //   newdropDownValue.length > 0 &&
                          //   (cashAmtt === null || cashAmtt === undefined
                          //     ? newdropDownValue.find(
                          //         (item) => item.label === "Cash Account"
                          //       )
                          //     : cashAmtt)
                          // }
                          // value={cashAmtt}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </td>

                      <td className="rdt_TableCell tablecell">
                        <input
                          style={{ fontSize: "13px" }}
                          type="text"
                          className="form-control form-control-sm  voucher-input"
                          // name="DrAmt"
                          // value={
                          //   voucherData === "REV" ? cashAmt.toFixed(2) : "0.00"
                          // }
                          // autoComplete="off"
                          // onKeyPress={handleKeyPress}
                        />
                      </td>

                      <td className="rdt_TableCell tablecell">
                        <div className="d-flex">
                          <input
                            style={{ fontSize: "13px" }}
                            type="number"
                            className="form-control form-control-sm  voucher-input"
                            name="DrAmt"
                            value={
                              voucherData === "REV"
                                ? cashAmt.toFixed(2)
                                : "0.00"
                            }
                            autoComplete="off"
                            onKeyPress={handleKeyPress}
                          />
                        </div>
                      </td>
                      <td className="rdt_TableCell tablecell">
                        <div className="d-flex">
                          <input
                            style={{ fontSize: "13px" }}
                            type="number"
                            className="form-control form-control-sm  voucher-input"
                            name="CrAmt"
                            value={
                              voucherData === "PAV"
                                ? cashAmt.toFixed(2)
                                : "0.00"
                            }
                            autoComplete="off"
                            onKeyPress={handleKeyPress}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="rdt_TableCell tablecell text-end fw-bold"
                      colSpan="2"
                    >
                      {mode === "en" ? "Total" : "पूरा"}
                    </td>
                    <td className="rdt_TableCell tablecell text-end fw-bold">
                      {voucherData === "REV" ? DEBIT.toFixed(2) : dr.toFixed(2)}
                      {/* {dr.toFixed(2)} */}
                    </td>
                    <td className="rdt_TableCell tablecell text-end fw-bold">
                      {voucherData === "PAV"
                        ? CREDIT.toFixed(2)
                        : cr.toFixed(2)}
                      {/* {cr.toFixed(2)} */}
                    </td>
                  </tr>
                  {/* )} */}
                </tfoot>
              </table>
            </div>

            <textarea
              id="narration"
              style={{ fontSize: "13px" }}
              class="form-control ps-2"
              name="narration"
              rows="3"
              cols="12"
              onChange={handleChange}
              placeholder={mode === "en" ? "Narration" : "कथन"}
            ></textarea>
          </div>
        </>
      </div>

      <RatePopup
        popup={popup}
        setPopup={setPopup}
        submit={submit}
        setSubmit={setSubmit}
        formValues={formValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        setFormValues={setFormValues}
        inputList={inputList}
        setInputList={setInputList}
        nameIndex={nameIndex}
        rateIndex={rateIndex}
        rateCurrency={rateCurrency}
        setAutoFocused={setAutoFocused}
        voucherData={voucherData}
      />
    </>
  );
}
